import * as jose from 'jose'

import ExampleCodeBlock, { CodeParamType } from '../../Docs/ExampleCodeBlock'
import { useWorkspace } from '../../Workspaces/workspace-context'

export function CreateJwtCodeExample() {
  const { workspace } = useWorkspace()

  return (
    <ExampleCodeBlock
      customCodes={{ js, python, go, ruby, java, php }}
      variables={{
        WORKSPACE_KEY: workspace?.key ?? '<WORKSPACE_KEY>',
        WORKSPACE_SECRET: workspace?.secret ?? '<WORKSPACE_SECRET>',
      }}
      variablesSpec={{
        WORKSPACE_KEY: {
          type: CodeParamType.String,
          title: 'Workspace Key',
        },
        WORKSPACE_SECRET: {
          type: CodeParamType.String,
          title: 'Workspace Secret',
        },
      }}
      parameters={{
        CUSTOMER_ID: CodeParamType.String,
        CUSTOMER_NAME: CodeParamType.String,
      }}
      run={async ({ parameters, workspace }) => {
        const token = await new jose.SignJWT({
          id: parameters.CUSTOMER_ID,
          name: parameters.CUSTOMER_NAME,
        })
          .setProtectedHeader({ alg: 'HS256', typ: 'JWT' })
          .setIssuer(workspace.key)
          .setExpirationTime('365d')
          .sign(new TextEncoder().encode(workspace.secret))

        return { token }
      }}
    />
  )
}

const js = `import jwt from 'jsonwebtoken'
// Your workspace key and secret.
// You can find them on the Settings page.
const WORKSPACE_KEY = '{WORKSPACE_KEY}'
const WORKSPACE_SECRET = '{WORKSPACE_SECRET}'

const tokenData = {
  // Identifier of your customer (user, team, or organization).
  id: '{CUSTOMER_ID}',
  // Human-readable customer name.
  name: '{CUSTOMER_NAME}',
  // (optional) Any user fields you want to attach to your customer.
  fields: {
    userField: '<user field value>'
  }
}

const options = {
  issuer: WORKSPACE_KEY,
  // To prevent token from being used for too long
  expiresIn: 7200,
  // HS256 signing algorithm is used by default,
  // but we recommend to go with more secure option like HS512.
  algorithm: 'HS512'
}

const token = jwt.sign(tokenData, WORKSPACE_SECRET, options)
`

const go = `import (
    "time"
    "github.com/dgrijalva/jwt-go"
)

var WORKSPACE_KEY = "{WORKSPACE_KEY}"
var WORKSPACE_SECRET = "{WORKSPACE_SECRET}"

var SigningKey = []byte(WORKSPACE_SECRET)


claims := jwt.MapClaims{
    // Identifier of your customer (user, team, or organization).
    "id" : "{CUSTOMER_ID}",
    // Human-readable customer name.
    "name": "{CUSTOMER_NAME}",
    // To prevent token from being used for too long
    "exp": time.Now().Add(time.Hour * 24).Unix(),
    "iss": WORKSPACE_KEY,
    }

token := jwt.NewWithClaims(jwt.SigningMethodHS256, claims)
tokenString, err := token.SignedString(SigningKey)`

const java = `import io.jsonwebtoken.Jwts;
import io.jsonwebtoken.SignatureAlgorithm;

import javax.crypto.spec.SecretKeySpec;
import java.time.temporal.ChronoUnit;
import java.util.Date;


String workspaceKey = "{WORKSPACE_KEY}";
String workspaceSecret = "{WORKSPACE_SECRET}";

String jwtToken = Jwts.builder()
        .claim("id", "{CUSTOMER_ID}") // Identifier of user or organization.
        .claim("name", "{CUSTOMER_NAME}") // Human-readable name (it will simplify troubleshooting)
        // .claim("fields", <user fields value>) (optional) Any user fields you want to attach to your user.
        .setExpiration(Date.from(new Date().toInstant().plus(14400, ChronoUnit.SECONDS))) // To prevent token from being used for too long
        .setIssuer(workspaceKey)
        .signWith(new SecretKeySpec(workspaceSecret.getBytes(), SignatureAlgorithm.HS256.getJcaName()), SignatureAlgorithm.HS256)
        .setHeaderParam("typ", "JWT")
        .compact();`

const php = `use Firebase\JWT\JWT;

// Your workspace key and secret.
// You can find them on the Settings page.
$secret = '{WORKSPACE_SECRET}';
$key = '{WORKSPACE_KEY}';

$payload = [
    'id' => "{CUSTOMER_ID}",  // ID of your customer in your system. It will be used to identify customer in Integration.app
    'name' => "{CUSTOMER_NAME}", // Human-readable customer name (it will simplify troubleshooting)
    'iss' => $key,
    'exp' => time() + 60 * 60 * 24 * 60, // To prevent token from being used for too long
];
$token = JWT::encode($payload, $secret, 'HS256');`

const python = `import datetime
import jwt

# Your workspace key and secret.
# You can find them on the Settings page.
WORKSPACE_KEY = "{WORKSPACE_KEY}"
WORKSPACE_SECRET = "{WORKSPACE_SECRET}"

encoded_jwt = jwt.encode(
    {
      # ID of your customer in your system.
      # It will be used to identify customer in Integration.app
      "id": "{CUSTOMER_ID}",
      # Human-readable name (it will simplify troubleshooting)
      "name": "{CUSTOMER_NAME}",
      "iss": WORKSPACE_KEY,
      # Any customer fields you want to attach to your user.
      "fields": {
        "field1": "<field value>"
      }
      "exp": datetime.datetime.now() + datetime.timedelta(seconds=1440)
    }, WORKSPACE_SECRET, algorithm="HS256")
`
const ruby = `require 'jwt'

WORKSPACE_SECRET = '{WORKSPACE_SECRET}'
WORKSPACE_KEY = '{WORKSPACE_KEY}'

payload = {
  id: '{CUSTOMER_ID}',
  name: '{CUSTOMER_NAME}',
  iss: WORKSPACE_KEY,
  exp: Time.now.to_i + 60 * 60 * 6, # Expiration time (6 hours from now)
}

token = JWT.encode(payload, WORKSPACE_SECRET, 'HS256')
`
