"use strict";
"use client";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { Tooltip } from '@ark-ui/react/tooltip';
export { TooltipContext as Context } from '@ark-ui/react/tooltip';
import '../../styled-system/helpers.mjs';
import '../../styled-system/css/conditions.mjs';
import '../../styled-system/css/css.mjs';
import { tooltip } from '../../styled-system/recipes/tooltip.mjs';
import { createStyleContext } from './utils/create-style-context.mjs';

const { withRootProvider, withContext } = createStyleContext(tooltip);
withRootProvider(Tooltip.RootProvider);
const Root = withRootProvider(Tooltip.Root);
const Arrow = withContext(Tooltip.Arrow, "arrow");
const ArrowTip = withContext(Tooltip.ArrowTip, "arrowTip");
const Content = withContext(Tooltip.Content, "content");
const Positioner = withContext(Tooltip.Positioner, "positioner");
const Trigger = withContext(Tooltip.Trigger, "trigger");

export { Arrow, ArrowTip, Content, Positioner, Root, Trigger };
