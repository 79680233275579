import ExampleCodeBlock, {
  CodeParamType,
} from 'components/Docs/ExampleCodeBlock'

import { httpCodeExamples } from '../http-code-examples'

const js = `await integrationApp
  .connection('{INTEGRATION_KEY}')
  .dataSource('{DATA_SOURCE_KEY}')
  .create()
`
const rest = httpCodeExamples(
  '/connections/{INTEGRATION_KEY}/data-sources/{DATA_SOURCE_KEY}',
  {
    method: 'PUT',
  },
)

export function CreateDataSourceInstanceCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js, ...rest }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
        DATA_SOURCE_KEY: CodeParamType.DataSourceKey,
      }}
      run={async ({ integrationApp, parameters }) => {
        return await integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .dataSource(parameters.DATA_SOURCE_KEY)
          .create()
      }}
    />
  )
}
