import {
  WorkspaceElementReference,
  WorkspaceElement,
  WorkspaceElementType,
} from '@integration-app/sdk'
import useSWR from 'swr'

import { useWorkspace } from 'components/Workspaces/workspace-context'

const WORKSPACE_ELEMENT_TYPE_TO_API_ROUTE: Partial<
  Record<WorkspaceElementType, string>
> = {
  [WorkspaceElementType.Integration]: 'integrations',
  [WorkspaceElementType.Connection]: 'connections',
  [WorkspaceElementType.Customer]: 'customers',
  [WorkspaceElementType.Flow]: 'flows',
  [WorkspaceElementType.FlowInstance]: 'flow-instances',
  [WorkspaceElementType.Action]: 'actions',
  [WorkspaceElementType.ActionInstance]: 'action-instances',
  [WorkspaceElementType.DataSource]: 'data-sources',
  [WorkspaceElementType.DataSourceInstance]: 'data-source-instances',
  [WorkspaceElementType.FieldMapping]: 'field-mappings',
  [WorkspaceElementType.FieldMappingInstance]: 'field-mapping-instances',
}

export function useWorkspaceElement(
  elementRef: WorkspaceElementReference | undefined,
) {
  const { engineAdminFetcher } = useWorkspace()

  const apiRoute = elementRef
    ? WORKSPACE_ELEMENT_TYPE_TO_API_ROUTE[elementRef.type]
    : null
  const elementRoute = apiRoute ? `/${apiRoute}/${elementRef?.id}` : null

  const { data: element, isLoading } = useSWR<WorkspaceElement>(
    elementRoute,
    engineAdminFetcher,
  )

  return { element, isLoading }
}
