"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { useState } from 'react';
import qs from 'query-string';
import useSWRInfinite from 'swr/infinite';
import { useIntegrationApp } from '../contexts/integration-app-context.mjs';

function useElements(route, query = {}) {
  const integrationApp = useIntegrationApp();
  const limit = query.limit ?? 25;
  const tokenHash = hashCode(integrationApp.token);
  function getKey(page, previousPageData) {
    if (page === 0)
      return `/${route}?${qs.stringify({
        ...query,
        limit,
        hash: tokenHash
      })}`;
    if (previousPageData.items?.length < limit) return null;
    return `/${route}?${qs.stringify({
      ...query,
      limit,
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      cursor: previousPageData.cursor,
      hash: tokenHash
    })}`;
  }
  const [loadingMore, setIsLoadingMore] = useState(false);
  const { data, size, setSize, isLoading, error, mutate, isValidating } = useSWRInfinite(
    getKey,
    (url) => integrationApp.get(url)
  );
  const items = data ? data.map((page) => page.items).flat() : [];
  const loading = isLoading;
  const refreshing = isValidating;
  async function loadMore() {
    const hasMoreToLoad = data[size - 1]?.items?.length === limit;
    if (hasMoreToLoad) {
      setIsLoadingMore(true);
      await setSize(size + 1);
      setIsLoadingMore(false);
    }
  }
  async function refresh() {
    await mutate();
  }
  return {
    items,
    refresh,
    refreshing,
    loadMore,
    loadingMore,
    loading,
    error
  };
}
function hashCode(s) {
  if (!s) {
    return 0;
  }
  const l = s.length;
  let h = 0, i = 0;
  if (l > 0) while (i < l) h = (h << 5) - h + s.charCodeAt(i++) | 0;
  return h;
}

export { useElements };
