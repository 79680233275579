import ExampleCodeBlock, {
  CodeParamType,
} from 'components/Docs/ExampleCodeBlock'

import { httpCodeExamples } from '../http-code-examples'

const js = `const dataSourceInstance = await integrationApp
  .connection('{INTEGRATION_KEY}')
  .dataSource('{DATA_SOURCE_KEY}')
  .get({ autoCreate: true })
`

const rest = httpCodeExamples(
  '/connections/{INTEGRATION_KEY}/data-sources/{DATA_SOURCE_KEY}?autoCreate=true',
  {
    method: 'GET',
  },
)

export function GetDataSourceInstanceCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js, ...rest }}
      parameters={{
        DATA_SOURCE_KEY: CodeParamType.DataSourceKey,
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
      }}
      run={async ({ parameters, integrationApp }) => {
        const dataSourceInstance = await integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .dataSource(parameters.DATA_SOURCE_KEY)
          .get({ autoCreate: true })

        return { dataSourceInstance }
      }}
    />
  )
}
