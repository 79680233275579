"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import useSWR from 'swr';
import { useIntegrationApp } from '../../contexts/integration-app-context.mjs';

function useConnectorSpec(integrationIdOrKey) {
  const integrationApp = useIntegrationApp();
  const { data, isLoading, error } = useSWR(
    integrationIdOrKey ? `/integrations/${integrationIdOrKey}/connector-spec` : void 0,
    () => integrationApp.integration(integrationIdOrKey).getConnectorSpec()
  );
  return { data, loading: isLoading, error };
}

export { useConnectorSpec };
