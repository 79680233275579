import { FlowNodeType, FlowRun } from '@integration-app/sdk'

import ExampleCodeBlock, {
  CodeParamType,
} from 'components/Docs/ExampleCodeBlock'
import useDocs from 'routes/Docs/components/docs-context'

import { useFlowInstanceContext } from '../../FlowBuilder/context/flow-instance-context'

const js = `await integrationApp
  .flowInstance('{FLOW_INSTANCE_ID}')
  .run({
    {{NODE_KEY_PARAM}}
    input: '{INPUT}'
  })
`

export function RunFlowInstanceCodeExample({
  parametersExpandedByDefault,
  showNodeKeyInExample = false,
  onAfterRun,
}: {
  parametersExpandedByDefault?: boolean
  showNodeKeyInExample?: boolean
  onAfterRun?: (flowRun: FlowRun) => Promise<any>
}) {
  const { flowInstance } = useFlowInstanceContext()
  const { parameters } = useDocs()

  let triggerKey = parameters.NODE_KEY
  if (!triggerKey) {
    for (const [key, node] of Object.entries(flowInstance?.nodes ?? {})) {
      if (node.type === FlowNodeType.ApiTrigger) {
        triggerKey = key
        break
      }
    }
  }

  const inputParamName = `INPUT/${flowInstance?.id}/${triggerKey}`
  const inputSchema = triggerKey
    ? flowInstance?.nodes?.[triggerKey]?.inputSchema
    : undefined

  const nodeKeyParam = showNodeKeyInExample
    ? "nodeKey: '{NODE_KEY}',\n    "
    : ''

  return (
    <ExampleCodeBlock
      parametersExpandedByDefault={parametersExpandedByDefault}
      customCodes={{ js: js.replace('{{NODE_KEY_PARAM}}', nodeKeyParam) }}
      parameters={{
        FLOW_INSTANCE_ID: CodeParamType.FlowInstanceId,
        ...(showNodeKeyInExample && { NODE_KEY: CodeParamType.String }),
        [inputParamName]: {
          title: 'Input',
          type: CodeParamType.DataSchema,
          schema: inputSchema,
          default: null,
        },
      }}
      run={async ({ integrationApp, parameters }) => {
        const result = await integrationApp
          .flowInstance(parameters.FLOW_INSTANCE_ID)
          .run({
            ...(showNodeKeyInExample && { nodeKey: parameters.NODE_KEY }),
            input: parameters[inputParamName],
          })

        await onAfterRun?.(result)

        return result
      }}
    />
  )
}
