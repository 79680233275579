"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { clsx } from 'clsx';
import { SvgIcon } from '../SvgIcon/index.mjs';
import '../../styled-system/css/css.mjs';
import '../../styled-system/helpers.mjs';
import { sva } from '../../styled-system/css/sva.mjs';

function GenericSvgIcon({ type, ...props }) {
  return /* @__PURE__ */ jsx(SvgIcon, { type, ...props, "aria-hidden": "true" });
}
function GenericImgIcon({ src, ...props }) {
  return /* @__PURE__ */ jsx("img", { src, ...props, "aria-hidden": "true" });
}
const itemIcon = sva({
  className: "item_icon",
  slots: ["icon"],
  variants: {
    framed: {
      true: {
        icon: {
          bg: "gray.3",
          borderColor: "gray.5",
          borderWidth: "0.5",
          color: "slate.11"
        }
      }
    }
  },
  base: {
    icon: {
      display: "flex",
      flexShrink: "0",
      flexGrow: "0",
      w: "6",
      h: "6",
      rounded: "lg",
      p: "1"
    }
  },
  defaultVariants: {
    framed: false
  }
});
function ItemIcon({
  className,
  framed,
  ...props
}) {
  const { icon } = itemIcon({ framed });
  if ("type" in props && props?.type) {
    return /* @__PURE__ */ jsx(GenericSvgIcon, { className: clsx(icon, className), ...props });
  }
  if ("src" in props && props?.src) {
    return /* @__PURE__ */ jsx(GenericImgIcon, { className: clsx(icon, className), ...props });
  }
  return null;
}

export { ItemIcon };
