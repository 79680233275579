import { Helmet } from 'react-helmet-async'

import useAuth from 'contexts/auth'

export const TITLE_SEPARATOR = ' · '

function useConsoleAppTitle(title?: string) {
  const { self } = useAuth()
  const name = self?.workspace?.name

  const devPrefix = process.env.NODE_ENV === 'development' ? '[DEV]' : ''
  const titles = [title, name, 'Integration Console'].filter(Boolean)

  return `${devPrefix} ${titles.join(TITLE_SEPARATOR)}`.trim()
}

export function HeadTitle({ children }: { children?: string | string[] }) {
  const title = useConsoleAppTitle(
    typeof children === 'string' ? children : children?.join(''),
  )
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  )
}

export function HeadMetatagsFavicons() {
  return (
    <>
      <link rel='icon' type='image/svg+xml' href='/images/favicon.svg' />
      <link
        rel='icon'
        type='image/png'
        sizes='32x32'
        href='/images/favicon-32x32.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='16x16'
        href='/images/favicon-16x16.png'
      />
    </>
  )
}

const googleFonts = [
  'https://fonts.googleapis.com/css2?family=IBM+Plex+Serif&display=swap',
  'https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap',
  'https://fonts.googleapis.com/css2?family=Inter:wght@400..700&display=swap',
]

export function HeadMetatagsGoogleFontsPreconnect() {
  return (
    <>
      <link
        rel='preconnect'
        href='https://fonts.googleapis.com'
        crossOrigin=''
      />
      <link rel='preconnect' href='https://fonts.gstatic.com' crossOrigin='' />
    </>
  )
}

export function HeadMetatagsGoogleFonts() {
  return (
    <>
      {googleFonts.map((href) => (
        <link key={`css_${href}`} href={href} rel='stylesheet' />
      ))}
    </>
  )
}
