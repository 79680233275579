import { createContext, useContext, ReactNode } from 'react'

import useAuth from 'contexts/auth'
import useApi from 'hooks/useApi'

type OnboardingStatus = 'show' | 'collapsed' | 'closed'

interface OnboardingContextType {
  status: OnboardingStatus
  showVideo: () => Promise<void>
  skipOnboarding: () => Promise<void>
  watchLater: () => Promise<void>
}

const OnboardingContext = createContext<OnboardingContextType | undefined>(
  undefined,
)

export const useOnboarding = () => {
  const context = useContext(OnboardingContext)
  if (!context) {
    throw new Error('useOnboarding must be used within an OnboardingProvider')
  }
  return context
}

export const OnboardingContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const { self, refresh, mutateSelf } = useAuth()
  const { apiClient } = useApi()

  // Do not show intro video if there are no workspaces
  const introVideoStatus = !!self?.workspaces?.length
    ? self?.user?.introVideoStatus
    : 'closed'

  async function updateIntroVideoStatus(status: OnboardingStatus) {
    await mutateSelf({
      ...self,
      user: {
        ...self.user,
        introVideoStatus: status,
      },
    })
    await apiClient.patch(`/platform-users`, {
      introVideoStatus: status,
    })
    await mutateSelf()
    refresh()
  }

  async function skipOnboarding() {
    return updateIntroVideoStatus('closed')
  }

  async function watchLater() {
    return updateIntroVideoStatus('collapsed')
  }

  async function showVideo() {
    return updateIntroVideoStatus('show')
  }

  return (
    <OnboardingContext.Provider
      value={{
        status: introVideoStatus,
        showVideo,
        skipOnboarding,
        watchLater,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  )
}
