"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import { Radiobutton } from '../Radio/index.mjs';
import classes from './SimpleInput.module.css.mjs';

function SimpleRadio({
  label,
  checked,
  onCheck
}) {
  return /* @__PURE__ */ jsxs("label", { className: classes.simpleRadio, onClick: onCheck, children: [
    /* @__PURE__ */ jsx(
      Radiobutton,
      {
        checked,
        onChange: () => {
        },
        className: classes.simpleRadio_radio
      }
    ),
    /* @__PURE__ */ jsx("span", { children: label })
  ] });
}

export { SimpleRadio };
