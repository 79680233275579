import { toHeaderCase } from 'js-convert-case'

import { Select, SelectItemType, ComboboxProps } from 'components/Select'

import { OptionItem } from './components'

const BOOLEAN_OPTIONS = [
  {
    value: 'true',
    rawValue: true,
    label: 'true',
  },
  {
    value: 'false',
    rawValue: false,
    label: 'false',
  },
]

export function BooleanComboboxSelect({
  name,
  value,
  onChange,
  ...props
}: Omit<ComboboxProps, 'items' | 'value' | 'onChange'> & {
  name: string
  value?: boolean
  onChange: (value?: string) => void
}) {
  const readableName = toHeaderCase(name)

  const selectedValue = BOOLEAN_OPTIONS.find(
    (option) => option.rawValue === value,
  )

  function handleChange(item: SelectItemType) {
    onChange(item?.rawValue)
  }

  return (
    <Select
      items={BOOLEAN_OPTIONS}
      value={selectedValue}
      onValueChange={handleChange}
      TriggerTextComponent={(props) => (
        <OptionItem asTrigger name={readableName} {...props} />
      )}
      {...props}
    />
  )
}
