"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { SvgIconType } from '../../SvgIcon/svg-icon-type.mjs';
import { NestedLevel, Row, Cell } from '../../DataBuilderUI/index.mjs';
import { DataBuilderFieldProvider } from '../field-context.mjs';
import { FieldDelete } from '../FieldDelete.mjs';
import DataBuilderFormFieldValue from '../FieldValue/index.mjs';
import DataBuilderFormNested from '../Nested.mjs';
import { DataBuilderFieldControlPanel } from './FieldControlPanel/index.mjs';

function DataBuilderFormNestedArrayItems({
  field,
  onChange
}) {
  const allowToAdd = !field.schema?.maxItems || (field?.subFields ?? [])?.length < field.schema.maxItems;
  function handleAddItem() {
    onChange([...field.value ?? [], null]);
  }
  if (Array.isArray(field.value)) {
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(ArrayItems, { field, onChange }),
      allowToAdd && /* @__PURE__ */ jsx(DataBuilderFieldControlPanel, { field, onAdd: handleAddItem })
    ] });
  } else if (field.value) {
    return null;
  } else {
    return /* @__PURE__ */ jsx(Fragment, { children: allowToAdd && /* @__PURE__ */ jsx(DataBuilderFieldControlPanel, { field, onAdd: handleAddItem }) });
  }
}
function ArrayItems({ field, onChange }) {
  function handleItemChange(index, value) {
    const newValue = [...field.value || []];
    newValue[index] = value;
    onChange(newValue);
  }
  function handleDeleteItem(index) {
    const newValue = [...field.value || []];
    newValue.splice(index, 1);
    onChange(newValue.length > 0 ? newValue : void 0);
  }
  return /* @__PURE__ */ jsx(Fragment, { children: field.subFields.map((itemField, index) => {
    return /* @__PURE__ */ jsx(
      DataComposerFieldArrayItem,
      {
        field: itemField,
        onChange: (value) => handleItemChange(index, value),
        onDelete: () => handleDeleteItem(index)
      },
      index
    );
  }) });
}
function DataComposerFieldArrayItem({
  field,
  onChange,
  onDelete = void 0
}) {
  function handleChange(value) {
    onChange?.(value);
  }
  return /* @__PURE__ */ jsxs(DataBuilderFieldProvider, { field, children: [
    /* @__PURE__ */ jsx(NestedLevel, { level: field.level, children: /* @__PURE__ */ jsxs(Row, { children: [
      /* @__PURE__ */ jsx(Cell.Icon, { type: SvgIconType.Array }),
      /* @__PURE__ */ jsx(Cell.Combobox, { grow: true, children: /* @__PURE__ */ jsx(DataBuilderFormFieldValue, { field, onChange: handleChange }) }),
      onDelete && /* @__PURE__ */ jsx(FieldDelete, { onClick: onDelete })
    ] }) }),
    /* @__PURE__ */ jsx(DataBuilderFormNested, { field, onChange: handleChange })
  ] });
}

export { DataBuilderFormNestedArrayItems as default };
