"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import { useState, useEffect } from 'react';
import { clsx } from 'clsx';
import '@integration-app/sdk';
import 'js-convert-case';
import { FieldDescription } from '../DataBuilder/FieldDescription.mjs';
import { Row, Cell, Title } from '../DataBuilderUI/index.mjs';
import { Input } from '../Input/Input.mjs';
import '../Input/SearchInput.mjs';
import classes from './SimpleInput.module.css.mjs';

function SimpleInput({
  label,
  value,
  description,
  onChange,
  ...props
}) {
  function handleChange(e) {
    onChange(e.target.value);
  }
  return /* @__PURE__ */ jsxs(Row, { className: clsx(classes.row, props?.disabled && classes.rowDisabled), children: [
    label && /* @__PURE__ */ jsx(Cell.Name, { children: /* @__PURE__ */ jsx(Title, { rightSlot: /* @__PURE__ */ jsx(FieldDescription, { text: description }), children: /* @__PURE__ */ jsx("span", { className: "whitespace-nowrap", children: label }) }) }),
    /* @__PURE__ */ jsx(Cell.Input, { className: classes.cell, grow: true, children: /* @__PURE__ */ jsx(
      Input,
      {
        value: value ?? "",
        onChange: handleChange,
        variant: "transparent",
        disabled: props?.disabled,
        ...props,
        className: classes.input
      }
    ) })
  ] });
}
function SimpleInputWithLocalState({
  value,
  onChange,
  ...props
}) {
  const [localValue, setLocalValue] = useState(value);
  const [dirtyInput, setDirtyInput] = useState(false);
  useEffect(() => {
    !dirtyInput && setLocalValue(value);
  }, [value, dirtyInput]);
  function handleLocalChange(value2) {
    setLocalValue(value2);
    setDirtyInput(true);
  }
  function handleFocus(e) {
    props?.onFocus?.(e);
    setDirtyInput(true);
  }
  function handleBlur(e) {
    props?.onBlur?.(e);
    handleChange();
  }
  function handleChange() {
    onChange(localValue);
    setDirtyInput(false);
  }
  function handleKeyUp(e) {
    props.onKeyUp?.(e);
    if (e.key === "Enter") {
      handleChange();
    }
  }
  return /* @__PURE__ */ jsx(
    SimpleInput,
    {
      value: localValue,
      onChange: handleLocalChange,
      onFocus: handleFocus,
      onBlur: handleBlur,
      onKeyUp: handleKeyUp,
      ...props
    }
  );
}
const DataBuilderSimpleInput = SimpleInput;

export { DataBuilderSimpleInput, SimpleInput, SimpleInputWithLocalState };
