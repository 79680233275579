import { CSSProperties } from 'react'
import dynamic from 'next/dynamic'

import classes from './JsonViewer.module.css'

// disable ssr
const DynamicReactJsonView = dynamic(
  () => import('@microlink/react-json-view'),
  {
    ssr: false,
  },
)

export function JsonViewer({
  json,
  truncateStringsAfter = false,
  collapsed = false,
  style = {},
}: {
  json?: any
  truncateStringsAfter?: number | false
  style?: CSSProperties
  collapsed?: boolean | number
}) {
  if (typeof window === 'undefined') return null

  if (json === null || json === undefined) {
    return '<empty data>'
  }

  if (typeof json !== 'object') {
    return json.toString()
  }

  return (
    <div className={classes.json_viewer}>
      <DynamicReactJsonView
        style={style}
        name={null} // disable root name
        iconStyle='square'
        displayDataTypes={false}
        displayObjectSize={false}
        quotesOnKeys={false}
        theme='rjv-default'
        src={json ?? {}}
        collapsed={collapsed}
        collapseStringsAfterLength={truncateStringsAfter}
        shouldCollapse={({ namespace, src, type }) => {
          const tooDeep = namespace.length > 2 // collapse at depth 3
          const tooManyItems = type === 'array' && (src as any[]).length > 20 // collapse arrays with more than 20 items

          return tooDeep || tooManyItems
        }}
      />
    </div>
  )
}
