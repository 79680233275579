"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useState } from 'react';
import { Button } from './Button/Button.mjs';

function AdvancedOptions({
  title = "advanced options",
  openByDefault = false,
  children
}) {
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(openByDefault);
  return showAdvancedOptions ? /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      Button,
      {
        variant: "ajax",
        onClick: () => setShowAdvancedOptions(false),
        className: "-ml-6 self-start",
        children: [
          "Hide ",
          title
        ]
      }
    ),
    /* @__PURE__ */ jsx("div", { className: "flex flex-col gap-2", children })
  ] }) : /* @__PURE__ */ jsxs(
    Button,
    {
      variant: "ajax",
      onClick: () => setShowAdvancedOptions(true),
      className: "-ml-6 self-start",
      children: [
        "Show ",
        title
      ]
    }
  );
}

export { AdvancedOptions };
