"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { parseDataLocationPath } from '@integration-app/sdk';
import useSWR from 'swr';
import { useIntegrationApp } from '../../contexts/integration-app-context.mjs';

function useDataCollectionSpec({
  path,
  key,
  integrationId
}) {
  const client = useIntegrationApp();
  const dataCollectionKey = key ?? parseDataLocationPath(path)?.key;
  const { data: dataCollectionSpec } = useSWR(
    dataCollectionKey && integrationId ? `/integrations/${integrationId}/data/${dataCollectionKey}` : null,
    async () => {
      return client.integration(integrationId).getDataCollection(dataCollectionKey);
    }
  );
  return dataCollectionSpec;
}

export { useDataCollectionSpec };
