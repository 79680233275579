"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import useSWR from 'swr';
import { useIntegrationApp } from '../../contexts/integration-app-context.mjs';

function useDataSourceInstanceCollection(dataSourceInstance) {
  const integrationApp = useIntegrationApp();
  const { data, error, isLoading, mutate } = useSWR(
    dataSourceInstance ? `${dataSourceInstance.id}/collection` : null,
    () => integrationApp.dataSourceInstance(dataSourceInstance.id).getCollection()
  );
  async function refresh() {
    return await mutate();
  }
  const collection = data;
  return {
    collection,
    refresh,
    error,
    loading: isLoading
  };
}

export { useDataSourceInstanceCollection };
