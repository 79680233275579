"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { dropdownRecipe } from '../../recipes/dropdown.mjs';
import { Truncate } from '../Truncate/index.mjs';
import { useDropdownItemContext } from './item-context.mjs';

function ItemTitle({ children }) {
  const styles = dropdownRecipe();
  const { id } = useDropdownItemContext();
  return /* @__PURE__ */ jsx(
    "div",
    {
      className: styles["item-title"],
      id,
      "aria-label": typeof children === "string" ? children : void 0,
      children: /* @__PURE__ */ jsx(Truncate, { mode: "end", children })
    }
  );
}

export { ItemTitle };
