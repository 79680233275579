"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { useEffect, useRef } from 'react';
import deepEqual from 'fast-deep-equal';

const isPrimitive = (value) => ["number", "string", "boolean"].includes(typeof value);
const warnDeps = (dependencies) => {
  if (dependencies.length === 0) {
    console.warn(
      "useDeepEffect should not be used with no dependencies. Use useEffect instead."
    );
  }
  if (dependencies.every(isPrimitive)) {
    console.warn(
      "useDeepEffect should not be used with primitive values. Use useEffect instead."
    );
  }
};
const getTriggerDeps = (dependencies, comparisonFn) => {
  const ref = useRef();
  const triggerDeps = useRef(0);
  if (!comparisonFn(dependencies, ref.current)) {
    ref.current = dependencies;
    triggerDeps.current = Math.random();
  }
  return [triggerDeps.current];
};
const useDeepEffect = (fn, dependencies = [], comparisonFn = deepEqual) => {
  warnDeps(dependencies);
  return useEffect(fn, getTriggerDeps(dependencies, comparisonFn));
};

export { useDeepEffect };
