import ExampleCodeBlock from 'components/Docs/ExampleCodeBlock'

import { useWorkspace } from '../../Workspaces/workspace-context'

export function SdkFetchTokenCodeExample() {
  const { testCustomerAccessToken } = useWorkspace()

  const js = `import { IntegrationAppClient } from '@integration-app/sdk'

const integrationApp = new IntegrationAppClient({
  // Test authentication token. You will need to replace it with the real one.
  async fetchToken() {
    return '${testCustomerAccessToken}';
  },
})
`

  return <ExampleCodeBlock customCodes={{ js }} />
}
