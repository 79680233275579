import {
  CodeBlockNotification,
  CodeBlockRunButton,
} from './CodeBlockComponents'
import classes from './LoginToRun.module.css'

export function LoginToRun({ children }) {
  return (
    <div className={classes.loginToRun}>
      <CodeBlockRunButton disabled>Run Now</CodeBlockRunButton>
      <CodeBlockNotification>{children}</CodeBlockNotification>
    </div>
  )
}
