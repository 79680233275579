import ExampleCodeBlock, {
  CodeParamType,
} from 'components/Docs/ExampleCodeBlock'

import { httpCodeExamples } from '../http-code-examples'

const js = `await integrationApp
  .connection('{INTEGRATION_KEY}')
  .dataSource('{DATA_SOURCE_KEY}', { instanceKey: '{INSTANCE_KEY}' })
  .get({ autoCreate: true })
`

const rest = httpCodeExamples(
  '/connections/{INTEGRATION_KEY}/data-sources/{DATA_SOURCE_KEY}?instanceKey={INSTANCE_KEY}&autoCreate=true',
)

export function GetDataSourceInstanceWithInstanceKeyCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js, ...rest }}
      parameters={{
        INSTANCE_KEY: CodeParamType.String,
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
        DATA_SOURCE_KEY: CodeParamType.DataSourceKey,
      }}
      run={async ({ integrationApp, parameters }) => {
        return await integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .dataSource(parameters.DATA_SOURCE_KEY, {
            instanceKey: parameters.INSTANCE_KEY,
          })
          .get({ autoCreate: true })
      }}
    />
  )
}
