"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { useElements } from '../useElements.mjs';

function useDataSourceInstances(query) {
  const { ...rest } = useElements(
    "data-source-instances",
    query
  );
  return {
    dataSourceInstances: rest.items,
    ...rest
  };
}

export { useDataSourceInstances };
