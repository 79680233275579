import {
  ErrorData,
  ErrorType,
  extractIntegrationAppErrorData,
  IntegrationAppError,
} from '@integration-app/sdk'

export type SupportedErrorTypes =
  | string
  | Error
  | IntegrationAppError
  | ErrorData
  | Record<string, unknown>

export function convertAnyErrorToErrorData(
  error: SupportedErrorTypes,
): ErrorData {
  return isErrorData(error) ? error : extractIntegrationAppErrorData(error)
}

function isErrorData(obj: any): obj is ErrorData {
  if (obj instanceof ErrorData) {
    return true
  }

  // if object has message and type properties
  // and type property is one of the ErrorType values
  // and message is a string
  if (
    typeof obj === 'object' &&
    obj !== null &&
    typeof obj.message === 'string' &&
    Object.values(ErrorType).includes(obj.type)
  ) {
    return true
  }

  return false
}

export function getErrorAdditionalData(
  errorData: ErrorData,
): Pick<ErrorData, 'data' | 'logs' | 'causedByError'> | undefined {
  const { data, logs, causedByError } = errorData

  const additionalData: Record<string, unknown> = {}
  if (data) {
    additionalData.data = getObjectWithoutWorkspaceElementReference(data)
  }
  if (logs) {
    additionalData.logs = logs
  }
  if (causedByError) {
    additionalData.causedByError = causedByError
  }

  return !Object.keys(additionalData).length ? undefined : additionalData
}

export function hasErrorAdditionalData(errorData?: ErrorData) {
  if (!errorData) return false
  const { logs, causedByError } = errorData
  const data = getObjectWithoutWorkspaceElementReference(errorData.data)

  return !!(data && Object.keys(data).length > 0) || !!logs || !!causedByError
}

export function getErrorDataWorkspaceElementReference(errorData?: ErrorData) {
  return errorData?.data?.workspaceElementReference
}

function getObjectWithoutWorkspaceElementReference(obj: unknown) {
  if (!obj || typeof obj !== 'object') return obj
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => key !== 'workspaceElementReference'),
  )
}
