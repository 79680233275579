"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsx, Fragment } from 'react/jsx-runtime';
import { createContext, useState, useContext, useRef, useEffect } from 'react';
import { getRefElement } from '../../../helpers/getRefElement.mjs';

const DropdownStackContext = createContext({
  isNested: false,
  stack: [],
  setStack: () => {
  }
});
const DropdownStackContextProvider = ({
  children
}) => {
  const [stack, setStack] = useState([]);
  const { isNested: hasNestedDropdownsContext } = useContext(DropdownStackContext);
  if (hasNestedDropdownsContext) {
    return /* @__PURE__ */ jsx(Fragment, { children });
  }
  return /* @__PURE__ */ jsx(
    DropdownStackContext.Provider,
    {
      value: {
        isNested: true,
        stack,
        setStack
      },
      children
    }
  );
};
const useDropdownStackContext = (dropdownRef) => {
  const { isNested, stack, setStack } = useContext(DropdownStackContext);
  const recordRef = useRef(dropdownRef);
  useEffect(() => {
    if (isNested) {
      setStack((prev) => [...prev, recordRef?.current]);
    } else {
      setStack([recordRef?.current]);
    }
    return () => {
      setStack((prev) => prev.filter((record) => record !== recordRef?.current));
    };
  }, []);
  function isClickInsideRefsAndChildren(event) {
    const stackStartedFromElement = stack.slice(
      stack.indexOf(recordRef?.current)
    );
    return stackStartedFromElement.some((ref) => {
      return isClickInsideRefRecord(ref, event);
    });
  }
  return { isClickInsideRefsAndChildren };
};
function isClickInsideRefRecord(record, event) {
  const element = getRefElement(record);
  return !!(element && element.contains(event.target));
}

export { DropdownStackContextProvider, useDropdownStackContext };
