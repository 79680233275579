/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import styleInject from 'style-inject';

var css_248z = "@layer elements {\n  .SimpleInput-module_row__6hKYf {\n    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;\n    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n    transition-duration: 150ms;\n  }\n  .SimpleInput-module_rowDisabled__4tZXo {\n    --tw-bg-opacity: 1;\n    background-color: rgb(248 248 248 / var(--tw-bg-opacity));\n    --tw-text-opacity: 1;\n    color: rgb(105 105 105 / var(--tw-text-opacity));\n  }\n    .SimpleInput-module_rowDisabled__4tZXo .SimpleInput-module_input__YrGJe,.SimpleInput-module_rowDisabled__4tZXo .SimpleInput-module_textarea__8wEzp {\n    background-color: transparent;\n    --tw-text-opacity: 1;\n    color: rgb(105 105 105 / var(--tw-text-opacity));\n  }\n\n  .SimpleInput-module_row__6hKYf:focus-within .SimpleInput-module_cell__iN08T {\n    --tw-bg-opacity: 1;\n    background-color: rgb(248 248 248 / var(--tw-bg-opacity));\n  }\n\n  .SimpleInput-module_row__6hKYf .SimpleInput-module_cell__iN08T:has(.SimpleInput-module_input__YrGJe) {\n    padding-top: 0.35rem;\n    padding-bottom: 0.35rem;\n  }\n  .SimpleInput-module_row__6hKYf .SimpleInput-module_cell__iN08T:has(.SimpleInput-module_textarea__8wEzp) {\n    padding: 0;\n  }\n    :is(.SimpleInput-module_row__6hKYf .SimpleInput-module_cell__iN08T:has(.SimpleInput-module_textarea__8wEzp)) .SimpleInput-module_textarea__8wEzp,:is(.SimpleInput-module_row__6hKYf .SimpleInput-module_cell__iN08T:has(.SimpleInput-module_textarea__8wEzp)) > [class*='textareaWrapper']::after {\n      padding-top: 0.75rem;\n      padding-bottom: 0.75rem;\n      width: 100%;\n      form-sizing: normal;\n    }\n  .SimpleInput-module_simpleRadio__71CyG {\n    display: flex;\n    cursor: pointer;\n    align-items: flex-start;\n    gap: 0.25rem;\n    width: -moz-fit-content;\n    width: fit-content;\n  }\n  .SimpleInput-module_simpleRadio_radio__2OU8S {\n    margin-top: 0.13rem;\n  }\n}\n";
var classes = {"row":"SimpleInput-module_row__6hKYf","rowDisabled":"SimpleInput-module_rowDisabled__4tZXo","input":"SimpleInput-module_input__YrGJe","textarea":"SimpleInput-module_textarea__8wEzp","cell":"SimpleInput-module_cell__iN08T","simpleRadio":"SimpleInput-module_simpleRadio__71CyG","simpleRadio_radio":"SimpleInput-module_simpleRadio_radio__2OU8S"};
styleInject(css_248z);

export { classes as default };
