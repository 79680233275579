"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import { getFullNameForLocator } from '@integration-app/sdk';
import { SvgIconType } from '../../../SvgIcon/svg-icon-type.mjs';
import { ComboBox } from '../../../ComboBox/ComboBox.mjs';
import { Row, Cell } from '../../../DataBuilderUI/index.mjs';
import { TagPlaceholder } from '../../../Tag/TagPlaceholder.mjs';
import { isObjectAllowedAdditionalProperties, fieldLabel } from '../../data-field.mjs';

function AddingFieldSelector({
  parentField,
  fieldsToAdd,
  onChange,
  onClose
}) {
  const allowAdditionalProperties = isObjectAllowedAdditionalProperties(parentField);
  const options = fieldsToAdd.map((f) => ({
    value: f.key,
    label: getFullNameForLocator(parentField.schema, f.key) || fieldLabel(f)
  }));
  allowAdditionalProperties && options.unshift({
    value: "",
    label: "Add custom field",
    iconType: SvgIconType.Add
  });
  return /* @__PURE__ */ jsxs(Row, { children: [
    /* @__PURE__ */ jsx(Cell.Combobox, { width: "14rem", children: /* @__PURE__ */ jsx(
      ComboBox,
      {
        value: void 0,
        options,
        schema: {
          type: "string",
          allowCustom: allowAdditionalProperties
        },
        onChange,
        onClose,
        autoOpen: true
      }
    ) }),
    /* @__PURE__ */ jsx(Cell.Combobox, { grow: true, disabled: true, children: /* @__PURE__ */ jsx(TagPlaceholder, { children: "Select value" }) })
  ] });
}

export { AddingFieldSelector };
