import { SvgIcon, SvgIconType } from '@integration-app/react'

import { Button, ButtonProps } from 'ui-kit/button'

import classes from './CodeBlockComponents.module.css'

export function CodeBlockRunButton(props: ButtonProps) {
  return <Button variant={'outline'} {...props} />
}

export function CodeBlockNotification({ children }) {
  return (
    <div className={classes.codeExampleNotification}>
      <SvgIcon type={SvgIconType.InfoCircle} />
      <div>{children}</div>
    </div>
  )
}
