"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { Spinner } from './spinner.mjs';
import { styled } from '../styled-system/jsx/factory.mjs';
import '../styled-system/jsx/is-valid-prop.mjs';
import '../styled-system/helpers.mjs';
import '../styled-system/css/css.mjs';
import { Center } from '../styled-system/jsx/center.mjs';
import '../styled-system/patterns/visually-hidden.mjs';
import { Button as Button$1 } from './styled/button.mjs';

const Button = forwardRef(
  (props, ref) => {
    const { loading, disabled, loadingText, children, ...rest } = props;
    const trulyDisabled = loading || disabled;
    return /* @__PURE__ */ jsx(Button$1, { disabled: trulyDisabled, ref, ...rest, children: loading && !loadingText ? /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(ButtonSpinner, {}),
      /* @__PURE__ */ jsx(styled.span, { opacity: 0, children })
    ] }) : loadingText ? loadingText : children });
  }
);
Button.displayName = "Button";
const ButtonSpinner = () => /* @__PURE__ */ jsx(
  Center,
  {
    inline: true,
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    insetStart: "50%",
    children: /* @__PURE__ */ jsx(Spinner, { colorPalette: "gray" })
  }
);

export { Button };
