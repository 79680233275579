import { ComponentProps } from 'react'
import { WorkspaceElementState } from '@integration-app/sdk'

import { StatusDot } from '../StatusDot'

import { WORKSPACE_ELEMENT_STATE_TO_COLOR_PALETTE } from '.'

export function WorkspaceElementStateDot({
  state,
  className,
  ...props
}: ComponentProps<typeof StatusDot> & {
  state: WorkspaceElementState
}) {
  return (
    <StatusDot
      status={WORKSPACE_ELEMENT_STATE_TO_COLOR_PALETTE(state)}
      className={className}
      {...props}
    />
  )
}
