import { ReactNode, useState } from 'react'
import { IconButton, SvgIcon, SvgIconType } from '@integration-app/react'

import clsx from 'utils/clsx'

import classes from './ErrorMessage.module.css'

function ErrorMessageRoot({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) {
  return <div className={clsx(classes.wrapper, className)}>{children}</div>
}

function ErrorMessageHeader({ children }) {
  const [minimized, setMinimized] = useState(false)
  function toggleMinimized() {
    setMinimized((m) => !m)
  }

  return (
    <div
      className={clsx(classes.header, minimized && classes.header__minimized)}
    >
      <h3>{children}</h3>
      <IconButton size={'small'} onClick={toggleMinimized}>
        <SvgIcon
          type={
            minimized ? SvgIconType.WindowMaximize : SvgIconType.WindowMinimize
          }
        />
      </IconButton>
    </div>
  )
}
function ErrorMessageContent({ children }) {
  return <div className={classes.content}>{children}</div>
}

const ErrorMessage = Object.assign(ErrorMessageRoot, {
  Header: ErrorMessageHeader,
  Content: ErrorMessageContent,
})
export default ErrorMessage
