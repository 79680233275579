"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import { useRef } from 'react';
import { useIsTextTruncated } from '../../helpers/hooks/useIsTextTruncated.mjs';
import '../../styled-system/css/css.mjs';
import '../../styled-system/helpers.mjs';
import '../../styled-system/jsx/is-valid-prop.mjs';
import { Box } from '../../styled-system/jsx/box.mjs';
import { Flex } from '../../styled-system/jsx/flex.mjs';
import '../../styled-system/patterns/visually-hidden.mjs';
import { Text } from '../../ui-kit/styled/text.mjs';
import { Tooltip } from '../Tooltip/index.mjs';

function Truncate({
  mode = "middle",
  children,
  endLetters = 5,
  className
}) {
  const overflowContainer = useRef(null);
  const isTruncated = useIsTextTruncated(overflowContainer);
  if (typeof children !== "string" || children.length < endLetters) {
    return className ? /* @__PURE__ */ jsx("span", { className, children }) : /* @__PURE__ */ jsx(Fragment, { children });
  }
  if (mode === "end") {
    return /* @__PURE__ */ jsx(
      Tooltip,
      {
        disabled: !isTruncated,
        tooltip: children,
        placement: "top",
        className,
        type: "dropdown",
        withArrow: false,
        children: /* @__PURE__ */ jsx(Box, { truncate: true, ref: overflowContainer, children })
      }
    );
  }
  const [startSegment, endSegment] = getSplittedStringAt(
    children,
    getSplitStringPosition(children, endLetters)
  );
  return /* @__PURE__ */ jsx(Tooltip, { disabled: !isTruncated, tooltip: children, placement: "top-start", children: /* @__PURE__ */ jsx(Text, { as: "span", display: "inline-flex", overflow: "hidden", maxW: "full", children: /* @__PURE__ */ jsxs(
    Flex,
    {
      display: "inline-flex",
      w: "fit-content",
      maxW: "full",
      flexDirection: "row",
      wrap: "nowrap",
      className,
      children: [
        startSegment && /* @__PURE__ */ jsx(
          Box,
          {
            ref: overflowContainer,
            display: "inline-block",
            flexGrow: "1",
            truncate: true,
            children: startSegment
          }
        ),
        endSegment && /* @__PURE__ */ jsx(Box, { display: "inline-block", flexShrink: "0", flexGrow: "0", children: endSegment })
      ]
    }
  ) }) });
}
function getSplitStringPosition(string, endLetters) {
  const stringLength = string.length;
  const lastIndexOfSpace = string.lastIndexOf(" ");
  const lastIndexOfAngleBracket = string.lastIndexOf(">");
  if (stringLength <= endLetters) {
    return stringLength;
  }
  if (lastIndexOfSpace < 0 && lastIndexOfAngleBracket < 0) {
    return endLetters;
  }
  const positionOfDelimiter = stringLength - Math.max(lastIndexOfAngleBracket, lastIndexOfSpace);
  if (positionOfDelimiter > endLetters * 1.8) {
    return endLetters;
  }
  return positionOfDelimiter;
}
function getSplittedStringAt(str, position) {
  const startSegment = str.slice(0, -position).replace(/\s/g, "\xA0");
  const endSegment = str.slice(-1 * position).replace(/\s/g, "\xA0");
  if (!startSegment && endSegment) {
    return [endSegment];
  }
  return [startSegment, endSegment];
}

export { Truncate };
