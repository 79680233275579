import { DataForm, useFieldMappingInstance } from '@integration-app/react'

import { CodeParamType } from '../../Docs/ExampleCodeBlock'
import ExampleUIBlock from '../../Docs/ExampleUIBlock'
import { SimpleLayout } from '../../Docs/SimpleLayout'

export function FieldMappingInstanceUiWithDataFormCodeExample() {
  return (
    <ExampleUIBlock
      customCodes={{ jsx }}
      Component={Component}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
        FIELD_MAPPING_KEY: CodeParamType.FieldMappingKey,
      }}
    />
  )
}

const jsx = `import { useFieldMappingInstance, DataForm } from '@integration-app/react'

export function FieldMappingUI() {
  const { fieldMappingInstance, patch, loading, error } =
    useFieldMappingInstance({
      integrationKey: '{INTEGRATION_KEY}',
      fieldMappingKey: '{FIELD_MAPPING_KEY}',
      autoCreate: true
    })

  if (!fieldMappingInstance) {
    return (
      <div>
        {loading && <div>Loading...</div>}
        {error && <div>Error: {error.message}</div>}
      </div>
    )
  }

  const importForm = new DataForm({
    schema: fieldMappingInstance.appSchema,
    value: fieldMappingInstance.importValue,
    variablesSchema: fieldMappingInstance.externalSchema
  })

  function handleOptionSelected(field, idx) {
    const option = importForm.getFieldValueOptions(field)[idx]
    const newImportValue = importForm.setFieldValue(
      field,
      option ? option.value : undefined,
    )

    return patch({ importValue: newImportValue })
  }

  return (
    <table>
      <tr>
        <th>Field</th>
        <th>Value</th>
      </tr>
      {importForm.getFields().map((field) => (
        <tr key={field.locator}>
          <td>{field.name}</td>
          <td>
            <select
              onChange={(e) => handleOptionSelected(field, e.target.value)}
            >
              <option></option>
              {importForm.getFieldValueOptions(field).map((option, idx) => (
                <option key={idx} value={idx} selected={option.selected}>
                  {option.name}
                </option>
              ))}
            </select>
          </td>
        </tr>
      ))}
    </table>
  )
}
`

function Component({ parameters }) {
  const { fieldMappingInstance, patch, loading, error } =
    useFieldMappingInstance({
      integrationKey: parameters.INTEGRATION_KEY,
      fieldMappingKey: parameters.FIELD_MAPPING_KEY,
      autoCreate: true,
    })

  if (!fieldMappingInstance) {
    return (
      <div>
        {loading && <div>Loading...</div>}
        {error && <div>Error: {error.message}</div>}
      </div>
    )
  }

  const importForm = new DataForm({
    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2322): Type 'DataSchema | undefined' is not assignable to... Remove this comment to see the full error message
    schema: fieldMappingInstance.appSchema,
    value: fieldMappingInstance.importValue,
    variablesSchema: fieldMappingInstance.externalSchema,
  })

  function handleOptionSelected(field, idx) {
    const option = importForm.getFieldValueOptions(field)[idx]
    const newImportValue = importForm.setFieldValue(
      field,
      option ? option.value : undefined,
    )
    return patch({ importValue: newImportValue })
  }

  return (
    <SimpleLayout>
      <table>
        <tr>
          <th>Field</th>
          <th>Value</th>
        </tr>
        {importForm.getFields().map((field) => (
          <tr key={field.locator}>
            <td>{field.name}</td>
            <td>
              <select
                onChange={(e) => handleOptionSelected(field, e.target.value)}
              >
                <option></option>
                {importForm.getFieldValueOptions(field).map((option, idx) => (
                  <option key={idx} value={idx} selected={option.selected}>
                    {option.name}
                  </option>
                ))}
              </select>
            </td>
          </tr>
        ))}
      </table>
    </SimpleLayout>
  )
}
