import { Flex } from 'styled-system/jsx'
import { Heading } from 'ui-kit/heading'

import dropdownRecipe from './dropdown.json'

const recipeMapping = {
  dropdown: dropdownRecipe,
} as const

export const ComponentRecipeTable: React.FC<{
  component: keyof typeof recipeMapping
}> = ({ component }) => {
  const recipe = recipeMapping[component]

  if (!recipe) {
    return null
  }

  return (
    <Flex flexDir='column' gap='4'>
      <Heading as='h4' textStyle='md' m='0!'>
        Component: <code>{recipe.component}</code>
      </Heading>
      <table className={'stripe w-full'}>
        <thead>
          <tr>
            <th>
              Part <code>className</code>
            </th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {recipe.slots.map((slot) => (
            <tr key={slot.slot}>
              <td>
                <code>
                  {recipe.component}__{slot.slot}
                </code>
              </td>
              <td>{slot.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Flex>
  )
}
