"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import '../../styled-system/css/css.mjs';
import '../../styled-system/helpers.mjs';
import { sva } from '../../styled-system/css/sva.mjs';
import { token } from '../../styled-system/tokens/index.mjs';
import { Root, Trigger, Positioner, Content, Arrow, ArrowTip } from '../../ui-kit/styled/tooltip.mjs';

const tooltipRecipe = sva({
  className: "tooltip",
  slots: ["content", "arrow-tip"],
  base: {
    content: {
      shadow: "none",
      fontWeight: "normal"
    }
  },
  variants: {
    type: {
      default: {
        content: {
          background: "neutral.3",
          rounded: "sm",
          color: "black"
        },
        "arrow-tip": {
          background: "neutral.3!"
        }
      },
      dropdown: {
        content: {
          background: "black",
          rounded: "xl",
          color: "white",
          fontWeight: "medium",
          px: "2",
          py: "1.5",
          maxW: "full"
        },
        "arrow-tip": {
          background: "black!"
        }
      }
    }
  },
  defaultVariants: {
    type: "default"
  }
});
const Tooltip = forwardRef(
  ({
    tooltip,
    placement = "right",
    delayToShow = 300,
    delayToHide = 50,
    offset = [0, 6],
    className,
    children,
    disabled,
    withArrow = true,
    type = "default"
  }, ref) => {
    const styles = tooltipRecipe({ type });
    const [crossAxis, mainAxis] = offset;
    return /* @__PURE__ */ jsxs(
      Root,
      {
        lazyMount: true,
        openDelay: delayToShow,
        closeDelay: delayToHide,
        positioning: {
          offset: {
            mainAxis,
            crossAxis
          },
          placement
        },
        disabled,
        children: [
          /* @__PURE__ */ jsx(Trigger, { asChild: true, children }),
          /* @__PURE__ */ jsx(Positioner, { className, ref, children: /* @__PURE__ */ jsxs(Content, { className: styles.content, children: [
            withArrow && /* @__PURE__ */ jsx(
              Arrow,
              {
                style: {
                  // https://github.com/cschroeter/park-ui/issues/411
                  // @ts-ignore
                  "--arrow-size": token(`spacing.2`)
                },
                children: /* @__PURE__ */ jsx(ArrowTip, { className: styles["arrow-tip"] })
              }
            ),
            tooltip
          ] }) })
        ]
      }
    );
  }
);

export { Tooltip };
