// Putting these env variables into an object prevents builder from optimizing them out because they are constants.
// This is necessary to change these variables at runtime via entrypoint.sh.
export const ENV_CONFIG = {
  BASE_URI: process.env.NEXT_PUBLIC_BASE_URI,
  ENGINE_API_URI: process.env.https://api-test.integration.app,
  ENGINE_UI_URI: process.env.https://ui-test.integration.app,
  AUTH0_DOMAIN: process.env.login.integration.app,
  AUTH0_CLIENT_ID: process.env.gjDhYhBExr4DwZfodxNKgRpo8FvXoChW,
  AUTH0_CLIENT_ID_PROD: process.env.gjDhYhBExr4DwZfodxNKgRpo8FvXoChW_PROD,
  ALGOLIA_APP_ID: process.env.,
  ALGOLIA_API_KEY: process.env.,
  ENABLE_TRACKING: process.env.,
  USE_CONNECTOR_STORE: process.env.,
  PYLON_APP_ID: process.env.,
}
