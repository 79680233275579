"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { createContext, useId, useState, useEffect, useContext } from 'react';

const ComboBoxContext = createContext({
  value: null,
  /* FIXME: strictNullCheck temporary fix */
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'DataSchema ... Remove this comment to see the full error message
  schema: null,
  /* FIXME: strictNullCheck temporary fix */
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'IValueSpec ... Remove this comment to see the full error message
  valueSpec: null,
  variablesSchema: null,
  /* FIXME: strictNullCheck temporary fix */
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'IComboBoxOp... Remove this comment to see the full error message
  options: null,
  /* FIXME: strictNullCheck temporary fix */
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'IComboBoxOp... Remove this comment to see the full error message
  optionFactories: null,
  allowCustom: false,
  isDropdownOpen: false,
  openDropdown: () => {
  },
  closeDropdown: () => {
  }
});
ComboBoxContext.displayName = "ComboBoxOptionsContext";
const ComboBoxProvider = ({
  value,
  schema,
  valueSpec,
  variablesSchema,
  options,
  optionFactories,
  refsToIgnoreClick,
  // Dropdown
  onDropdownOpen,
  onDropdownClose,
  autoOpen,
  topDropdownPanelComponent,
  children
}) => {
  const scopedComboboxId = useId();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  useEffect(() => {
    if (autoOpen) {
      openDropdown();
    }
  }, [autoOpen]);
  function openDropdown() {
    if (isDropdownOpen) return;
    setTimeout(() => {
      setIsDropdownOpen(true);
      onDropdownOpen?.();
    }, 10);
  }
  function closeDropdown() {
    if (!isDropdownOpen) return;
    setIsDropdownOpen(false);
    onDropdownClose?.();
  }
  return /* @__PURE__ */ jsx(
    ComboBoxContext.Provider,
    {
      value: {
        scopedComboboxId,
        value,
        schema,
        valueSpec,
        variablesSchema,
        options,
        optionFactories,
        allowCustom: valueSpec?.allowCustom ?? false,
        refsToIgnoreClick,
        isDropdownOpen,
        openDropdown,
        closeDropdown,
        topDropdownPanelComponent
      },
      children
    }
  );
};
function useComboBox() {
  return useContext(ComboBoxContext);
}

export { ComboBoxProvider, useComboBox };
