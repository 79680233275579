import ExampleCodeBlock, {
  CodeParamType,
} from 'components/Docs/ExampleCodeBlock'

import { httpCodeExamples } from '../http-code-examples'

const js = `await integrationApp
  .flowRun('{FLOW_RUN_ID}')
  .getOutput()
`

const rest = httpCodeExamples('/flow-runs/{FLOW_RUN_ID}/output', {
  method: 'GET',
})

export function GetFlowRunOutputCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{
        js,
        ...rest,
      }}
      parameters={{
        FLOW_RUN_ID: CodeParamType.String,
      }}
      run={async ({ integrationApp, parameters }) => {
        return await integrationApp.flowRun(parameters.FLOW_RUN_ID).getOutput()
      }}
    />
  )
}
