import { useEffect, useState } from 'react'
import { Portal } from '@ark-ui/react'

import useApi from 'hooks/useApi'
import { css } from 'styled-system/css'

const borderClassnames = css({
  pointerEvents: 'none',
  position: 'fixed',
  inset: 0,
  borderWidth: 4,
  borderColor: 'warning.10',
  zIndex: 'tooltip',
})

export function ImpersonationVisualNotification() {
  const [isClient, setIsClient] = useState(false)
  const { getImpersonationToken } = useApi()

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) {
    return null
  }

  if (!getImpersonationToken()) {
    return null
  }

  return (
    <Portal>
      <div className={borderClassnames}></div>
    </Portal>
  )
}
