import { DataInput, useFieldMappingInstance } from '@integration-app/react'

import ExampleUIBlock from 'components/Docs/ExampleUIBlock'

import { CodeParamType } from '../../Docs/ExampleCodeBlock'

const jsx = `import { useFieldMappingInstance, DataInput } from "@integration-app/react";

export function FieldMappingExample() {
  const { fieldMappingInstance, patch, loading, error } =
    useFieldMappingInstance({
      integrationKey: '{INTEGRATION_KEY}',
      fieldMappingKey: '{FIELD_MAPPING_KEY}',
      autoCreate: true,
    });

  if (loading) {
    return <p>Loading</p>;
  }

  if (error) {
    return <p>{String(error)}</p>;
  }

  if (!fieldMappingInstance) {
    return <p>Field mapping is missing</p>;
  }

  if (!fieldMappingInstance?.appSchema) {
    return <p>This field mapping does not have a schema to map to.</p>;
  }

  return (
    <DataInput
      schema={fieldMappingInstance.appSchema}
      value={fieldMappingInstance.importValue}
      variablesSchema={fieldMappingInstance.externalSchema}
      onChange={(importValue: unknown) => patch({ importValue })}
    />
  );
}
`

function Component({ parameters }) {
  const { fieldMappingInstance, patch, loading, error } =
    useFieldMappingInstance({
      integrationKey: parameters.INTEGRATION_KEY,
      fieldMappingKey: parameters.FIELD_MAPPING_KEY,
      autoCreate: true,
    })

  if (loading) {
    return <p>Loading</p>
  }

  if (error) {
    return <p>{String(error)}</p>
  }

  if (!fieldMappingInstance) {
    return <p>Field mapping is missing</p>
  }

  if (!fieldMappingInstance?.appSchema) {
    return <p>This field mapping does not have a schema to map to.</p>
  }

  return (
    <DataInput
      schema={fieldMappingInstance.appSchema}
      value={fieldMappingInstance.importValue}
      variablesSchema={fieldMappingInstance.externalSchema}
      onChange={(importValue: unknown) => patch({ importValue })}
    />
  )
}

export function FieldMappingInstanceExample() {
  return (
    <ExampleUIBlock
      customCodes={{ jsx }}
      Component={Component}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
        FIELD_MAPPING_KEY: CodeParamType.FieldMappingKey,
      }}
    />
  )
}
