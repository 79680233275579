import ExampleCodeBlock from '../../Docs/ExampleCodeBlock'

export function ReactSdkFetchTokenCodeExample() {
  const jsx = `async fetchToken() {
  const token = await myTokenFetcher(.....);
  return token;
}

export function MyApp() {
  return (
    <IntegrationAppProvider fetchToken={fetchToken}>
      <MyComponent />
    </IntegrationAppProvider>
  )
}`

  return <ExampleCodeBlock customCodes={{ jsx }} />
}
