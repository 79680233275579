import { PropsWithChildren } from 'react'

import { OnboardingContextProvider } from './OnboardingContext'
import { OnboardingVideo } from './OnboardingVideo'

export const OnboardingProviderWrapper = ({ children }: PropsWithChildren) => {
  return (
    <OnboardingContextProvider>
      {children}
      <OnboardingVideo />
    </OnboardingContextProvider>
  )
}
