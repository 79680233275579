"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { ark } from '@ark-ui/react/factory';
import { styled } from '../../styled-system/jsx/factory.mjs';
import '../../styled-system/jsx/is-valid-prop.mjs';
import 'react';
import '../../styled-system/helpers.mjs';
import '../../styled-system/css/css.mjs';
import '../../styled-system/patterns/visually-hidden.mjs';
import '../../styled-system/css/conditions.mjs';
import { icon } from '../../styled-system/recipes/icon.mjs';

const Icon = styled(ark.svg, icon, {
  defaultProps: { asChild: true }
});

export { Icon };
