import {
  Children,
  cloneElement,
  JSXElementConstructor,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  PopperProps,
  DefaultPopper,
  PopperRefElementType,
} from '@integration-app/react'
import useEventStopPropagation from '@integration-app/ui/hooks/useEventStopPropagation'
import { TbChecks } from 'react-icons/tb'
import { useCopyToClipboard as useCopyToClipboardHook } from 'usehooks-ts'

import { Icon } from 'ui-kit/icon'

import classes from './CopyToClipboard.module.css'

const DEFAULT_TIMEOUT = 2000

interface IPopper {
  offset?: [number, number]
  position?: PopperProps['placement']
  refElement?: MutableRefObject<any>
}

interface ICopyToClipboard {
  children: ReactElement<any, string | JSXElementConstructor<any>>
  value: string
  confirmationText?: ReactNode
  timeout?: number
  popper?: boolean | IPopper
  onClick?: (arg) => void
}

export function useCopyToClipboard(timeout: number = DEFAULT_TIMEOUT) {
  const [copied, setCopied] = useState(false)
  const [, copy] = useCopyToClipboardHook()

  useEffect(() => {
    let timeoutId: NodeJS.Timeout
    if (copied) {
      timeoutId = setTimeout(() => setCopied(false), timeout)
    }
    return () => timeoutId && clearTimeout(timeoutId)
  }, [copied])

  async function copyToClipboard(value: string) {
    await copy(value)
    setCopied(true)
  }

  return {
    copyToClipboard,
    copied,
  }
}

export default function CopyToClipboard({
  children,
  value,
  confirmationText = <DefaultConfirmationText />,
  timeout = DEFAULT_TIMEOUT,
  popper = false,
  onClick,
  ...props
}: ICopyToClipboard) {
  const refElement = useRef(null)
  const { copied, copyToClipboard } = useCopyToClipboard(timeout)

  const handleOnClick = useEventStopPropagation(async (event) => {
    // useEventStopPropagation doesn't prevent default event behavior when click is on <a> tag
    // so we need to do it manually
    event?.preventDefault()

    await copyToClipboard(value)
    onClick?.(event)

    return false
  })

  const passedProps = {
    onClick: handleOnClick,
    ...props,
  } as Record<any, any>

  if (popper) {
    passedProps.ref = (popper as IPopper)?.refElement || refElement
  }

  if (!popper && copied) {
    passedProps.children = confirmationText
  }

  return (
    <>
      {cloneElement(Children.only(children), passedProps)}
      {popper && (
        // FIXME: strictNullCheck temporary fix
        // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'Elemen... Remove this comment to see the full error message
        <CopyToClipboardPopper isOpen={copied} refElement={refElement.current}>
          {confirmationText}
        </CopyToClipboardPopper>
      )}
    </>
  )
}

export function CopyToClipboardPopper({
  refElement,
  isOpen = false,
  children = <DefaultConfirmationText />,
  offset = [0, -20],
}: {
  refElement: PopperRefElementType
  isOpen: boolean
  children?: ReactNode
  offset?: [number, number]
}) {
  return (
    <DefaultPopper
      className={classes.notification}
      refElement={refElement}
      isOpen={isOpen}
      placement={'right'}
      offset={offset}
    >
      {children}
    </DefaultPopper>
  )
}

function DefaultConfirmationText() {
  return (
    <>
      Copied{' '}
      <Icon>
        <TbChecks />
      </Icon>
    </>
  )
}
