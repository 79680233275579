import { useIntegrationApp } from '@integration-app/react'

import ExampleUIBlock from '../../Docs/ExampleUIBlock'

const jsx = `import {
  IntegrationAppProvider,
  useIntegrationApp,
} from '@integration-app/react'

export default function MyApp() {
  return (
    <IntegrationAppProvider token='{TOKEN}'>
      <MyComponent />
    </IntegrationAppProvider>
  )
}

function MyComponent() {
  const integrationApp = useIntegrationApp()

  return (
    <div>
      <button onClick={() => integrationApp.open()}>Integrate</button>
    </div>
  )
}
`

function Component() {
  const integrationApp = useIntegrationApp()

  return (
    <div>
      <button onClick={() => integrationApp.open()}>Integrate</button>
    </div>
  )
}

export function OpenUiWithReactSdkCodeExample() {
  return <ExampleUIBlock customCodes={{ jsx }} Component={Component} />
}
