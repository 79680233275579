import { SvgIcon, SvgIconType } from '@integration-app/react'
import { WorkspaceElementReference } from '@integration-app/sdk'
import { toHeaderCase } from 'js-convert-case'
import { TbArrowRight } from 'react-icons/tb'

import { Tooltip } from 'components/Tooltip'
import { Flex } from 'styled-system/jsx'

import { Icon } from '../../ui-kit/icon'
import { ControlGroup } from '../ControlGroup'
import { useWorkspaceElement } from './hooks/useWorkspaceElement'
import { WorkspaceElementStateDot } from './WorkspaceElementStateDot'

import {
  WORKSPACE_ELEMENT_TYPE_TO_ICON_TYPE,
  WORKSPACE_ELEMENTS_TYPE_TO_ROUTE,
} from '.'

export default function WorkspaceElementLink({
  wsElementRef,
}: {
  wsElementRef: WorkspaceElementReference
}) {
  const { element, isLoading } = useWorkspaceElement(wsElementRef)

  const route = WORKSPACE_ELEMENTS_TYPE_TO_ROUTE[wsElementRef.type]
  const iconType =
    WORKSPACE_ELEMENT_TYPE_TO_ICON_TYPE[wsElementRef.type] ?? SvgIconType.Link

  if (!wsElementRef) {
    return null
  }
  return (
    <ControlGroup.Root size={'dense'}>
      <ControlGroup.Text>
        <Flex alignItems={'center'} gap={2}>
          <Flex alignItems={'center'} gap={1}>
            <Icon size={'sm'}>
              <SvgIcon type={iconType} />
            </Icon>
            {toHeaderCase(wsElementRef.type)}:
          </Flex>

          {element?.name ?? wsElementRef.id}

          {isLoading ? (
            <Icon size='sm'>
              <SvgIcon type={SvgIconType.Loader} />
            </Icon>
          ) : (
            element?.state && (
              <Tooltip tooltip={toHeaderCase(element.state)}>
                <WorkspaceElementStateDot state={element.state} size='sm' />
              </Tooltip>
            )
          )}
        </Flex>
      </ControlGroup.Text>

      <ControlGroup.IconLink
        tooltip={'See Details'}
        to={`${route}/${wsElementRef.id}`}
      >
        <TbArrowRight />
      </ControlGroup.IconLink>
    </ControlGroup.Root>
  )
}
