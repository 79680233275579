"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { WorkspaceElementType } from '@integration-app/sdk';
import { useElement } from '../useElement.mjs';

function useFieldMappingInstance(selector) {
  const {
    item: fieldMappingInstance,
    accessor,
    refresh,
    ...rest
  } = useElement(
    WorkspaceElementType.FieldMappingInstance,
    selector,
    (integrationApp) => selector ? integrationApp.fieldMappingInstance(selector) : void 0
  );
  async function setup() {
    await accessor?.setup();
    await refresh();
  }
  async function reset() {
    await accessor?.reset();
    await refresh();
  }
  async function openConfiguration(options) {
    return accessor?.openConfiguration(options);
  }
  return {
    fieldMappingInstance,
    accessor,
    refresh,
    setup,
    reset,
    openConfiguration,
    ...rest
  };
}

export { useFieldMappingInstance };
