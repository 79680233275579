import { useRef, useState } from 'react'
import { TbPlayerPlayFilled, TbX } from 'react-icons/tb'

import { IconButton } from 'components/Button/IconButton'
import { useOnboarding } from 'components/Onboarding/OnboardingContext'
import { sva } from 'styled-system/css'
import { Box } from 'styled-system/jsx'
import { Button } from 'ui-kit/button'
import { Icon } from 'ui-kit/icon'
import { Popup } from 'ui-kit/popup'
import { Text } from 'ui-kit/text'

import Poster from './poster.png'

const VIDEO_URL =
  'https://integration-app-assets.s3.eu-central-1.amazonaws.com/product-videos/console-intro.mp4'

const videoPlayerRecipe = sva({
  className: 'videoPlayer',
  slots: ['wrapper', 'video', 'button', 'playBadge'],
  base: {
    wrapper: {
      maxWidth: '85dvw',
      aspectRatio: '10 / 8',
      height: 'full',
      position: 'relative',
    },
    video: {
      position: 'absolute',
      inset: 0,
      borderWidth: 1,
      borderRadius: 'l3',
      colorPalette: 'gray',
      _focusVisible: {
        outline: '2px solid',
        outlineColor: 'colorPalette.default',
        outlineOffset: '2px',
      },
    },
    button: {
      position: 'absolute',
      inset: 0,
      border: 0,
      borderRadius: 'l3',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      colorPalette: 'gray',
      _hover: {
        '& .videoPlayer__playBadge': {
          backgroundColor: 'primary.10',
        },
      },
      _focusVisible: {
        outline: '2px solid',
        outlineColor: 'colorPalette.default',
        outlineOffset: '2px',
      },
    },
    playBadge: {
      backgroundColor: 'gray.9',
      transitionProperty: 'background-color',
      transitionDuration: 'normal',
      transitionTimingFunction: 'ease-in-out',
      color: 'white',
      width: 24,
      height: 16,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '2xl',
    },
  },
})
const videoPlayerClassnames = videoPlayerRecipe()

function VideoPlayer() {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)

  function handlePlay() {
    void videoRef.current?.play().then(() => {
      setIsPlaying(true)
    })
  }

  return (
    <Box className={videoPlayerClassnames.wrapper}>
      <video
        poster={Poster.src}
        className={videoPlayerClassnames.video}
        ref={videoRef}
        controls={isPlaying}
        onPlay={() => setIsPlaying(true)}
        src={VIDEO_URL}
      ></video>
      {!isPlaying && (
        <button className={videoPlayerClassnames.button} onClick={handlePlay}>
          <Text className={videoPlayerClassnames.playBadge}>
            <Icon size='xl'>
              <TbPlayerPlayFilled />
            </Icon>
          </Text>
        </button>
      )}
    </Box>
  )
}

export function OnboardingVideo() {
  const [skipOnboardingLoading, setSkipOnboardingLoading] = useState(false)
  const [watchLaterLoading, setWatchLaterLoading] = useState(false)
  const { status, skipOnboarding, watchLater } = useOnboarding()
  const showVideoPopup = status === 'show'

  async function handleSkipOnboarding() {
    setSkipOnboardingLoading(true)
    await skipOnboarding()
    setSkipOnboardingLoading(false)
  }

  async function handleWatchLater() {
    setWatchLaterLoading(true)
    await watchLater()
    setWatchLaterLoading(false)
  }

  return (
    <Popup.Root lazyMount unmountOnExit open={showVideoPopup}>
      <Popup.Backdrop backgroundColor={'black.a6'} />
      <Popup.Positioner paddingBlock='5dvh'>
        <Popup.Window
          height='90dvh'
          maxHeight='90dvh'
          display='grid'
          gridTemplateAreas='"header" "content" "footer"'
          gridTemplateRows='min-content 1fr min-content'
          boxShadow='0 0 0 1px {colors.gray.3}, 0 0 0 4px {colors.gray.6}, {shadows.lg}'
          _open={{ animationName: 'onboarding-video-in' }}
          _closed={{ animationName: 'onboarding-video-out' }}
        >
          <IconButton
            tooltip='Watch later'
            variant='ghost'
            size='xs'
            position='absolute'
            top='2'
            right='3'
            onClick={handleWatchLater}
            disabled={watchLaterLoading}
          >
            <TbX />
          </IconButton>
          <Popup.Header
            gridArea='header'
            paddingInline='5'
            paddingBlock='3'
            borderBlockEndWidth='1'
            borderBlockEndColor='gray.3'
          >
            <Popup.Title>Welcome to Integration App</Popup.Title>
          </Popup.Header>
          <Popup.Content gridArea='content' padding='5' height='full'>
            <VideoPlayer />
          </Popup.Content>
          <Popup.Footer
            gridArea='footer'
            display='flex'
            justifyContent='flex-end'
            alignItems='center'
            gap={'4'}
            borderBlockStartWidth='1'
            borderBlockStartColor='gray.3'
            paddingInline='5'
            paddingBlock='3'
            backgroundColor='bg.subtle'
          >
            <Button
              variant='ghost'
              size='dense'
              colorPalette='primary'
              onClick={handleSkipOnboarding}
              loading={skipOnboardingLoading}
            >
              Skip forever
            </Button>
            <Button
              variant='outline'
              size='dense'
              onClick={handleWatchLater}
              loading={watchLaterLoading}
            >
              Watch later
            </Button>
          </Popup.Footer>
        </Popup.Window>
      </Popup.Positioner>
    </Popup.Root>
  )
}
