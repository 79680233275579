import { DocBreadcrumbType } from 'routes/Docs/components/DocsBreadcrumbs'
import {
  createDocMenuBasedOnArticleListMap,
  getArticleList,
  getNodeParents,
} from 'routes/Docs/helpers/helpers'
import { ArticleListMapType, DocMenuType } from 'routes/Docs/types'

// FIXME: strictNullCheck temporary fix
// @ts-expect-error TS(2339): Property 'context' does not exist on type 'NodeReq... Remove this comment to see the full error message
const articlesContext = require.context(
  'routes/Docs/articles/',
  true,
  // Only .mdx files and files with relates path are allowed
  /\..*\.mdx$/,
)

const articleListMap: ArticleListMapType = getArticleList(articlesContext)

const articleDocMenu: DocMenuType[] =
  createDocMenuBasedOnArticleListMap(articleListMap)

export function getArticleDocMenu() {
  return articleDocMenu
}

export function getArticle(path: string) {
  const safePath = path.split('#')[0] // remove hash
  return articleListMap.get(safePath)
}

export function getArticleBreadcrumbs(path: string): DocBreadcrumbType[] {
  const article = getArticle(path)

  if (!article) {
    return []
  }

  return [...getNodeParents(article).reverse(), article].map((article) => ({
    path: getArticle(article.path)?.Component ? article?.path : undefined,
    title: article?.menuTitle || article?.title,
  }))
}
