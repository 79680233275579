"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { UNIFIED_DATA_MODELS, schemaHasFixedValues, getReferenceCollectionPathForSchema, getFormula, DataBuilderFormulaType } from '@integration-app/sdk';
import { getLookupCollection } from '../Dropdown/Categories/getLookupCollection.mjs';
import { DropdownCategoryType } from '../types.mjs';

function isSchemaAllowCustomValue(schema) {
  const schemaHasNoType = !schema?.type;
  const schemaIsObjectWithNoProperties = schema?.type === "object" && Object.keys(schema.properties ?? {}).length === 0;
  const schemaIsArrayWithNoSchemaItems = schema?.type === "array" && !schema?.items?.type;
  return schemaHasNoType || schemaIsObjectWithNoProperties || schemaIsArrayWithNoSchemaItems;
}
async function getAvailableRootCategories({
  schema,
  options,
  optionFactories,
  variablesOnly,
  hasVariables,
  client,
  connectionId,
  integrationId
}) {
  const availableCategories = /* @__PURE__ */ new Set();
  const udm = schema?.referenceUdm;
  const udmSpec = udm ? UNIFIED_DATA_MODELS[udm] : void 0;
  if (!!udmSpec) {
    availableCategories.add(DropdownCategoryType.SELECT_FROM_UDM_DYNAMICALLY);
  }
  if (schema && (connectionId || integrationId)) {
    const collection = await getLookupCollection({
      schema,
      client,
      connectionId,
      integrationId
    });
    if (collection?.find?.queryFields || collection?.match?.fields) {
      availableCategories.add(DropdownCategoryType.SELECT_FROM_UDM_DYNAMICALLY);
    }
  }
  const comboboxHasOptions = Array.isArray(options);
  const comboboxHasOptionFactories = (optionFactories || []).length > 0;
  const comboboxHasFixedValues = !!schema && schemaHasFixedValues(schema);
  const comboboxHasOptionList = comboboxHasOptions || comboboxHasOptionFactories || comboboxHasFixedValues;
  if (comboboxHasOptionList) {
    availableCategories.add(DropdownCategoryType.OPTIONS);
  }
  const comboboxHasReferenceCollection = !!getReferenceCollectionPathForSchema(schema);
  if (comboboxHasReferenceCollection && connectionId) {
    availableCategories.add(DropdownCategoryType.LOOKUP_OPTIONS);
  }
  const schemaAllowCustomValue = isSchemaAllowCustomValue(schema);
  if (!variablesOnly && !comboboxHasOptionList && schemaAllowCustomValue) {
    availableCategories.add(DropdownCategoryType.CUSTOM);
  }
  if (hasVariables) {
    availableCategories.add(DropdownCategoryType.VARIABLE);
    availableCategories.add(DropdownCategoryType.FORMULA);
  }
  return availableCategories.size > 0 ? [...availableCategories] : null;
}
function getCurrentPath(availableCategories, value) {
  if (availableCategories.length < 1) {
    return [DropdownCategoryType.ROOT];
  }
  if (availableCategories.length === 1) {
    return availableCategories;
  }
  const formulaType = getFormula(value)?.type;
  if (formulaType === DataBuilderFormulaType.LOOKUP && availableCategories.includes(
    DropdownCategoryType.SELECT_FROM_UDM_DYNAMICALLY
  )) {
    return [
      DropdownCategoryType.ROOT,
      DropdownCategoryType.SELECT_FROM_UDM_DYNAMICALLY
    ];
  }
  if (formulaType === DataBuilderFormulaType.VAR) {
    return [DropdownCategoryType.ROOT, DropdownCategoryType.VARIABLE];
  }
  const generalFormulaTypes = Object.values(DataBuilderFormulaType).filter(
    (x) => ![
      DataBuilderFormulaType.TPL,
      DataBuilderFormulaType.VAR,
      DataBuilderFormulaType.RECORD
    ].includes(x)
  );
  if (availableCategories.includes(DropdownCategoryType.FORMULA) && generalFormulaTypes.includes(formulaType)) {
    return [
      DropdownCategoryType.ROOT,
      DropdownCategoryType.FORMULA,
      DropdownCategoryType.FORMULA_CONFIG
    ];
  }
  if (availableCategories.includes(DropdownCategoryType.LOOKUP_OPTIONS)) {
    return [DropdownCategoryType.ROOT];
  }
  if (availableCategories.includes(DropdownCategoryType.CUSTOM)) {
    return [DropdownCategoryType.ROOT];
  }
  return [DropdownCategoryType.ROOT];
}
async function getStackForPath(path, value, getOptions, stack, searchValue) {
  const [first, ...rest] = path;
  if (!first) {
    return stack;
  }
  const options = await getOptions(searchValue, stack);
  const targetOption = options.find((option) => {
    const categoryMatched = option.childCategory !== void 0 && option.childCategory === first;
    if (first === DropdownCategoryType.FORMULA_CONFIG) {
      const valueFormulaType = getFormula(value)?.type;
      const optionFormulaType = getFormula(option.value)?.type;
      if (!optionFormulaType && !optionFormulaType) {
        return false;
      }
      return categoryMatched && valueFormulaType === optionFormulaType;
    }
    return categoryMatched;
  });
  const newStack = [...stack];
  if (targetOption) {
    newStack.push(targetOption);
  }
  return await getStackForPath(rest, value, getOptions, newStack, searchValue);
}
function canUserEnterValueFromKeyboard(schema, options, optionFactories, variablesOnly, value) {
  const comboboxHasOptions = (options || []).length > 0;
  const comboboxHasOptionFactories = (optionFactories || []).length > 0;
  if (variablesOnly || comboboxHasOptions || comboboxHasOptionFactories) {
    return false;
  }
  const valueIsLookup = !!value?.$lookup;
  if (valueIsLookup) {
    return false;
  }
  if (schema && schemaHasFixedValues(schema)) {
    return !!schema.allowCustom;
  }
  if (schema?.type && ["array", "object", "boolean"].includes(schema?.type)) {
    return false;
  }
  return true;
}

export { canUserEnterValueFromKeyboard, getAvailableRootCategories, getCurrentPath, getStackForPath };
