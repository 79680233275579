"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useComboBoxDropdownContext } from '../../context/combobox-dropdown-context.mjs';
import { DropdownCategoryType } from '../../types.mjs';
import { DropdownUI } from '../../../DropdownUI/index.mjs';
import { OptionsList } from '../OptionsList.mjs';
import { LookupOptionsCategoryContent } from './LookupOptions/index.mjs';

function RootCategoryContent({
  onOptionSelect
}) {
  const { availableCategories } = useComboBoxDropdownContext();
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(DropdownUI.Panel, { children: /* @__PURE__ */ jsx(OptionsList, { onOptionSelect }) }),
    availableCategories?.includes(DropdownCategoryType.LOOKUP_OPTIONS) && /* @__PURE__ */ jsx(LookupOptionsCategoryContent, { onOptionSelect })
  ] });
}

export { RootCategoryContent };
