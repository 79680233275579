"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsx } from 'react/jsx-runtime';

function NoUiFormula() {
  return /* @__PURE__ */ jsx("span", { children: "This value cannot be displayed" });
}

export { NoUiFormula as default };
