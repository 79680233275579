import * as jose from 'jose'

import ExampleCodeBlock, { CodeParamType } from '../../Docs/ExampleCodeBlock'
import { useWorkspace } from '../../Workspaces/workspace-context'

export function CreateAdminJwtCodeExample() {
  const { workspace } = useWorkspace()

  return (
    <ExampleCodeBlock
      customCodes={{ js }}
      variables={{
        WORKSPACE_KEY: workspace?.key ?? '<WORKSPACE_KEY>',
        WORKSPACE_SECRET: workspace?.secret ?? '<WORKSPACE_SECRET>',
      }}
      variablesSpec={{
        WORKSPACE_KEY: {
          type: CodeParamType.String,
          title: 'Workspace Key',
        },
        WORKSPACE_SECRET: {
          type: CodeParamType.String,
          title: 'Workspace Secret',
        },
      }}
      run={async ({ workspace }) => {
        const token = await new jose.SignJWT({
          isAdmin: true,
        })
          .setProtectedHeader({ alg: 'HS256', typ: 'JWT' })
          .setIssuer(workspace.key)
          .setExpirationTime('365d')
          .sign(new TextEncoder().encode(workspace.secret))

        return { token }
      }}
    />
  )
}

const js = `import jwt from 'jsonwebtoken'
// Your workspace key and secret.
// You can find them on the Settings page.
const WORKSPACE_KEY = '{WORKSPACE_KEY}'
const WORKSPACE_SECRET = '{WORKSPACE_SECRET}'

const tokenData = {
  isAdmin: true
}

const options = {
  issuer: WORKSPACE_KEY,
  // To prevent token from being used for too long
  expiresIn: 7200,
  // HS256 signing algorithm is used by default,
  // but we recommend to go with more secure option like HS512.
  algorithm: 'HS512'
}

const token = jwt.sign(tokenData, WORKSPACE_SECRET, options)
`
