import { ComponentType, useState } from 'react'
import { IntegrationAppProvider } from '@integration-app/react'

import {
  CodeParams,
  CodeBlockRunButton,
  LoginToRun,
  CodeLanguageSelector,
} from 'components/CodeBlock/components'
import { CodeBlockElement } from 'components/CodeBlock/elements'
import {
  CodeBlockType,
  CodeExampleType,
  CodeParamType,
} from 'components/Docs/codeBlock-types'

import {
  codeBlocksToCodeExamples,
  ParametersSpec,
  renderVariables,
} from './ExampleCodeBlock'
import useAuth from '../../contexts/auth'
import useDocs from '../../routes/Docs/components/docs-context'
import { useWorkspace } from '../Workspaces/workspace-context'

export interface RenderArgs {
  parameters: Record<string, any>
}

function ExampleUIBlock({
  variables = {},
  customCodes,
  parameters: parametersSpec = {},
  Component,
}: {
  variables?: Record<string, string>
  customCodes: CodeBlockType
  parameters?: ParametersSpec
  Component?: ComponentType<RenderArgs>
}) {
  const { self, loginLink } = useAuth()
  const { testCustomerClient } = useWorkspace()
  const { parameters } = useDocs()
  const [rendered, setRendered] = useState(null)

  const codeExamples = codeBlocksToCodeExamples(customCodes)

  const [currentLanguage, setCurrentLanguage] = useState(
    codeExamples[0].language,
  )
  const currentCodeExample = codeExamples.find(
    (codeExample) => codeExample.language === currentLanguage,
  ) as CodeExampleType

  function onLanguageChange(value: CodeExampleType['language']) {
    setCurrentLanguage(value)
  }

  function replaceParamsAndVariables(code: string) {
    return renderVariables(
      renderVariables(code, parameters),
      variables,
      parametersSpec,
    )
  }

  return (
    <CodeBlockElement.Root className={'mt-4 mb-6'}>
      <CodeParams disabled={!self?.user?.id} parametersSpec={parametersSpec} />
      <CodeBlockElement.Section className={'py-1.5 bg-neutral01 items-center'}>
        <CodeLanguageSelector
          value={currentLanguage}
          onChange={onLanguageChange}
          codeExamples={codeExamples}
        />
      </CodeBlockElement.Section>

      <CodeBlockElement.Code
        code={replaceParamsAndVariables(currentCodeExample.code).trim()}
        language={currentCodeExample.language}
        copyToClipboard
      />

      {Component && (
        <CodeBlockElement.Section>
          {self?.user?.id ? (
            <>
              {rendered ? (
                // FIXME: strictNullCheck temporary fix
                // @ts-expect-error TS(2345): Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
                <CodeBlockRunButton onClick={() => setRendered(false)}>
                  Hide
                </CodeBlockRunButton>
              ) : (
                // FIXME: strictNullCheck temporary fix
                // @ts-expect-error TS(2345): Argument of type 'true' is not assignable to param... Remove this comment to see the full error message
                <CodeBlockRunButton onClick={() => setRendered(true)}>
                  Render
                </CodeBlockRunButton>
              )}
            </>
          ) : (
            <LoginToRun>
              Please <a href={loginLink(window.location.href)}>log in</a> to run
              this example
            </LoginToRun>
          )}
        </CodeBlockElement.Section>
      )}

      {rendered && (
        <CodeBlockElement.Section>
          <IntegrationAppProvider
            token={testCustomerClient.token}
            apiUri={testCustomerClient.apiUri}
            uiUri={testCustomerClient.uiUri}
          >
            {/* FIXME: strictNullCheck temporary fix */}
            {/* @ts-expect-error TS(2604): JSX element type 'Component' does not have any con... Remove this comment to see the full error message*/}
            <Component parameters={parameters} />
          </IntegrationAppProvider>
        </CodeBlockElement.Section>
      )}
    </CodeBlockElement.Root>
  )
}

ExampleUIBlock.ParamType = CodeParamType

export default ExampleUIBlock
