/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

const tokens = {
  "animations.backdrop-in": {
    "value": "fade-in 250ms var(--iap-easings-emphasized-in)",
    "variable": "var(--iap-animations-backdrop-in)"
  },
  "animations.backdrop-out": {
    "value": "fade-out 200ms var(--iap-easings-emphasized-out)",
    "variable": "var(--iap-animations-backdrop-out)"
  },
  "animations.dialog-in": {
    "value": "slide-in 400ms var(--iap-easings-emphasized-in)",
    "variable": "var(--iap-animations-dialog-in)"
  },
  "animations.dialog-out": {
    "value": "slide-out 200ms var(--iap-easings-emphasized-out)",
    "variable": "var(--iap-animations-dialog-out)"
  },
  "animations.drawer-in-left": {
    "value": "slide-in-left 400ms var(--iap-easings-emphasized-in)",
    "variable": "var(--iap-animations-drawer-in-left)"
  },
  "animations.drawer-out-left": {
    "value": "slide-out-left 200ms var(--iap-easings-emphasized-out)",
    "variable": "var(--iap-animations-drawer-out-left)"
  },
  "animations.drawer-in-right": {
    "value": "slide-in-right 400ms var(--iap-easings-emphasized-in)",
    "variable": "var(--iap-animations-drawer-in-right)"
  },
  "animations.drawer-out-right": {
    "value": "slide-out-right 200ms var(--iap-easings-emphasized-out)",
    "variable": "var(--iap-animations-drawer-out-right)"
  },
  "animations.skeleton-pulse": {
    "value": "skeleton-pulse 2s var(--iap-easings-pulse) infinite",
    "variable": "var(--iap-animations-skeleton-pulse)"
  },
  "animations.fade-in": {
    "value": "fade-in 400ms var(--iap-easings-emphasized-in)",
    "variable": "var(--iap-animations-fade-in)"
  },
  "animations.collapse-in": {
    "value": "collapse-in 250ms var(--iap-easings-emphasized-in)",
    "variable": "var(--iap-animations-collapse-in)"
  },
  "animations.collapse-out": {
    "value": "collapse-out 200ms var(--iap-easings-emphasized-out)",
    "variable": "var(--iap-animations-collapse-out)"
  },
  "animations.spin": {
    "value": "spin 1s linear infinite",
    "variable": "var(--iap-animations-spin)"
  },
  "blurs.sm": {
    "value": "4px",
    "variable": "var(--iap-blurs-sm)"
  },
  "blurs.base": {
    "value": "8px",
    "variable": "var(--iap-blurs-base)"
  },
  "blurs.md": {
    "value": "12px",
    "variable": "var(--iap-blurs-md)"
  },
  "blurs.lg": {
    "value": "16px",
    "variable": "var(--iap-blurs-lg)"
  },
  "blurs.xl": {
    "value": "24px",
    "variable": "var(--iap-blurs-xl)"
  },
  "blurs.2xl": {
    "value": "40px",
    "variable": "var(--iap-blurs-2xl)"
  },
  "blurs.3xl": {
    "value": "64px",
    "variable": "var(--iap-blurs-3xl)"
  },
  "borders.none": {
    "value": "none",
    "variable": "var(--iap-borders-none)"
  },
  "colors.current": {
    "value": "currentColor",
    "variable": "var(--iap-colors-current)"
  },
  "colors.black": {
    "value": "#000000",
    "variable": "var(--iap-colors-black)"
  },
  "colors.black.a1": {
    "value": "rgba(0, 0, 0, 0.05)",
    "variable": "var(--iap-colors-black-a1)"
  },
  "colors.black.a2": {
    "value": "rgba(0, 0, 0, 0.1)",
    "variable": "var(--iap-colors-black-a2)"
  },
  "colors.black.a3": {
    "value": "rgba(0, 0, 0, 0.15)",
    "variable": "var(--iap-colors-black-a3)"
  },
  "colors.black.a4": {
    "value": "rgba(0, 0, 0, 0.2)",
    "variable": "var(--iap-colors-black-a4)"
  },
  "colors.black.a5": {
    "value": "rgba(0, 0, 0, 0.3)",
    "variable": "var(--iap-colors-black-a5)"
  },
  "colors.black.a6": {
    "value": "rgba(0, 0, 0, 0.4)",
    "variable": "var(--iap-colors-black-a6)"
  },
  "colors.black.a7": {
    "value": "rgba(0, 0, 0, 0.5)",
    "variable": "var(--iap-colors-black-a7)"
  },
  "colors.black.a8": {
    "value": "rgba(0, 0, 0, 0.6)",
    "variable": "var(--iap-colors-black-a8)"
  },
  "colors.black.a9": {
    "value": "rgba(0, 0, 0, 0.7)",
    "variable": "var(--iap-colors-black-a9)"
  },
  "colors.black.a10": {
    "value": "rgba(0, 0, 0, 0.8)",
    "variable": "var(--iap-colors-black-a10)"
  },
  "colors.black.a11": {
    "value": "rgba(0, 0, 0, 0.9)",
    "variable": "var(--iap-colors-black-a11)"
  },
  "colors.black.a12": {
    "value": "rgba(0, 0, 0, 0.95)",
    "variable": "var(--iap-colors-black-a12)"
  },
  "colors.white": {
    "value": "#ffffff",
    "variable": "var(--iap-colors-white)"
  },
  "colors.white.a1": {
    "value": "rgba(255, 255, 255, 0.05)",
    "variable": "var(--iap-colors-white-a1)"
  },
  "colors.white.a2": {
    "value": "rgba(255, 255, 255, 0.1)",
    "variable": "var(--iap-colors-white-a2)"
  },
  "colors.white.a3": {
    "value": "rgba(255, 255, 255, 0.15)",
    "variable": "var(--iap-colors-white-a3)"
  },
  "colors.white.a4": {
    "value": "rgba(255, 255, 255, 0.2)",
    "variable": "var(--iap-colors-white-a4)"
  },
  "colors.white.a5": {
    "value": "rgba(255, 255, 255, 0.3)",
    "variable": "var(--iap-colors-white-a5)"
  },
  "colors.white.a6": {
    "value": "rgba(255, 255, 255, 0.4)",
    "variable": "var(--iap-colors-white-a6)"
  },
  "colors.white.a7": {
    "value": "rgba(255, 255, 255, 0.5)",
    "variable": "var(--iap-colors-white-a7)"
  },
  "colors.white.a8": {
    "value": "rgba(255, 255, 255, 0.6)",
    "variable": "var(--iap-colors-white-a8)"
  },
  "colors.white.a9": {
    "value": "rgba(255, 255, 255, 0.7)",
    "variable": "var(--iap-colors-white-a9)"
  },
  "colors.white.a10": {
    "value": "rgba(255, 255, 255, 0.8)",
    "variable": "var(--iap-colors-white-a10)"
  },
  "colors.white.a11": {
    "value": "rgba(255, 255, 255, 0.9)",
    "variable": "var(--iap-colors-white-a11)"
  },
  "colors.white.a12": {
    "value": "rgba(255, 255, 255, 0.95)",
    "variable": "var(--iap-colors-white-a12)"
  },
  "colors.transparent": {
    "value": "rgb(0 0 0 / 0)",
    "variable": "var(--iap-colors-transparent)"
  },
  "colors.gray.light.1": {
    "value": "#fcfcfc",
    "variable": "var(--iap-colors-gray-light-1)"
  },
  "colors.gray.light.2": {
    "value": "#f9f9f9",
    "variable": "var(--iap-colors-gray-light-2)"
  },
  "colors.gray.light.3": {
    "value": "#f0f0f0",
    "variable": "var(--iap-colors-gray-light-3)"
  },
  "colors.gray.light.4": {
    "value": "#e8e8e8",
    "variable": "var(--iap-colors-gray-light-4)"
  },
  "colors.gray.light.5": {
    "value": "#e0e0e0",
    "variable": "var(--iap-colors-gray-light-5)"
  },
  "colors.gray.light.6": {
    "value": "#d9d9d9",
    "variable": "var(--iap-colors-gray-light-6)"
  },
  "colors.gray.light.7": {
    "value": "#cecece",
    "variable": "var(--iap-colors-gray-light-7)"
  },
  "colors.gray.light.8": {
    "value": "#bbbbbb",
    "variable": "var(--iap-colors-gray-light-8)"
  },
  "colors.gray.light.9": {
    "value": "#8d8d8d",
    "variable": "var(--iap-colors-gray-light-9)"
  },
  "colors.gray.light.10": {
    "value": "#838383",
    "variable": "var(--iap-colors-gray-light-10)"
  },
  "colors.gray.light.11": {
    "value": "#646464",
    "variable": "var(--iap-colors-gray-light-11)"
  },
  "colors.gray.light.12": {
    "value": "#202020",
    "variable": "var(--iap-colors-gray-light-12)"
  },
  "colors.gray.light.a1": {
    "value": "#00000003",
    "variable": "var(--iap-colors-gray-light-a1)"
  },
  "colors.gray.light.a2": {
    "value": "#00000006",
    "variable": "var(--iap-colors-gray-light-a2)"
  },
  "colors.gray.light.a3": {
    "value": "#0000000f",
    "variable": "var(--iap-colors-gray-light-a3)"
  },
  "colors.gray.light.a4": {
    "value": "#00000017",
    "variable": "var(--iap-colors-gray-light-a4)"
  },
  "colors.gray.light.a5": {
    "value": "#0000001f",
    "variable": "var(--iap-colors-gray-light-a5)"
  },
  "colors.gray.light.a6": {
    "value": "#00000026",
    "variable": "var(--iap-colors-gray-light-a6)"
  },
  "colors.gray.light.a7": {
    "value": "#00000031",
    "variable": "var(--iap-colors-gray-light-a7)"
  },
  "colors.gray.light.a8": {
    "value": "#00000044",
    "variable": "var(--iap-colors-gray-light-a8)"
  },
  "colors.gray.light.a9": {
    "value": "#00000072",
    "variable": "var(--iap-colors-gray-light-a9)"
  },
  "colors.gray.light.a10": {
    "value": "#0000007c",
    "variable": "var(--iap-colors-gray-light-a10)"
  },
  "colors.gray.light.a11": {
    "value": "#0000009b",
    "variable": "var(--iap-colors-gray-light-a11)"
  },
  "colors.gray.light.a12": {
    "value": "#000000df",
    "variable": "var(--iap-colors-gray-light-a12)"
  },
  "colors.gray.dark.1": {
    "value": "#111111",
    "variable": "var(--iap-colors-gray-dark-1)"
  },
  "colors.gray.dark.2": {
    "value": "#191919",
    "variable": "var(--iap-colors-gray-dark-2)"
  },
  "colors.gray.dark.3": {
    "value": "#222222",
    "variable": "var(--iap-colors-gray-dark-3)"
  },
  "colors.gray.dark.4": {
    "value": "#2a2a2a",
    "variable": "var(--iap-colors-gray-dark-4)"
  },
  "colors.gray.dark.5": {
    "value": "#313131",
    "variable": "var(--iap-colors-gray-dark-5)"
  },
  "colors.gray.dark.6": {
    "value": "#3a3a3a",
    "variable": "var(--iap-colors-gray-dark-6)"
  },
  "colors.gray.dark.7": {
    "value": "#484848",
    "variable": "var(--iap-colors-gray-dark-7)"
  },
  "colors.gray.dark.8": {
    "value": "#606060",
    "variable": "var(--iap-colors-gray-dark-8)"
  },
  "colors.gray.dark.9": {
    "value": "#6e6e6e",
    "variable": "var(--iap-colors-gray-dark-9)"
  },
  "colors.gray.dark.10": {
    "value": "#7b7b7b",
    "variable": "var(--iap-colors-gray-dark-10)"
  },
  "colors.gray.dark.11": {
    "value": "#b4b4b4",
    "variable": "var(--iap-colors-gray-dark-11)"
  },
  "colors.gray.dark.12": {
    "value": "#eeeeee",
    "variable": "var(--iap-colors-gray-dark-12)"
  },
  "colors.gray.dark.a1": {
    "value": "#00000000",
    "variable": "var(--iap-colors-gray-dark-a1)"
  },
  "colors.gray.dark.a2": {
    "value": "#ffffff09",
    "variable": "var(--iap-colors-gray-dark-a2)"
  },
  "colors.gray.dark.a3": {
    "value": "#ffffff12",
    "variable": "var(--iap-colors-gray-dark-a3)"
  },
  "colors.gray.dark.a4": {
    "value": "#ffffff1b",
    "variable": "var(--iap-colors-gray-dark-a4)"
  },
  "colors.gray.dark.a5": {
    "value": "#ffffff22",
    "variable": "var(--iap-colors-gray-dark-a5)"
  },
  "colors.gray.dark.a6": {
    "value": "#ffffff2c",
    "variable": "var(--iap-colors-gray-dark-a6)"
  },
  "colors.gray.dark.a7": {
    "value": "#ffffff3b",
    "variable": "var(--iap-colors-gray-dark-a7)"
  },
  "colors.gray.dark.a8": {
    "value": "#ffffff55",
    "variable": "var(--iap-colors-gray-dark-a8)"
  },
  "colors.gray.dark.a9": {
    "value": "#ffffff64",
    "variable": "var(--iap-colors-gray-dark-a9)"
  },
  "colors.gray.dark.a10": {
    "value": "#ffffff72",
    "variable": "var(--iap-colors-gray-dark-a10)"
  },
  "colors.gray.dark.a11": {
    "value": "#ffffffaf",
    "variable": "var(--iap-colors-gray-dark-a11)"
  },
  "colors.gray.dark.a12": {
    "value": "#ffffffed",
    "variable": "var(--iap-colors-gray-dark-a12)"
  },
  "colors.green.light.1": {
    "value": "#fbfefc",
    "variable": "var(--iap-colors-green-light-1)"
  },
  "colors.green.light.2": {
    "value": "#f4fbf6",
    "variable": "var(--iap-colors-green-light-2)"
  },
  "colors.green.light.3": {
    "value": "#e6f6eb",
    "variable": "var(--iap-colors-green-light-3)"
  },
  "colors.green.light.4": {
    "value": "#d6f1df",
    "variable": "var(--iap-colors-green-light-4)"
  },
  "colors.green.light.5": {
    "value": "#c4e8d1",
    "variable": "var(--iap-colors-green-light-5)"
  },
  "colors.green.light.6": {
    "value": "#adddc0",
    "variable": "var(--iap-colors-green-light-6)"
  },
  "colors.green.light.7": {
    "value": "#8eceaa",
    "variable": "var(--iap-colors-green-light-7)"
  },
  "colors.green.light.8": {
    "value": "#5bb98b",
    "variable": "var(--iap-colors-green-light-8)"
  },
  "colors.green.light.9": {
    "value": "#30a46c",
    "variable": "var(--iap-colors-green-light-9)"
  },
  "colors.green.light.10": {
    "value": "#2b9a66",
    "variable": "var(--iap-colors-green-light-10)"
  },
  "colors.green.light.11": {
    "value": "#218358",
    "variable": "var(--iap-colors-green-light-11)"
  },
  "colors.green.light.12": {
    "value": "#193b2d",
    "variable": "var(--iap-colors-green-light-12)"
  },
  "colors.green.light.a1": {
    "value": "#00c04004",
    "variable": "var(--iap-colors-green-light-a1)"
  },
  "colors.green.light.a2": {
    "value": "#00a32f0b",
    "variable": "var(--iap-colors-green-light-a2)"
  },
  "colors.green.light.a3": {
    "value": "#00a43319",
    "variable": "var(--iap-colors-green-light-a3)"
  },
  "colors.green.light.a4": {
    "value": "#00a83829",
    "variable": "var(--iap-colors-green-light-a4)"
  },
  "colors.green.light.a5": {
    "value": "#019c393b",
    "variable": "var(--iap-colors-green-light-a5)"
  },
  "colors.green.light.a6": {
    "value": "#00963c52",
    "variable": "var(--iap-colors-green-light-a6)"
  },
  "colors.green.light.a7": {
    "value": "#00914071",
    "variable": "var(--iap-colors-green-light-a7)"
  },
  "colors.green.light.a8": {
    "value": "#00924ba4",
    "variable": "var(--iap-colors-green-light-a8)"
  },
  "colors.green.light.a9": {
    "value": "#008f4acf",
    "variable": "var(--iap-colors-green-light-a9)"
  },
  "colors.green.light.a10": {
    "value": "#008647d4",
    "variable": "var(--iap-colors-green-light-a10)"
  },
  "colors.green.light.a11": {
    "value": "#00713fde",
    "variable": "var(--iap-colors-green-light-a11)"
  },
  "colors.green.light.a12": {
    "value": "#002616e6",
    "variable": "var(--iap-colors-green-light-a12)"
  },
  "colors.green.dark.1": {
    "value": "#0e1512",
    "variable": "var(--iap-colors-green-dark-1)"
  },
  "colors.green.dark.2": {
    "value": "#121b17",
    "variable": "var(--iap-colors-green-dark-2)"
  },
  "colors.green.dark.3": {
    "value": "#132d21",
    "variable": "var(--iap-colors-green-dark-3)"
  },
  "colors.green.dark.4": {
    "value": "#113b29",
    "variable": "var(--iap-colors-green-dark-4)"
  },
  "colors.green.dark.5": {
    "value": "#174933",
    "variable": "var(--iap-colors-green-dark-5)"
  },
  "colors.green.dark.6": {
    "value": "#20573e",
    "variable": "var(--iap-colors-green-dark-6)"
  },
  "colors.green.dark.7": {
    "value": "#28684a",
    "variable": "var(--iap-colors-green-dark-7)"
  },
  "colors.green.dark.8": {
    "value": "#2f7c57",
    "variable": "var(--iap-colors-green-dark-8)"
  },
  "colors.green.dark.9": {
    "value": "#30a46c",
    "variable": "var(--iap-colors-green-dark-9)"
  },
  "colors.green.dark.10": {
    "value": "#33b074",
    "variable": "var(--iap-colors-green-dark-10)"
  },
  "colors.green.dark.11": {
    "value": "#3dd68c",
    "variable": "var(--iap-colors-green-dark-11)"
  },
  "colors.green.dark.12": {
    "value": "#b1f1cb",
    "variable": "var(--iap-colors-green-dark-12)"
  },
  "colors.green.dark.a1": {
    "value": "#00de4505",
    "variable": "var(--iap-colors-green-dark-a1)"
  },
  "colors.green.dark.a2": {
    "value": "#29f99d0b",
    "variable": "var(--iap-colors-green-dark-a2)"
  },
  "colors.green.dark.a3": {
    "value": "#22ff991e",
    "variable": "var(--iap-colors-green-dark-a3)"
  },
  "colors.green.dark.a4": {
    "value": "#11ff992d",
    "variable": "var(--iap-colors-green-dark-a4)"
  },
  "colors.green.dark.a5": {
    "value": "#2bffa23c",
    "variable": "var(--iap-colors-green-dark-a5)"
  },
  "colors.green.dark.a6": {
    "value": "#44ffaa4b",
    "variable": "var(--iap-colors-green-dark-a6)"
  },
  "colors.green.dark.a7": {
    "value": "#50fdac5e",
    "variable": "var(--iap-colors-green-dark-a7)"
  },
  "colors.green.dark.a8": {
    "value": "#54ffad73",
    "variable": "var(--iap-colors-green-dark-a8)"
  },
  "colors.green.dark.a9": {
    "value": "#44ffa49e",
    "variable": "var(--iap-colors-green-dark-a9)"
  },
  "colors.green.dark.a10": {
    "value": "#43fea4ab",
    "variable": "var(--iap-colors-green-dark-a10)"
  },
  "colors.green.dark.a11": {
    "value": "#46fea5d4",
    "variable": "var(--iap-colors-green-dark-a11)"
  },
  "colors.green.dark.a12": {
    "value": "#bbffd7f0",
    "variable": "var(--iap-colors-green-dark-a12)"
  },
  "colors.red.light.1": {
    "value": "#fffcfc",
    "variable": "var(--iap-colors-red-light-1)"
  },
  "colors.red.light.2": {
    "value": "#fff7f7",
    "variable": "var(--iap-colors-red-light-2)"
  },
  "colors.red.light.3": {
    "value": "#feebec",
    "variable": "var(--iap-colors-red-light-3)"
  },
  "colors.red.light.4": {
    "value": "#ffdbdc",
    "variable": "var(--iap-colors-red-light-4)"
  },
  "colors.red.light.5": {
    "value": "#ffcdce",
    "variable": "var(--iap-colors-red-light-5)"
  },
  "colors.red.light.6": {
    "value": "#fdbdbe",
    "variable": "var(--iap-colors-red-light-6)"
  },
  "colors.red.light.7": {
    "value": "#f4a9aa",
    "variable": "var(--iap-colors-red-light-7)"
  },
  "colors.red.light.8": {
    "value": "#eb8e90",
    "variable": "var(--iap-colors-red-light-8)"
  },
  "colors.red.light.9": {
    "value": "#e5484d",
    "variable": "var(--iap-colors-red-light-9)"
  },
  "colors.red.light.10": {
    "value": "#dc3e42",
    "variable": "var(--iap-colors-red-light-10)"
  },
  "colors.red.light.11": {
    "value": "#ce2c31",
    "variable": "var(--iap-colors-red-light-11)"
  },
  "colors.red.light.12": {
    "value": "#641723",
    "variable": "var(--iap-colors-red-light-12)"
  },
  "colors.red.light.a1": {
    "value": "#ff000003",
    "variable": "var(--iap-colors-red-light-a1)"
  },
  "colors.red.light.a2": {
    "value": "#ff000008",
    "variable": "var(--iap-colors-red-light-a2)"
  },
  "colors.red.light.a3": {
    "value": "#f3000d14",
    "variable": "var(--iap-colors-red-light-a3)"
  },
  "colors.red.light.a4": {
    "value": "#ff000824",
    "variable": "var(--iap-colors-red-light-a4)"
  },
  "colors.red.light.a5": {
    "value": "#ff000632",
    "variable": "var(--iap-colors-red-light-a5)"
  },
  "colors.red.light.a6": {
    "value": "#f8000442",
    "variable": "var(--iap-colors-red-light-a6)"
  },
  "colors.red.light.a7": {
    "value": "#df000356",
    "variable": "var(--iap-colors-red-light-a7)"
  },
  "colors.red.light.a8": {
    "value": "#d2000571",
    "variable": "var(--iap-colors-red-light-a8)"
  },
  "colors.red.light.a9": {
    "value": "#db0007b7",
    "variable": "var(--iap-colors-red-light-a9)"
  },
  "colors.red.light.a10": {
    "value": "#d10005c1",
    "variable": "var(--iap-colors-red-light-a10)"
  },
  "colors.red.light.a11": {
    "value": "#c40006d3",
    "variable": "var(--iap-colors-red-light-a11)"
  },
  "colors.red.light.a12": {
    "value": "#55000de8",
    "variable": "var(--iap-colors-red-light-a12)"
  },
  "colors.red.dark.1": {
    "value": "#191111",
    "variable": "var(--iap-colors-red-dark-1)"
  },
  "colors.red.dark.2": {
    "value": "#201314",
    "variable": "var(--iap-colors-red-dark-2)"
  },
  "colors.red.dark.3": {
    "value": "#3b1219",
    "variable": "var(--iap-colors-red-dark-3)"
  },
  "colors.red.dark.4": {
    "value": "#500f1c",
    "variable": "var(--iap-colors-red-dark-4)"
  },
  "colors.red.dark.5": {
    "value": "#611623",
    "variable": "var(--iap-colors-red-dark-5)"
  },
  "colors.red.dark.6": {
    "value": "#72232d",
    "variable": "var(--iap-colors-red-dark-6)"
  },
  "colors.red.dark.7": {
    "value": "#8c333a",
    "variable": "var(--iap-colors-red-dark-7)"
  },
  "colors.red.dark.8": {
    "value": "#b54548",
    "variable": "var(--iap-colors-red-dark-8)"
  },
  "colors.red.dark.9": {
    "value": "#e5484d",
    "variable": "var(--iap-colors-red-dark-9)"
  },
  "colors.red.dark.10": {
    "value": "#ec5d5e",
    "variable": "var(--iap-colors-red-dark-10)"
  },
  "colors.red.dark.11": {
    "value": "#ff9592",
    "variable": "var(--iap-colors-red-dark-11)"
  },
  "colors.red.dark.12": {
    "value": "#ffd1d9",
    "variable": "var(--iap-colors-red-dark-12)"
  },
  "colors.red.dark.a1": {
    "value": "#f4121209",
    "variable": "var(--iap-colors-red-dark-a1)"
  },
  "colors.red.dark.a2": {
    "value": "#f22f3e11",
    "variable": "var(--iap-colors-red-dark-a2)"
  },
  "colors.red.dark.a3": {
    "value": "#ff173f2d",
    "variable": "var(--iap-colors-red-dark-a3)"
  },
  "colors.red.dark.a4": {
    "value": "#fe0a3b44",
    "variable": "var(--iap-colors-red-dark-a4)"
  },
  "colors.red.dark.a5": {
    "value": "#ff204756",
    "variable": "var(--iap-colors-red-dark-a5)"
  },
  "colors.red.dark.a6": {
    "value": "#ff3e5668",
    "variable": "var(--iap-colors-red-dark-a6)"
  },
  "colors.red.dark.a7": {
    "value": "#ff536184",
    "variable": "var(--iap-colors-red-dark-a7)"
  },
  "colors.red.dark.a8": {
    "value": "#ff5d61b0",
    "variable": "var(--iap-colors-red-dark-a8)"
  },
  "colors.red.dark.a9": {
    "value": "#fe4e54e4",
    "variable": "var(--iap-colors-red-dark-a9)"
  },
  "colors.red.dark.a10": {
    "value": "#ff6465eb",
    "variable": "var(--iap-colors-red-dark-a10)"
  },
  "colors.red.dark.a11": {
    "value": "#ff9592",
    "variable": "var(--iap-colors-red-dark-a11)"
  },
  "colors.red.dark.a12": {
    "value": "#ffd1d9",
    "variable": "var(--iap-colors-red-dark-a12)"
  },
  "colors.sand.light.1": {
    "value": "#fdfdfc",
    "variable": "var(--iap-colors-sand-light-1)"
  },
  "colors.sand.light.2": {
    "value": "#f9f9f8",
    "variable": "var(--iap-colors-sand-light-2)"
  },
  "colors.sand.light.3": {
    "value": "#f1f0ef",
    "variable": "var(--iap-colors-sand-light-3)"
  },
  "colors.sand.light.4": {
    "value": "#e9e8e6",
    "variable": "var(--iap-colors-sand-light-4)"
  },
  "colors.sand.light.5": {
    "value": "#e2e1de",
    "variable": "var(--iap-colors-sand-light-5)"
  },
  "colors.sand.light.6": {
    "value": "#dad9d6",
    "variable": "var(--iap-colors-sand-light-6)"
  },
  "colors.sand.light.7": {
    "value": "#cfceca",
    "variable": "var(--iap-colors-sand-light-7)"
  },
  "colors.sand.light.8": {
    "value": "#bcbbb5",
    "variable": "var(--iap-colors-sand-light-8)"
  },
  "colors.sand.light.9": {
    "value": "#8d8d86",
    "variable": "var(--iap-colors-sand-light-9)"
  },
  "colors.sand.light.10": {
    "value": "#82827c",
    "variable": "var(--iap-colors-sand-light-10)"
  },
  "colors.sand.light.11": {
    "value": "#63635e",
    "variable": "var(--iap-colors-sand-light-11)"
  },
  "colors.sand.light.12": {
    "value": "#21201c",
    "variable": "var(--iap-colors-sand-light-12)"
  },
  "colors.sand.light.a1": {
    "value": "#55550003",
    "variable": "var(--iap-colors-sand-light-a1)"
  },
  "colors.sand.light.a2": {
    "value": "#25250007",
    "variable": "var(--iap-colors-sand-light-a2)"
  },
  "colors.sand.light.a3": {
    "value": "#20100010",
    "variable": "var(--iap-colors-sand-light-a3)"
  },
  "colors.sand.light.a4": {
    "value": "#1f150019",
    "variable": "var(--iap-colors-sand-light-a4)"
  },
  "colors.sand.light.a5": {
    "value": "#1f180021",
    "variable": "var(--iap-colors-sand-light-a5)"
  },
  "colors.sand.light.a6": {
    "value": "#19130029",
    "variable": "var(--iap-colors-sand-light-a6)"
  },
  "colors.sand.light.a7": {
    "value": "#19140035",
    "variable": "var(--iap-colors-sand-light-a7)"
  },
  "colors.sand.light.a8": {
    "value": "#1915014a",
    "variable": "var(--iap-colors-sand-light-a8)"
  },
  "colors.sand.light.a9": {
    "value": "#0f0f0079",
    "variable": "var(--iap-colors-sand-light-a9)"
  },
  "colors.sand.light.a10": {
    "value": "#0c0c0083",
    "variable": "var(--iap-colors-sand-light-a10)"
  },
  "colors.sand.light.a11": {
    "value": "#080800a1",
    "variable": "var(--iap-colors-sand-light-a11)"
  },
  "colors.sand.light.a12": {
    "value": "#060500e3",
    "variable": "var(--iap-colors-sand-light-a12)"
  },
  "colors.sand.dark.1": {
    "value": "#111110",
    "variable": "var(--iap-colors-sand-dark-1)"
  },
  "colors.sand.dark.2": {
    "value": "#191918",
    "variable": "var(--iap-colors-sand-dark-2)"
  },
  "colors.sand.dark.3": {
    "value": "#222221",
    "variable": "var(--iap-colors-sand-dark-3)"
  },
  "colors.sand.dark.4": {
    "value": "#2a2a28",
    "variable": "var(--iap-colors-sand-dark-4)"
  },
  "colors.sand.dark.5": {
    "value": "#31312e",
    "variable": "var(--iap-colors-sand-dark-5)"
  },
  "colors.sand.dark.6": {
    "value": "#3b3a37",
    "variable": "var(--iap-colors-sand-dark-6)"
  },
  "colors.sand.dark.7": {
    "value": "#494844",
    "variable": "var(--iap-colors-sand-dark-7)"
  },
  "colors.sand.dark.8": {
    "value": "#62605b",
    "variable": "var(--iap-colors-sand-dark-8)"
  },
  "colors.sand.dark.9": {
    "value": "#6f6d66",
    "variable": "var(--iap-colors-sand-dark-9)"
  },
  "colors.sand.dark.10": {
    "value": "#7c7b74",
    "variable": "var(--iap-colors-sand-dark-10)"
  },
  "colors.sand.dark.11": {
    "value": "#b5b3ad",
    "variable": "var(--iap-colors-sand-dark-11)"
  },
  "colors.sand.dark.12": {
    "value": "#eeeeec",
    "variable": "var(--iap-colors-sand-dark-12)"
  },
  "colors.sand.dark.a1": {
    "value": "#00000000",
    "variable": "var(--iap-colors-sand-dark-a1)"
  },
  "colors.sand.dark.a2": {
    "value": "#f4f4f309",
    "variable": "var(--iap-colors-sand-dark-a2)"
  },
  "colors.sand.dark.a3": {
    "value": "#f6f6f513",
    "variable": "var(--iap-colors-sand-dark-a3)"
  },
  "colors.sand.dark.a4": {
    "value": "#fefef31b",
    "variable": "var(--iap-colors-sand-dark-a4)"
  },
  "colors.sand.dark.a5": {
    "value": "#fbfbeb23",
    "variable": "var(--iap-colors-sand-dark-a5)"
  },
  "colors.sand.dark.a6": {
    "value": "#fffaed2d",
    "variable": "var(--iap-colors-sand-dark-a6)"
  },
  "colors.sand.dark.a7": {
    "value": "#fffbed3c",
    "variable": "var(--iap-colors-sand-dark-a7)"
  },
  "colors.sand.dark.a8": {
    "value": "#fff9eb57",
    "variable": "var(--iap-colors-sand-dark-a8)"
  },
  "colors.sand.dark.a9": {
    "value": "#fffae965",
    "variable": "var(--iap-colors-sand-dark-a9)"
  },
  "colors.sand.dark.a10": {
    "value": "#fffdee73",
    "variable": "var(--iap-colors-sand-dark-a10)"
  },
  "colors.sand.dark.a11": {
    "value": "#fffcf4b0",
    "variable": "var(--iap-colors-sand-dark-a11)"
  },
  "colors.sand.dark.a12": {
    "value": "#fffffded",
    "variable": "var(--iap-colors-sand-dark-a12)"
  },
  "colors.slate.light.1": {
    "value": "#fcfcfd",
    "variable": "var(--iap-colors-slate-light-1)"
  },
  "colors.slate.light.2": {
    "value": "#f9f9fb",
    "variable": "var(--iap-colors-slate-light-2)"
  },
  "colors.slate.light.3": {
    "value": "#f0f0f3",
    "variable": "var(--iap-colors-slate-light-3)"
  },
  "colors.slate.light.4": {
    "value": "#e8e8ec",
    "variable": "var(--iap-colors-slate-light-4)"
  },
  "colors.slate.light.5": {
    "value": "#e0e1e6",
    "variable": "var(--iap-colors-slate-light-5)"
  },
  "colors.slate.light.6": {
    "value": "#d9d9e0",
    "variable": "var(--iap-colors-slate-light-6)"
  },
  "colors.slate.light.7": {
    "value": "#cdced6",
    "variable": "var(--iap-colors-slate-light-7)"
  },
  "colors.slate.light.8": {
    "value": "#b9bbc6",
    "variable": "var(--iap-colors-slate-light-8)"
  },
  "colors.slate.light.9": {
    "value": "#8b8d98",
    "variable": "var(--iap-colors-slate-light-9)"
  },
  "colors.slate.light.10": {
    "value": "#80838d",
    "variable": "var(--iap-colors-slate-light-10)"
  },
  "colors.slate.light.11": {
    "value": "#60646c",
    "variable": "var(--iap-colors-slate-light-11)"
  },
  "colors.slate.light.12": {
    "value": "#1c2024",
    "variable": "var(--iap-colors-slate-light-12)"
  },
  "colors.slate.light.a1": {
    "value": "#00005503",
    "variable": "var(--iap-colors-slate-light-a1)"
  },
  "colors.slate.light.a2": {
    "value": "#00005506",
    "variable": "var(--iap-colors-slate-light-a2)"
  },
  "colors.slate.light.a3": {
    "value": "#0000330f",
    "variable": "var(--iap-colors-slate-light-a3)"
  },
  "colors.slate.light.a4": {
    "value": "#00002d17",
    "variable": "var(--iap-colors-slate-light-a4)"
  },
  "colors.slate.light.a5": {
    "value": "#0009321f",
    "variable": "var(--iap-colors-slate-light-a5)"
  },
  "colors.slate.light.a6": {
    "value": "#00002f26",
    "variable": "var(--iap-colors-slate-light-a6)"
  },
  "colors.slate.light.a7": {
    "value": "#00062e32",
    "variable": "var(--iap-colors-slate-light-a7)"
  },
  "colors.slate.light.a8": {
    "value": "#00083046",
    "variable": "var(--iap-colors-slate-light-a8)"
  },
  "colors.slate.light.a9": {
    "value": "#00051d74",
    "variable": "var(--iap-colors-slate-light-a9)"
  },
  "colors.slate.light.a10": {
    "value": "#00071b7f",
    "variable": "var(--iap-colors-slate-light-a10)"
  },
  "colors.slate.light.a11": {
    "value": "#0007149f",
    "variable": "var(--iap-colors-slate-light-a11)"
  },
  "colors.slate.light.a12": {
    "value": "#000509e3",
    "variable": "var(--iap-colors-slate-light-a12)"
  },
  "colors.slate.dark.1": {
    "value": "#111113",
    "variable": "var(--iap-colors-slate-dark-1)"
  },
  "colors.slate.dark.2": {
    "value": "#18191b",
    "variable": "var(--iap-colors-slate-dark-2)"
  },
  "colors.slate.dark.3": {
    "value": "#212225",
    "variable": "var(--iap-colors-slate-dark-3)"
  },
  "colors.slate.dark.4": {
    "value": "#272a2d",
    "variable": "var(--iap-colors-slate-dark-4)"
  },
  "colors.slate.dark.5": {
    "value": "#2e3135",
    "variable": "var(--iap-colors-slate-dark-5)"
  },
  "colors.slate.dark.6": {
    "value": "#363a3f",
    "variable": "var(--iap-colors-slate-dark-6)"
  },
  "colors.slate.dark.7": {
    "value": "#43484e",
    "variable": "var(--iap-colors-slate-dark-7)"
  },
  "colors.slate.dark.8": {
    "value": "#5a6169",
    "variable": "var(--iap-colors-slate-dark-8)"
  },
  "colors.slate.dark.9": {
    "value": "#696e77",
    "variable": "var(--iap-colors-slate-dark-9)"
  },
  "colors.slate.dark.10": {
    "value": "#777b84",
    "variable": "var(--iap-colors-slate-dark-10)"
  },
  "colors.slate.dark.11": {
    "value": "#b0b4ba",
    "variable": "var(--iap-colors-slate-dark-11)"
  },
  "colors.slate.dark.12": {
    "value": "#edeef0",
    "variable": "var(--iap-colors-slate-dark-12)"
  },
  "colors.slate.dark.a1": {
    "value": "#00000000",
    "variable": "var(--iap-colors-slate-dark-a1)"
  },
  "colors.slate.dark.a2": {
    "value": "#d8f4f609",
    "variable": "var(--iap-colors-slate-dark-a2)"
  },
  "colors.slate.dark.a3": {
    "value": "#ddeaf814",
    "variable": "var(--iap-colors-slate-dark-a3)"
  },
  "colors.slate.dark.a4": {
    "value": "#d3edf81d",
    "variable": "var(--iap-colors-slate-dark-a4)"
  },
  "colors.slate.dark.a5": {
    "value": "#d9edfe25",
    "variable": "var(--iap-colors-slate-dark-a5)"
  },
  "colors.slate.dark.a6": {
    "value": "#d6ebfd30",
    "variable": "var(--iap-colors-slate-dark-a6)"
  },
  "colors.slate.dark.a7": {
    "value": "#d9edff40",
    "variable": "var(--iap-colors-slate-dark-a7)"
  },
  "colors.slate.dark.a8": {
    "value": "#d9edff5d",
    "variable": "var(--iap-colors-slate-dark-a8)"
  },
  "colors.slate.dark.a9": {
    "value": "#dfebfd6d",
    "variable": "var(--iap-colors-slate-dark-a9)"
  },
  "colors.slate.dark.a10": {
    "value": "#e5edfd7b",
    "variable": "var(--iap-colors-slate-dark-a10)"
  },
  "colors.slate.dark.a11": {
    "value": "#f1f7feb5",
    "variable": "var(--iap-colors-slate-dark-a11)"
  },
  "colors.slate.dark.a12": {
    "value": "#fcfdffef",
    "variable": "var(--iap-colors-slate-dark-a12)"
  },
  "colors.tomato.light.1": {
    "value": "#fffcfc",
    "variable": "var(--iap-colors-tomato-light-1)"
  },
  "colors.tomato.light.2": {
    "value": "#fff8f7",
    "variable": "var(--iap-colors-tomato-light-2)"
  },
  "colors.tomato.light.3": {
    "value": "#feebe7",
    "variable": "var(--iap-colors-tomato-light-3)"
  },
  "colors.tomato.light.4": {
    "value": "#ffdcd3",
    "variable": "var(--iap-colors-tomato-light-4)"
  },
  "colors.tomato.light.5": {
    "value": "#ffcdc2",
    "variable": "var(--iap-colors-tomato-light-5)"
  },
  "colors.tomato.light.6": {
    "value": "#fdbdaf",
    "variable": "var(--iap-colors-tomato-light-6)"
  },
  "colors.tomato.light.7": {
    "value": "#f5a898",
    "variable": "var(--iap-colors-tomato-light-7)"
  },
  "colors.tomato.light.8": {
    "value": "#ec8e7b",
    "variable": "var(--iap-colors-tomato-light-8)"
  },
  "colors.tomato.light.9": {
    "value": "#e54d2e",
    "variable": "var(--iap-colors-tomato-light-9)"
  },
  "colors.tomato.light.10": {
    "value": "#dd4425",
    "variable": "var(--iap-colors-tomato-light-10)"
  },
  "colors.tomato.light.11": {
    "value": "#d13415",
    "variable": "var(--iap-colors-tomato-light-11)"
  },
  "colors.tomato.light.12": {
    "value": "#5c271f",
    "variable": "var(--iap-colors-tomato-light-12)"
  },
  "colors.tomato.light.a1": {
    "value": "#ff000003",
    "variable": "var(--iap-colors-tomato-light-a1)"
  },
  "colors.tomato.light.a2": {
    "value": "#ff200008",
    "variable": "var(--iap-colors-tomato-light-a2)"
  },
  "colors.tomato.light.a3": {
    "value": "#f52b0018",
    "variable": "var(--iap-colors-tomato-light-a3)"
  },
  "colors.tomato.light.a4": {
    "value": "#ff35002c",
    "variable": "var(--iap-colors-tomato-light-a4)"
  },
  "colors.tomato.light.a5": {
    "value": "#ff2e003d",
    "variable": "var(--iap-colors-tomato-light-a5)"
  },
  "colors.tomato.light.a6": {
    "value": "#f92d0050",
    "variable": "var(--iap-colors-tomato-light-a6)"
  },
  "colors.tomato.light.a7": {
    "value": "#e7280067",
    "variable": "var(--iap-colors-tomato-light-a7)"
  },
  "colors.tomato.light.a8": {
    "value": "#db250084",
    "variable": "var(--iap-colors-tomato-light-a8)"
  },
  "colors.tomato.light.a9": {
    "value": "#df2600d1",
    "variable": "var(--iap-colors-tomato-light-a9)"
  },
  "colors.tomato.light.a10": {
    "value": "#d72400da",
    "variable": "var(--iap-colors-tomato-light-a10)"
  },
  "colors.tomato.light.a11": {
    "value": "#cd2200ea",
    "variable": "var(--iap-colors-tomato-light-a11)"
  },
  "colors.tomato.light.a12": {
    "value": "#460900e0",
    "variable": "var(--iap-colors-tomato-light-a12)"
  },
  "colors.tomato.dark.1": {
    "value": "#181111",
    "variable": "var(--iap-colors-tomato-dark-1)"
  },
  "colors.tomato.dark.2": {
    "value": "#1f1513",
    "variable": "var(--iap-colors-tomato-dark-2)"
  },
  "colors.tomato.dark.3": {
    "value": "#391714",
    "variable": "var(--iap-colors-tomato-dark-3)"
  },
  "colors.tomato.dark.4": {
    "value": "#4e1511",
    "variable": "var(--iap-colors-tomato-dark-4)"
  },
  "colors.tomato.dark.5": {
    "value": "#5e1c16",
    "variable": "var(--iap-colors-tomato-dark-5)"
  },
  "colors.tomato.dark.6": {
    "value": "#6e2920",
    "variable": "var(--iap-colors-tomato-dark-6)"
  },
  "colors.tomato.dark.7": {
    "value": "#853a2d",
    "variable": "var(--iap-colors-tomato-dark-7)"
  },
  "colors.tomato.dark.8": {
    "value": "#ac4d39",
    "variable": "var(--iap-colors-tomato-dark-8)"
  },
  "colors.tomato.dark.9": {
    "value": "#e54d2e",
    "variable": "var(--iap-colors-tomato-dark-9)"
  },
  "colors.tomato.dark.10": {
    "value": "#ec6142",
    "variable": "var(--iap-colors-tomato-dark-10)"
  },
  "colors.tomato.dark.11": {
    "value": "#ff977d",
    "variable": "var(--iap-colors-tomato-dark-11)"
  },
  "colors.tomato.dark.12": {
    "value": "#fbd3cb",
    "variable": "var(--iap-colors-tomato-dark-12)"
  },
  "colors.tomato.dark.a1": {
    "value": "#f1121208",
    "variable": "var(--iap-colors-tomato-dark-a1)"
  },
  "colors.tomato.dark.a2": {
    "value": "#ff55330f",
    "variable": "var(--iap-colors-tomato-dark-a2)"
  },
  "colors.tomato.dark.a3": {
    "value": "#ff35232b",
    "variable": "var(--iap-colors-tomato-dark-a3)"
  },
  "colors.tomato.dark.a4": {
    "value": "#fd201142",
    "variable": "var(--iap-colors-tomato-dark-a4)"
  },
  "colors.tomato.dark.a5": {
    "value": "#fe332153",
    "variable": "var(--iap-colors-tomato-dark-a5)"
  },
  "colors.tomato.dark.a6": {
    "value": "#ff4f3864",
    "variable": "var(--iap-colors-tomato-dark-a6)"
  },
  "colors.tomato.dark.a7": {
    "value": "#fd644a7d",
    "variable": "var(--iap-colors-tomato-dark-a7)"
  },
  "colors.tomato.dark.a8": {
    "value": "#fe6d4ea7",
    "variable": "var(--iap-colors-tomato-dark-a8)"
  },
  "colors.tomato.dark.a9": {
    "value": "#fe5431e4",
    "variable": "var(--iap-colors-tomato-dark-a9)"
  },
  "colors.tomato.dark.a10": {
    "value": "#ff6847eb",
    "variable": "var(--iap-colors-tomato-dark-a10)"
  },
  "colors.tomato.dark.a11": {
    "value": "#ff977d",
    "variable": "var(--iap-colors-tomato-dark-a11)"
  },
  "colors.tomato.dark.a12": {
    "value": "#ffd6cefb",
    "variable": "var(--iap-colors-tomato-dark-a12)"
  },
  "colors.yellow.light.1": {
    "value": "#fdfdf9",
    "variable": "var(--iap-colors-yellow-light-1)"
  },
  "colors.yellow.light.2": {
    "value": "#fefce9",
    "variable": "var(--iap-colors-yellow-light-2)"
  },
  "colors.yellow.light.3": {
    "value": "#fffab8",
    "variable": "var(--iap-colors-yellow-light-3)"
  },
  "colors.yellow.light.4": {
    "value": "#fff394",
    "variable": "var(--iap-colors-yellow-light-4)"
  },
  "colors.yellow.light.5": {
    "value": "#ffe770",
    "variable": "var(--iap-colors-yellow-light-5)"
  },
  "colors.yellow.light.6": {
    "value": "#f3d768",
    "variable": "var(--iap-colors-yellow-light-6)"
  },
  "colors.yellow.light.7": {
    "value": "#e4c767",
    "variable": "var(--iap-colors-yellow-light-7)"
  },
  "colors.yellow.light.8": {
    "value": "#d5ae39",
    "variable": "var(--iap-colors-yellow-light-8)"
  },
  "colors.yellow.light.9": {
    "value": "#ffe629",
    "variable": "var(--iap-colors-yellow-light-9)"
  },
  "colors.yellow.light.10": {
    "value": "#ffdc00",
    "variable": "var(--iap-colors-yellow-light-10)"
  },
  "colors.yellow.light.11": {
    "value": "#9e6c00",
    "variable": "var(--iap-colors-yellow-light-11)"
  },
  "colors.yellow.light.12": {
    "value": "#473b1f",
    "variable": "var(--iap-colors-yellow-light-12)"
  },
  "colors.yellow.light.a1": {
    "value": "#aaaa0006",
    "variable": "var(--iap-colors-yellow-light-a1)"
  },
  "colors.yellow.light.a2": {
    "value": "#f4dd0016",
    "variable": "var(--iap-colors-yellow-light-a2)"
  },
  "colors.yellow.light.a3": {
    "value": "#ffee0047",
    "variable": "var(--iap-colors-yellow-light-a3)"
  },
  "colors.yellow.light.a4": {
    "value": "#ffe3016b",
    "variable": "var(--iap-colors-yellow-light-a4)"
  },
  "colors.yellow.light.a5": {
    "value": "#ffd5008f",
    "variable": "var(--iap-colors-yellow-light-a5)"
  },
  "colors.yellow.light.a6": {
    "value": "#ebbc0097",
    "variable": "var(--iap-colors-yellow-light-a6)"
  },
  "colors.yellow.light.a7": {
    "value": "#d2a10098",
    "variable": "var(--iap-colors-yellow-light-a7)"
  },
  "colors.yellow.light.a8": {
    "value": "#c99700c6",
    "variable": "var(--iap-colors-yellow-light-a8)"
  },
  "colors.yellow.light.a9": {
    "value": "#ffe100d6",
    "variable": "var(--iap-colors-yellow-light-a9)"
  },
  "colors.yellow.light.a10": {
    "value": "#ffdc00",
    "variable": "var(--iap-colors-yellow-light-a10)"
  },
  "colors.yellow.light.a11": {
    "value": "#9e6c00",
    "variable": "var(--iap-colors-yellow-light-a11)"
  },
  "colors.yellow.light.a12": {
    "value": "#2e2000e0",
    "variable": "var(--iap-colors-yellow-light-a12)"
  },
  "colors.yellow.dark.1": {
    "value": "#14120b",
    "variable": "var(--iap-colors-yellow-dark-1)"
  },
  "colors.yellow.dark.2": {
    "value": "#1b180f",
    "variable": "var(--iap-colors-yellow-dark-2)"
  },
  "colors.yellow.dark.3": {
    "value": "#2d2305",
    "variable": "var(--iap-colors-yellow-dark-3)"
  },
  "colors.yellow.dark.4": {
    "value": "#362b00",
    "variable": "var(--iap-colors-yellow-dark-4)"
  },
  "colors.yellow.dark.5": {
    "value": "#433500",
    "variable": "var(--iap-colors-yellow-dark-5)"
  },
  "colors.yellow.dark.6": {
    "value": "#524202",
    "variable": "var(--iap-colors-yellow-dark-6)"
  },
  "colors.yellow.dark.7": {
    "value": "#665417",
    "variable": "var(--iap-colors-yellow-dark-7)"
  },
  "colors.yellow.dark.8": {
    "value": "#836a21",
    "variable": "var(--iap-colors-yellow-dark-8)"
  },
  "colors.yellow.dark.9": {
    "value": "#ffe629",
    "variable": "var(--iap-colors-yellow-dark-9)"
  },
  "colors.yellow.dark.10": {
    "value": "#ffff57",
    "variable": "var(--iap-colors-yellow-dark-10)"
  },
  "colors.yellow.dark.11": {
    "value": "#f5e147",
    "variable": "var(--iap-colors-yellow-dark-11)"
  },
  "colors.yellow.dark.12": {
    "value": "#f6eeb4",
    "variable": "var(--iap-colors-yellow-dark-12)"
  },
  "colors.yellow.dark.a1": {
    "value": "#d1510004",
    "variable": "var(--iap-colors-yellow-dark-a1)"
  },
  "colors.yellow.dark.a2": {
    "value": "#f9b4000b",
    "variable": "var(--iap-colors-yellow-dark-a2)"
  },
  "colors.yellow.dark.a3": {
    "value": "#ffaa001e",
    "variable": "var(--iap-colors-yellow-dark-a3)"
  },
  "colors.yellow.dark.a4": {
    "value": "#fdb70028",
    "variable": "var(--iap-colors-yellow-dark-a4)"
  },
  "colors.yellow.dark.a5": {
    "value": "#febb0036",
    "variable": "var(--iap-colors-yellow-dark-a5)"
  },
  "colors.yellow.dark.a6": {
    "value": "#fec40046",
    "variable": "var(--iap-colors-yellow-dark-a6)"
  },
  "colors.yellow.dark.a7": {
    "value": "#fdcb225c",
    "variable": "var(--iap-colors-yellow-dark-a7)"
  },
  "colors.yellow.dark.a8": {
    "value": "#fdca327b",
    "variable": "var(--iap-colors-yellow-dark-a8)"
  },
  "colors.yellow.dark.a9": {
    "value": "#ffe629",
    "variable": "var(--iap-colors-yellow-dark-a9)"
  },
  "colors.yellow.dark.a10": {
    "value": "#ffff57",
    "variable": "var(--iap-colors-yellow-dark-a10)"
  },
  "colors.yellow.dark.a11": {
    "value": "#fee949f5",
    "variable": "var(--iap-colors-yellow-dark-a11)"
  },
  "colors.yellow.dark.a12": {
    "value": "#fef6baf6",
    "variable": "var(--iap-colors-yellow-dark-a12)"
  },
  "colors.neutral.light.1": {
    "value": "#fcfcfc",
    "variable": "var(--iap-colors-neutral-light-1)"
  },
  "colors.neutral.light.2": {
    "value": "#f9f9f9",
    "variable": "var(--iap-colors-neutral-light-2)"
  },
  "colors.neutral.light.3": {
    "value": "#f0f0f0",
    "variable": "var(--iap-colors-neutral-light-3)"
  },
  "colors.neutral.light.4": {
    "value": "#e8e8e8",
    "variable": "var(--iap-colors-neutral-light-4)"
  },
  "colors.neutral.light.5": {
    "value": "#e0e0e0",
    "variable": "var(--iap-colors-neutral-light-5)"
  },
  "colors.neutral.light.6": {
    "value": "#d9d9d9",
    "variable": "var(--iap-colors-neutral-light-6)"
  },
  "colors.neutral.light.7": {
    "value": "#cecece",
    "variable": "var(--iap-colors-neutral-light-7)"
  },
  "colors.neutral.light.8": {
    "value": "#bbbbbb",
    "variable": "var(--iap-colors-neutral-light-8)"
  },
  "colors.neutral.light.9": {
    "value": "#8d8d8d",
    "variable": "var(--iap-colors-neutral-light-9)"
  },
  "colors.neutral.light.10": {
    "value": "#838383",
    "variable": "var(--iap-colors-neutral-light-10)"
  },
  "colors.neutral.light.11": {
    "value": "#646464",
    "variable": "var(--iap-colors-neutral-light-11)"
  },
  "colors.neutral.light.12": {
    "value": "#202020",
    "variable": "var(--iap-colors-neutral-light-12)"
  },
  "colors.neutral.light.a1": {
    "value": "#00000003",
    "variable": "var(--iap-colors-neutral-light-a1)"
  },
  "colors.neutral.light.a2": {
    "value": "#00000006",
    "variable": "var(--iap-colors-neutral-light-a2)"
  },
  "colors.neutral.light.a3": {
    "value": "#0000000f",
    "variable": "var(--iap-colors-neutral-light-a3)"
  },
  "colors.neutral.light.a4": {
    "value": "#00000017",
    "variable": "var(--iap-colors-neutral-light-a4)"
  },
  "colors.neutral.light.a5": {
    "value": "#0000001f",
    "variable": "var(--iap-colors-neutral-light-a5)"
  },
  "colors.neutral.light.a6": {
    "value": "#00000026",
    "variable": "var(--iap-colors-neutral-light-a6)"
  },
  "colors.neutral.light.a7": {
    "value": "#00000031",
    "variable": "var(--iap-colors-neutral-light-a7)"
  },
  "colors.neutral.light.a8": {
    "value": "#00000044",
    "variable": "var(--iap-colors-neutral-light-a8)"
  },
  "colors.neutral.light.a9": {
    "value": "#00000072",
    "variable": "var(--iap-colors-neutral-light-a9)"
  },
  "colors.neutral.light.a10": {
    "value": "#0000007c",
    "variable": "var(--iap-colors-neutral-light-a10)"
  },
  "colors.neutral.light.a11": {
    "value": "#0000009b",
    "variable": "var(--iap-colors-neutral-light-a11)"
  },
  "colors.neutral.light.a12": {
    "value": "#000000df",
    "variable": "var(--iap-colors-neutral-light-a12)"
  },
  "colors.neutral.dark.1": {
    "value": "#111111",
    "variable": "var(--iap-colors-neutral-dark-1)"
  },
  "colors.neutral.dark.2": {
    "value": "#191919",
    "variable": "var(--iap-colors-neutral-dark-2)"
  },
  "colors.neutral.dark.3": {
    "value": "#222222",
    "variable": "var(--iap-colors-neutral-dark-3)"
  },
  "colors.neutral.dark.4": {
    "value": "#2a2a2a",
    "variable": "var(--iap-colors-neutral-dark-4)"
  },
  "colors.neutral.dark.5": {
    "value": "#313131",
    "variable": "var(--iap-colors-neutral-dark-5)"
  },
  "colors.neutral.dark.6": {
    "value": "#3a3a3a",
    "variable": "var(--iap-colors-neutral-dark-6)"
  },
  "colors.neutral.dark.7": {
    "value": "#484848",
    "variable": "var(--iap-colors-neutral-dark-7)"
  },
  "colors.neutral.dark.8": {
    "value": "#606060",
    "variable": "var(--iap-colors-neutral-dark-8)"
  },
  "colors.neutral.dark.9": {
    "value": "#6e6e6e",
    "variable": "var(--iap-colors-neutral-dark-9)"
  },
  "colors.neutral.dark.10": {
    "value": "#7b7b7b",
    "variable": "var(--iap-colors-neutral-dark-10)"
  },
  "colors.neutral.dark.11": {
    "value": "#b4b4b4",
    "variable": "var(--iap-colors-neutral-dark-11)"
  },
  "colors.neutral.dark.12": {
    "value": "#eeeeee",
    "variable": "var(--iap-colors-neutral-dark-12)"
  },
  "colors.neutral.dark.a1": {
    "value": "#00000000",
    "variable": "var(--iap-colors-neutral-dark-a1)"
  },
  "colors.neutral.dark.a2": {
    "value": "#ffffff09",
    "variable": "var(--iap-colors-neutral-dark-a2)"
  },
  "colors.neutral.dark.a3": {
    "value": "#ffffff12",
    "variable": "var(--iap-colors-neutral-dark-a3)"
  },
  "colors.neutral.dark.a4": {
    "value": "#ffffff1b",
    "variable": "var(--iap-colors-neutral-dark-a4)"
  },
  "colors.neutral.dark.a5": {
    "value": "#ffffff22",
    "variable": "var(--iap-colors-neutral-dark-a5)"
  },
  "colors.neutral.dark.a6": {
    "value": "#ffffff2c",
    "variable": "var(--iap-colors-neutral-dark-a6)"
  },
  "colors.neutral.dark.a7": {
    "value": "#ffffff3b",
    "variable": "var(--iap-colors-neutral-dark-a7)"
  },
  "colors.neutral.dark.a8": {
    "value": "#ffffff55",
    "variable": "var(--iap-colors-neutral-dark-a8)"
  },
  "colors.neutral.dark.a9": {
    "value": "#ffffff64",
    "variable": "var(--iap-colors-neutral-dark-a9)"
  },
  "colors.neutral.dark.a10": {
    "value": "#ffffff72",
    "variable": "var(--iap-colors-neutral-dark-a10)"
  },
  "colors.neutral.dark.a11": {
    "value": "#ffffffaf",
    "variable": "var(--iap-colors-neutral-dark-a11)"
  },
  "colors.neutral.dark.a12": {
    "value": "#ffffffed",
    "variable": "var(--iap-colors-neutral-dark-a12)"
  },
  "durations.fastest": {
    "value": "50ms",
    "variable": "var(--iap-durations-fastest)"
  },
  "durations.faster": {
    "value": "100ms",
    "variable": "var(--iap-durations-faster)"
  },
  "durations.fast": {
    "value": "150ms",
    "variable": "var(--iap-durations-fast)"
  },
  "durations.normal": {
    "value": "200ms",
    "variable": "var(--iap-durations-normal)"
  },
  "durations.slow": {
    "value": "300ms",
    "variable": "var(--iap-durations-slow)"
  },
  "durations.slower": {
    "value": "400ms",
    "variable": "var(--iap-durations-slower)"
  },
  "durations.slowest": {
    "value": "500ms",
    "variable": "var(--iap-durations-slowest)"
  },
  "easings.pulse": {
    "value": "cubic-bezier(0.4, 0.0, 0.6, 1.0)",
    "variable": "var(--iap-easings-pulse)"
  },
  "easings.default": {
    "value": "cubic-bezier(0.2, 0.0, 0, 1.0)",
    "variable": "var(--iap-easings-default)"
  },
  "easings.emphasized-in": {
    "value": "cubic-bezier(0.05, 0.7, 0.1, 1.0)",
    "variable": "var(--iap-easings-emphasized-in)"
  },
  "easings.emphasized-out": {
    "value": "cubic-bezier(0.3, 0.0, 0.8, 0.15)",
    "variable": "var(--iap-easings-emphasized-out)"
  },
  "fonts.sans": {
    "value": "ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"",
    "variable": "var(--iap-fonts-sans)"
  },
  "fonts.serif": {
    "value": "ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif",
    "variable": "var(--iap-fonts-serif)"
  },
  "fonts.mono": {
    "value": "ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, \"Liberation Mono\", \"Courier New\", monospace",
    "variable": "var(--iap-fonts-mono)"
  },
  "fontSizes.2xs": {
    "value": "0.5rem",
    "variable": "var(--iap-font-sizes-2xs)"
  },
  "fontSizes.xs": {
    "value": "0.75rem",
    "variable": "var(--iap-font-sizes-xs)"
  },
  "fontSizes.sm": {
    "value": "0.875rem",
    "variable": "var(--iap-font-sizes-sm)"
  },
  "fontSizes.md": {
    "value": "1rem",
    "variable": "var(--iap-font-sizes-md)"
  },
  "fontSizes.lg": {
    "value": "1.125rem",
    "variable": "var(--iap-font-sizes-lg)"
  },
  "fontSizes.xl": {
    "value": "1.25rem",
    "variable": "var(--iap-font-sizes-xl)"
  },
  "fontSizes.2xl": {
    "value": "1.5rem",
    "variable": "var(--iap-font-sizes-2xl)"
  },
  "fontSizes.3xl": {
    "value": "1.875rem",
    "variable": "var(--iap-font-sizes-3xl)"
  },
  "fontSizes.4xl": {
    "value": "2.25rem",
    "variable": "var(--iap-font-sizes-4xl)"
  },
  "fontSizes.5xl": {
    "value": "3rem",
    "variable": "var(--iap-font-sizes-5xl)"
  },
  "fontSizes.6xl": {
    "value": "3.75rem",
    "variable": "var(--iap-font-sizes-6xl)"
  },
  "fontSizes.7xl": {
    "value": "4.5rem",
    "variable": "var(--iap-font-sizes-7xl)"
  },
  "fontSizes.8xl": {
    "value": "6rem",
    "variable": "var(--iap-font-sizes-8xl)"
  },
  "fontSizes.9xl": {
    "value": "8rem",
    "variable": "var(--iap-font-sizes-9xl)"
  },
  "fontWeights.thin": {
    "value": "100",
    "variable": "var(--iap-font-weights-thin)"
  },
  "fontWeights.extralight": {
    "value": "200",
    "variable": "var(--iap-font-weights-extralight)"
  },
  "fontWeights.light": {
    "value": "300",
    "variable": "var(--iap-font-weights-light)"
  },
  "fontWeights.normal": {
    "value": "400",
    "variable": "var(--iap-font-weights-normal)"
  },
  "fontWeights.medium": {
    "value": "500",
    "variable": "var(--iap-font-weights-medium)"
  },
  "fontWeights.semibold": {
    "value": "600",
    "variable": "var(--iap-font-weights-semibold)"
  },
  "fontWeights.bold": {
    "value": "700",
    "variable": "var(--iap-font-weights-bold)"
  },
  "fontWeights.extrabold": {
    "value": "800",
    "variable": "var(--iap-font-weights-extrabold)"
  },
  "fontWeights.black": {
    "value": "900",
    "variable": "var(--iap-font-weights-black)"
  },
  "letterSpacings.tighter": {
    "value": "-0.05em",
    "variable": "var(--iap-letter-spacings-tighter)"
  },
  "letterSpacings.tight": {
    "value": "-0.025em",
    "variable": "var(--iap-letter-spacings-tight)"
  },
  "letterSpacings.normal": {
    "value": "0em",
    "variable": "var(--iap-letter-spacings-normal)"
  },
  "letterSpacings.wide": {
    "value": "0.025em",
    "variable": "var(--iap-letter-spacings-wide)"
  },
  "letterSpacings.wider": {
    "value": "0.05em",
    "variable": "var(--iap-letter-spacings-wider)"
  },
  "letterSpacings.widest": {
    "value": "0.1em",
    "variable": "var(--iap-letter-spacings-widest)"
  },
  "lineHeights.none": {
    "value": "1",
    "variable": "var(--iap-line-heights-none)"
  },
  "lineHeights.tight": {
    "value": "1.25",
    "variable": "var(--iap-line-heights-tight)"
  },
  "lineHeights.normal": {
    "value": "1.5",
    "variable": "var(--iap-line-heights-normal)"
  },
  "lineHeights.relaxed": {
    "value": "1.75",
    "variable": "var(--iap-line-heights-relaxed)"
  },
  "lineHeights.loose": {
    "value": "2",
    "variable": "var(--iap-line-heights-loose)"
  },
  "radii.none": {
    "value": "0",
    "variable": "var(--iap-radii-none)"
  },
  "radii.2xs": {
    "value": "0.0625rem",
    "variable": "var(--iap-radii-2xs)"
  },
  "radii.xs": {
    "value": "0.125rem",
    "variable": "var(--iap-radii-xs)"
  },
  "radii.sm": {
    "value": "0.25rem",
    "variable": "var(--iap-radii-sm)"
  },
  "radii.md": {
    "value": "0.375rem",
    "variable": "var(--iap-radii-md)"
  },
  "radii.lg": {
    "value": "0.5rem",
    "variable": "var(--iap-radii-lg)"
  },
  "radii.xl": {
    "value": "0.75rem",
    "variable": "var(--iap-radii-xl)"
  },
  "radii.2xl": {
    "value": "1rem",
    "variable": "var(--iap-radii-2xl)"
  },
  "radii.3xl": {
    "value": "1.5rem",
    "variable": "var(--iap-radii-3xl)"
  },
  "radii.full": {
    "value": "9999px",
    "variable": "var(--iap-radii-full)"
  },
  "sizes.0": {
    "value": "0rem",
    "variable": "var(--iap-sizes-0)"
  },
  "sizes.1": {
    "value": "0.25rem",
    "variable": "var(--iap-sizes-1)"
  },
  "sizes.2": {
    "value": "0.5rem",
    "variable": "var(--iap-sizes-2)"
  },
  "sizes.3": {
    "value": "0.75rem",
    "variable": "var(--iap-sizes-3)"
  },
  "sizes.4": {
    "value": "1rem",
    "variable": "var(--iap-sizes-4)"
  },
  "sizes.5": {
    "value": "1.25rem",
    "variable": "var(--iap-sizes-5)"
  },
  "sizes.6": {
    "value": "1.5rem",
    "variable": "var(--iap-sizes-6)"
  },
  "sizes.7": {
    "value": "1.75rem",
    "variable": "var(--iap-sizes-7)"
  },
  "sizes.8": {
    "value": "2rem",
    "variable": "var(--iap-sizes-8)"
  },
  "sizes.9": {
    "value": "2.25rem",
    "variable": "var(--iap-sizes-9)"
  },
  "sizes.10": {
    "value": "2.5rem",
    "variable": "var(--iap-sizes-10)"
  },
  "sizes.11": {
    "value": "2.75rem",
    "variable": "var(--iap-sizes-11)"
  },
  "sizes.12": {
    "value": "3rem",
    "variable": "var(--iap-sizes-12)"
  },
  "sizes.14": {
    "value": "3.5rem",
    "variable": "var(--iap-sizes-14)"
  },
  "sizes.16": {
    "value": "4rem",
    "variable": "var(--iap-sizes-16)"
  },
  "sizes.20": {
    "value": "5rem",
    "variable": "var(--iap-sizes-20)"
  },
  "sizes.24": {
    "value": "6rem",
    "variable": "var(--iap-sizes-24)"
  },
  "sizes.28": {
    "value": "7rem",
    "variable": "var(--iap-sizes-28)"
  },
  "sizes.32": {
    "value": "8rem",
    "variable": "var(--iap-sizes-32)"
  },
  "sizes.36": {
    "value": "9rem",
    "variable": "var(--iap-sizes-36)"
  },
  "sizes.40": {
    "value": "10rem",
    "variable": "var(--iap-sizes-40)"
  },
  "sizes.44": {
    "value": "11rem",
    "variable": "var(--iap-sizes-44)"
  },
  "sizes.48": {
    "value": "12rem",
    "variable": "var(--iap-sizes-48)"
  },
  "sizes.52": {
    "value": "13rem",
    "variable": "var(--iap-sizes-52)"
  },
  "sizes.56": {
    "value": "14rem",
    "variable": "var(--iap-sizes-56)"
  },
  "sizes.60": {
    "value": "15rem",
    "variable": "var(--iap-sizes-60)"
  },
  "sizes.64": {
    "value": "16rem",
    "variable": "var(--iap-sizes-64)"
  },
  "sizes.72": {
    "value": "18rem",
    "variable": "var(--iap-sizes-72)"
  },
  "sizes.80": {
    "value": "20rem",
    "variable": "var(--iap-sizes-80)"
  },
  "sizes.96": {
    "value": "24rem",
    "variable": "var(--iap-sizes-96)"
  },
  "sizes.0.5": {
    "value": "0.125rem",
    "variable": "var(--iap-sizes-0\\.5)"
  },
  "sizes.1.5": {
    "value": "0.375rem",
    "variable": "var(--iap-sizes-1\\.5)"
  },
  "sizes.2.5": {
    "value": "0.625rem",
    "variable": "var(--iap-sizes-2\\.5)"
  },
  "sizes.3.5": {
    "value": "0.875rem",
    "variable": "var(--iap-sizes-3\\.5)"
  },
  "sizes.4.5": {
    "value": "1.125rem",
    "variable": "var(--iap-sizes-4\\.5)"
  },
  "sizes.2xs": {
    "value": "16rem",
    "variable": "var(--iap-sizes-2xs)"
  },
  "sizes.xs": {
    "value": "20rem",
    "variable": "var(--iap-sizes-xs)"
  },
  "sizes.sm": {
    "value": "24rem",
    "variable": "var(--iap-sizes-sm)"
  },
  "sizes.md": {
    "value": "28rem",
    "variable": "var(--iap-sizes-md)"
  },
  "sizes.lg": {
    "value": "32rem",
    "variable": "var(--iap-sizes-lg)"
  },
  "sizes.xl": {
    "value": "36rem",
    "variable": "var(--iap-sizes-xl)"
  },
  "sizes.2xl": {
    "value": "42rem",
    "variable": "var(--iap-sizes-2xl)"
  },
  "sizes.3xl": {
    "value": "48rem",
    "variable": "var(--iap-sizes-3xl)"
  },
  "sizes.4xl": {
    "value": "56rem",
    "variable": "var(--iap-sizes-4xl)"
  },
  "sizes.5xl": {
    "value": "64rem",
    "variable": "var(--iap-sizes-5xl)"
  },
  "sizes.6xl": {
    "value": "72rem",
    "variable": "var(--iap-sizes-6xl)"
  },
  "sizes.7xl": {
    "value": "80rem",
    "variable": "var(--iap-sizes-7xl)"
  },
  "sizes.8xl": {
    "value": "90rem",
    "variable": "var(--iap-sizes-8xl)"
  },
  "sizes.full": {
    "value": "100%",
    "variable": "var(--iap-sizes-full)"
  },
  "sizes.min": {
    "value": "min-content",
    "variable": "var(--iap-sizes-min)"
  },
  "sizes.max": {
    "value": "max-content",
    "variable": "var(--iap-sizes-max)"
  },
  "sizes.fit": {
    "value": "fit-content",
    "variable": "var(--iap-sizes-fit)"
  },
  "sizes.breakpoint-sm": {
    "value": "640px",
    "variable": "var(--iap-sizes-breakpoint-sm)"
  },
  "sizes.breakpoint-md": {
    "value": "768px",
    "variable": "var(--iap-sizes-breakpoint-md)"
  },
  "sizes.breakpoint-lg": {
    "value": "1024px",
    "variable": "var(--iap-sizes-breakpoint-lg)"
  },
  "sizes.breakpoint-xl": {
    "value": "1280px",
    "variable": "var(--iap-sizes-breakpoint-xl)"
  },
  "sizes.breakpoint-2xl": {
    "value": "1536px",
    "variable": "var(--iap-sizes-breakpoint-2xl)"
  },
  "spacing.0": {
    "value": "0rem",
    "variable": "var(--iap-spacing-0)"
  },
  "spacing.1": {
    "value": "0.25rem",
    "variable": "var(--iap-spacing-1)"
  },
  "spacing.2": {
    "value": "0.5rem",
    "variable": "var(--iap-spacing-2)"
  },
  "spacing.3": {
    "value": "0.75rem",
    "variable": "var(--iap-spacing-3)"
  },
  "spacing.4": {
    "value": "1rem",
    "variable": "var(--iap-spacing-4)"
  },
  "spacing.5": {
    "value": "1.25rem",
    "variable": "var(--iap-spacing-5)"
  },
  "spacing.6": {
    "value": "1.5rem",
    "variable": "var(--iap-spacing-6)"
  },
  "spacing.7": {
    "value": "1.75rem",
    "variable": "var(--iap-spacing-7)"
  },
  "spacing.8": {
    "value": "2rem",
    "variable": "var(--iap-spacing-8)"
  },
  "spacing.9": {
    "value": "2.25rem",
    "variable": "var(--iap-spacing-9)"
  },
  "spacing.10": {
    "value": "2.5rem",
    "variable": "var(--iap-spacing-10)"
  },
  "spacing.11": {
    "value": "2.75rem",
    "variable": "var(--iap-spacing-11)"
  },
  "spacing.12": {
    "value": "3rem",
    "variable": "var(--iap-spacing-12)"
  },
  "spacing.14": {
    "value": "3.5rem",
    "variable": "var(--iap-spacing-14)"
  },
  "spacing.16": {
    "value": "4rem",
    "variable": "var(--iap-spacing-16)"
  },
  "spacing.20": {
    "value": "5rem",
    "variable": "var(--iap-spacing-20)"
  },
  "spacing.24": {
    "value": "6rem",
    "variable": "var(--iap-spacing-24)"
  },
  "spacing.28": {
    "value": "7rem",
    "variable": "var(--iap-spacing-28)"
  },
  "spacing.32": {
    "value": "8rem",
    "variable": "var(--iap-spacing-32)"
  },
  "spacing.36": {
    "value": "9rem",
    "variable": "var(--iap-spacing-36)"
  },
  "spacing.40": {
    "value": "10rem",
    "variable": "var(--iap-spacing-40)"
  },
  "spacing.44": {
    "value": "11rem",
    "variable": "var(--iap-spacing-44)"
  },
  "spacing.48": {
    "value": "12rem",
    "variable": "var(--iap-spacing-48)"
  },
  "spacing.52": {
    "value": "13rem",
    "variable": "var(--iap-spacing-52)"
  },
  "spacing.56": {
    "value": "14rem",
    "variable": "var(--iap-spacing-56)"
  },
  "spacing.60": {
    "value": "15rem",
    "variable": "var(--iap-spacing-60)"
  },
  "spacing.64": {
    "value": "16rem",
    "variable": "var(--iap-spacing-64)"
  },
  "spacing.72": {
    "value": "18rem",
    "variable": "var(--iap-spacing-72)"
  },
  "spacing.80": {
    "value": "20rem",
    "variable": "var(--iap-spacing-80)"
  },
  "spacing.96": {
    "value": "24rem",
    "variable": "var(--iap-spacing-96)"
  },
  "spacing.0.5": {
    "value": "0.125rem",
    "variable": "var(--iap-spacing-0\\.5)"
  },
  "spacing.1.5": {
    "value": "0.375rem",
    "variable": "var(--iap-spacing-1\\.5)"
  },
  "spacing.2.5": {
    "value": "0.625rem",
    "variable": "var(--iap-spacing-2\\.5)"
  },
  "spacing.3.5": {
    "value": "0.875rem",
    "variable": "var(--iap-spacing-3\\.5)"
  },
  "spacing.4.5": {
    "value": "1.125rem",
    "variable": "var(--iap-spacing-4\\.5)"
  },
  "zIndex.hide": {
    "value": -1,
    "variable": "var(--iap-z-index-hide)"
  },
  "zIndex.base": {
    "value": 0,
    "variable": "var(--iap-z-index-base)"
  },
  "zIndex.docked": {
    "value": 10,
    "variable": "var(--iap-z-index-docked)"
  },
  "zIndex.dropdown": {
    "value": 1000,
    "variable": "var(--iap-z-index-dropdown)"
  },
  "zIndex.sticky": {
    "value": 1100,
    "variable": "var(--iap-z-index-sticky)"
  },
  "zIndex.banner": {
    "value": 1200,
    "variable": "var(--iap-z-index-banner)"
  },
  "zIndex.overlay": {
    "value": 1300,
    "variable": "var(--iap-z-index-overlay)"
  },
  "zIndex.modal": {
    "value": 1400,
    "variable": "var(--iap-z-index-modal)"
  },
  "zIndex.popover": {
    "value": 1500,
    "variable": "var(--iap-z-index-popover)"
  },
  "zIndex.skipLink": {
    "value": 1600,
    "variable": "var(--iap-z-index-skip-link)"
  },
  "zIndex.toast": {
    "value": 1700,
    "variable": "var(--iap-z-index-toast)"
  },
  "zIndex.tooltip": {
    "value": 1800,
    "variable": "var(--iap-z-index-tooltip)"
  },
  "breakpoints.sm": {
    "value": "640px",
    "variable": "var(--iap-breakpoints-sm)"
  },
  "breakpoints.md": {
    "value": "768px",
    "variable": "var(--iap-breakpoints-md)"
  },
  "breakpoints.lg": {
    "value": "1024px",
    "variable": "var(--iap-breakpoints-lg)"
  },
  "breakpoints.xl": {
    "value": "1280px",
    "variable": "var(--iap-breakpoints-xl)"
  },
  "breakpoints.2xl": {
    "value": "1536px",
    "variable": "var(--iap-breakpoints-2xl)"
  },
  "colors.surface.default": {
    "value": "var(--iap-colors-neutral-2)",
    "variable": "var(--iap-colors-surface-default)"
  },
  "colors.touchable.hover": {
    "value": "var(--iap-colors-neutral-3)",
    "variable": "var(--iap-colors-touchable-hover)"
  },
  "radii.l1": {
    "value": "var(--iap-radii-none)",
    "variable": "var(--iap-radii-l1)"
  },
  "radii.l2": {
    "value": "var(--iap-radii-none)",
    "variable": "var(--iap-radii-l2)"
  },
  "radii.l3": {
    "value": "var(--iap-radii-none)",
    "variable": "var(--iap-radii-l3)"
  },
  "spacing.-1": {
    "value": "calc(var(--iap-spacing-1) * -1)",
    "variable": "var(--iap-spacing-1)"
  },
  "spacing.-2": {
    "value": "calc(var(--iap-spacing-2) * -1)",
    "variable": "var(--iap-spacing-2)"
  },
  "spacing.-3": {
    "value": "calc(var(--iap-spacing-3) * -1)",
    "variable": "var(--iap-spacing-3)"
  },
  "spacing.-4": {
    "value": "calc(var(--iap-spacing-4) * -1)",
    "variable": "var(--iap-spacing-4)"
  },
  "spacing.-5": {
    "value": "calc(var(--iap-spacing-5) * -1)",
    "variable": "var(--iap-spacing-5)"
  },
  "spacing.-6": {
    "value": "calc(var(--iap-spacing-6) * -1)",
    "variable": "var(--iap-spacing-6)"
  },
  "spacing.-7": {
    "value": "calc(var(--iap-spacing-7) * -1)",
    "variable": "var(--iap-spacing-7)"
  },
  "spacing.-8": {
    "value": "calc(var(--iap-spacing-8) * -1)",
    "variable": "var(--iap-spacing-8)"
  },
  "spacing.-9": {
    "value": "calc(var(--iap-spacing-9) * -1)",
    "variable": "var(--iap-spacing-9)"
  },
  "spacing.-10": {
    "value": "calc(var(--iap-spacing-10) * -1)",
    "variable": "var(--iap-spacing-10)"
  },
  "spacing.-11": {
    "value": "calc(var(--iap-spacing-11) * -1)",
    "variable": "var(--iap-spacing-11)"
  },
  "spacing.-12": {
    "value": "calc(var(--iap-spacing-12) * -1)",
    "variable": "var(--iap-spacing-12)"
  },
  "spacing.-14": {
    "value": "calc(var(--iap-spacing-14) * -1)",
    "variable": "var(--iap-spacing-14)"
  },
  "spacing.-16": {
    "value": "calc(var(--iap-spacing-16) * -1)",
    "variable": "var(--iap-spacing-16)"
  },
  "spacing.-20": {
    "value": "calc(var(--iap-spacing-20) * -1)",
    "variable": "var(--iap-spacing-20)"
  },
  "spacing.-24": {
    "value": "calc(var(--iap-spacing-24) * -1)",
    "variable": "var(--iap-spacing-24)"
  },
  "spacing.-28": {
    "value": "calc(var(--iap-spacing-28) * -1)",
    "variable": "var(--iap-spacing-28)"
  },
  "spacing.-32": {
    "value": "calc(var(--iap-spacing-32) * -1)",
    "variable": "var(--iap-spacing-32)"
  },
  "spacing.-36": {
    "value": "calc(var(--iap-spacing-36) * -1)",
    "variable": "var(--iap-spacing-36)"
  },
  "spacing.-40": {
    "value": "calc(var(--iap-spacing-40) * -1)",
    "variable": "var(--iap-spacing-40)"
  },
  "spacing.-44": {
    "value": "calc(var(--iap-spacing-44) * -1)",
    "variable": "var(--iap-spacing-44)"
  },
  "spacing.-48": {
    "value": "calc(var(--iap-spacing-48) * -1)",
    "variable": "var(--iap-spacing-48)"
  },
  "spacing.-52": {
    "value": "calc(var(--iap-spacing-52) * -1)",
    "variable": "var(--iap-spacing-52)"
  },
  "spacing.-56": {
    "value": "calc(var(--iap-spacing-56) * -1)",
    "variable": "var(--iap-spacing-56)"
  },
  "spacing.-60": {
    "value": "calc(var(--iap-spacing-60) * -1)",
    "variable": "var(--iap-spacing-60)"
  },
  "spacing.-64": {
    "value": "calc(var(--iap-spacing-64) * -1)",
    "variable": "var(--iap-spacing-64)"
  },
  "spacing.-72": {
    "value": "calc(var(--iap-spacing-72) * -1)",
    "variable": "var(--iap-spacing-72)"
  },
  "spacing.-80": {
    "value": "calc(var(--iap-spacing-80) * -1)",
    "variable": "var(--iap-spacing-80)"
  },
  "spacing.-96": {
    "value": "calc(var(--iap-spacing-96) * -1)",
    "variable": "var(--iap-spacing-96)"
  },
  "spacing.-0.5": {
    "value": "calc(var(--iap-spacing-0\\.5) * -1)",
    "variable": "var(--iap-spacing-0\\.5)"
  },
  "spacing.-1.5": {
    "value": "calc(var(--iap-spacing-1\\.5) * -1)",
    "variable": "var(--iap-spacing-1\\.5)"
  },
  "spacing.-2.5": {
    "value": "calc(var(--iap-spacing-2\\.5) * -1)",
    "variable": "var(--iap-spacing-2\\.5)"
  },
  "spacing.-3.5": {
    "value": "calc(var(--iap-spacing-3\\.5) * -1)",
    "variable": "var(--iap-spacing-3\\.5)"
  },
  "spacing.-4.5": {
    "value": "calc(var(--iap-spacing-4\\.5) * -1)",
    "variable": "var(--iap-spacing-4\\.5)"
  },
  "colors.green.1": {
    "value": "var(--iap-colors-green-1)",
    "variable": "var(--iap-colors-green-1)"
  },
  "colors.green.2": {
    "value": "var(--iap-colors-green-2)",
    "variable": "var(--iap-colors-green-2)"
  },
  "colors.green.3": {
    "value": "var(--iap-colors-green-3)",
    "variable": "var(--iap-colors-green-3)"
  },
  "colors.green.4": {
    "value": "var(--iap-colors-green-4)",
    "variable": "var(--iap-colors-green-4)"
  },
  "colors.green.5": {
    "value": "var(--iap-colors-green-5)",
    "variable": "var(--iap-colors-green-5)"
  },
  "colors.green.6": {
    "value": "var(--iap-colors-green-6)",
    "variable": "var(--iap-colors-green-6)"
  },
  "colors.green.7": {
    "value": "var(--iap-colors-green-7)",
    "variable": "var(--iap-colors-green-7)"
  },
  "colors.green.8": {
    "value": "var(--iap-colors-green-8)",
    "variable": "var(--iap-colors-green-8)"
  },
  "colors.green.9": {
    "value": "var(--iap-colors-green-9)",
    "variable": "var(--iap-colors-green-9)"
  },
  "colors.green.10": {
    "value": "var(--iap-colors-green-10)",
    "variable": "var(--iap-colors-green-10)"
  },
  "colors.green.11": {
    "value": "var(--iap-colors-green-11)",
    "variable": "var(--iap-colors-green-11)"
  },
  "colors.green.12": {
    "value": "var(--iap-colors-green-12)",
    "variable": "var(--iap-colors-green-12)"
  },
  "colors.green.a1": {
    "value": "var(--iap-colors-green-a1)",
    "variable": "var(--iap-colors-green-a1)"
  },
  "colors.green.a2": {
    "value": "var(--iap-colors-green-a2)",
    "variable": "var(--iap-colors-green-a2)"
  },
  "colors.green.a3": {
    "value": "var(--iap-colors-green-a3)",
    "variable": "var(--iap-colors-green-a3)"
  },
  "colors.green.a4": {
    "value": "var(--iap-colors-green-a4)",
    "variable": "var(--iap-colors-green-a4)"
  },
  "colors.green.a5": {
    "value": "var(--iap-colors-green-a5)",
    "variable": "var(--iap-colors-green-a5)"
  },
  "colors.green.a6": {
    "value": "var(--iap-colors-green-a6)",
    "variable": "var(--iap-colors-green-a6)"
  },
  "colors.green.a7": {
    "value": "var(--iap-colors-green-a7)",
    "variable": "var(--iap-colors-green-a7)"
  },
  "colors.green.a8": {
    "value": "var(--iap-colors-green-a8)",
    "variable": "var(--iap-colors-green-a8)"
  },
  "colors.green.a9": {
    "value": "var(--iap-colors-green-a9)",
    "variable": "var(--iap-colors-green-a9)"
  },
  "colors.green.a10": {
    "value": "var(--iap-colors-green-a10)",
    "variable": "var(--iap-colors-green-a10)"
  },
  "colors.green.a11": {
    "value": "var(--iap-colors-green-a11)",
    "variable": "var(--iap-colors-green-a11)"
  },
  "colors.green.a12": {
    "value": "var(--iap-colors-green-a12)",
    "variable": "var(--iap-colors-green-a12)"
  },
  "colors.green.default": {
    "value": "var(--iap-colors-green-default)",
    "variable": "var(--iap-colors-green-default)"
  },
  "colors.green.emphasized": {
    "value": "var(--iap-colors-green-emphasized)",
    "variable": "var(--iap-colors-green-emphasized)"
  },
  "colors.green.fg": {
    "value": "var(--iap-colors-green-fg)",
    "variable": "var(--iap-colors-green-fg)"
  },
  "colors.green.text": {
    "value": "var(--iap-colors-green-text)",
    "variable": "var(--iap-colors-green-text)"
  },
  "colors.neutral.1": {
    "value": "var(--iap-colors-neutral-1)",
    "variable": "var(--iap-colors-neutral-1)"
  },
  "colors.neutral.2": {
    "value": "var(--iap-colors-neutral-2)",
    "variable": "var(--iap-colors-neutral-2)"
  },
  "colors.neutral.3": {
    "value": "var(--iap-colors-neutral-3)",
    "variable": "var(--iap-colors-neutral-3)"
  },
  "colors.neutral.4": {
    "value": "var(--iap-colors-neutral-4)",
    "variable": "var(--iap-colors-neutral-4)"
  },
  "colors.neutral.5": {
    "value": "var(--iap-colors-neutral-5)",
    "variable": "var(--iap-colors-neutral-5)"
  },
  "colors.neutral.6": {
    "value": "var(--iap-colors-neutral-6)",
    "variable": "var(--iap-colors-neutral-6)"
  },
  "colors.neutral.7": {
    "value": "var(--iap-colors-neutral-7)",
    "variable": "var(--iap-colors-neutral-7)"
  },
  "colors.neutral.8": {
    "value": "var(--iap-colors-neutral-8)",
    "variable": "var(--iap-colors-neutral-8)"
  },
  "colors.neutral.9": {
    "value": "var(--iap-colors-neutral-9)",
    "variable": "var(--iap-colors-neutral-9)"
  },
  "colors.neutral.10": {
    "value": "var(--iap-colors-neutral-10)",
    "variable": "var(--iap-colors-neutral-10)"
  },
  "colors.neutral.11": {
    "value": "var(--iap-colors-neutral-11)",
    "variable": "var(--iap-colors-neutral-11)"
  },
  "colors.neutral.12": {
    "value": "var(--iap-colors-neutral-12)",
    "variable": "var(--iap-colors-neutral-12)"
  },
  "colors.neutral.a1": {
    "value": "var(--iap-colors-neutral-a1)",
    "variable": "var(--iap-colors-neutral-a1)"
  },
  "colors.neutral.a2": {
    "value": "var(--iap-colors-neutral-a2)",
    "variable": "var(--iap-colors-neutral-a2)"
  },
  "colors.neutral.a3": {
    "value": "var(--iap-colors-neutral-a3)",
    "variable": "var(--iap-colors-neutral-a3)"
  },
  "colors.neutral.a4": {
    "value": "var(--iap-colors-neutral-a4)",
    "variable": "var(--iap-colors-neutral-a4)"
  },
  "colors.neutral.a5": {
    "value": "var(--iap-colors-neutral-a5)",
    "variable": "var(--iap-colors-neutral-a5)"
  },
  "colors.neutral.a6": {
    "value": "var(--iap-colors-neutral-a6)",
    "variable": "var(--iap-colors-neutral-a6)"
  },
  "colors.neutral.a7": {
    "value": "var(--iap-colors-neutral-a7)",
    "variable": "var(--iap-colors-neutral-a7)"
  },
  "colors.neutral.a8": {
    "value": "var(--iap-colors-neutral-a8)",
    "variable": "var(--iap-colors-neutral-a8)"
  },
  "colors.neutral.a9": {
    "value": "var(--iap-colors-neutral-a9)",
    "variable": "var(--iap-colors-neutral-a9)"
  },
  "colors.neutral.a10": {
    "value": "var(--iap-colors-neutral-a10)",
    "variable": "var(--iap-colors-neutral-a10)"
  },
  "colors.neutral.a11": {
    "value": "var(--iap-colors-neutral-a11)",
    "variable": "var(--iap-colors-neutral-a11)"
  },
  "colors.neutral.a12": {
    "value": "var(--iap-colors-neutral-a12)",
    "variable": "var(--iap-colors-neutral-a12)"
  },
  "colors.neutral.default": {
    "value": "var(--iap-colors-neutral-default)",
    "variable": "var(--iap-colors-neutral-default)"
  },
  "colors.neutral.emphasized": {
    "value": "var(--iap-colors-neutral-emphasized)",
    "variable": "var(--iap-colors-neutral-emphasized)"
  },
  "colors.neutral.fg": {
    "value": "var(--iap-colors-neutral-fg)",
    "variable": "var(--iap-colors-neutral-fg)"
  },
  "colors.neutral.text": {
    "value": "var(--iap-colors-neutral-text)",
    "variable": "var(--iap-colors-neutral-text)"
  },
  "colors.red.1": {
    "value": "var(--iap-colors-red-1)",
    "variable": "var(--iap-colors-red-1)"
  },
  "colors.red.2": {
    "value": "var(--iap-colors-red-2)",
    "variable": "var(--iap-colors-red-2)"
  },
  "colors.red.3": {
    "value": "var(--iap-colors-red-3)",
    "variable": "var(--iap-colors-red-3)"
  },
  "colors.red.4": {
    "value": "var(--iap-colors-red-4)",
    "variable": "var(--iap-colors-red-4)"
  },
  "colors.red.5": {
    "value": "var(--iap-colors-red-5)",
    "variable": "var(--iap-colors-red-5)"
  },
  "colors.red.6": {
    "value": "var(--iap-colors-red-6)",
    "variable": "var(--iap-colors-red-6)"
  },
  "colors.red.7": {
    "value": "var(--iap-colors-red-7)",
    "variable": "var(--iap-colors-red-7)"
  },
  "colors.red.8": {
    "value": "var(--iap-colors-red-8)",
    "variable": "var(--iap-colors-red-8)"
  },
  "colors.red.9": {
    "value": "var(--iap-colors-red-9)",
    "variable": "var(--iap-colors-red-9)"
  },
  "colors.red.10": {
    "value": "var(--iap-colors-red-10)",
    "variable": "var(--iap-colors-red-10)"
  },
  "colors.red.11": {
    "value": "var(--iap-colors-red-11)",
    "variable": "var(--iap-colors-red-11)"
  },
  "colors.red.12": {
    "value": "var(--iap-colors-red-12)",
    "variable": "var(--iap-colors-red-12)"
  },
  "colors.red.a1": {
    "value": "var(--iap-colors-red-a1)",
    "variable": "var(--iap-colors-red-a1)"
  },
  "colors.red.a2": {
    "value": "var(--iap-colors-red-a2)",
    "variable": "var(--iap-colors-red-a2)"
  },
  "colors.red.a3": {
    "value": "var(--iap-colors-red-a3)",
    "variable": "var(--iap-colors-red-a3)"
  },
  "colors.red.a4": {
    "value": "var(--iap-colors-red-a4)",
    "variable": "var(--iap-colors-red-a4)"
  },
  "colors.red.a5": {
    "value": "var(--iap-colors-red-a5)",
    "variable": "var(--iap-colors-red-a5)"
  },
  "colors.red.a6": {
    "value": "var(--iap-colors-red-a6)",
    "variable": "var(--iap-colors-red-a6)"
  },
  "colors.red.a7": {
    "value": "var(--iap-colors-red-a7)",
    "variable": "var(--iap-colors-red-a7)"
  },
  "colors.red.a8": {
    "value": "var(--iap-colors-red-a8)",
    "variable": "var(--iap-colors-red-a8)"
  },
  "colors.red.a9": {
    "value": "var(--iap-colors-red-a9)",
    "variable": "var(--iap-colors-red-a9)"
  },
  "colors.red.a10": {
    "value": "var(--iap-colors-red-a10)",
    "variable": "var(--iap-colors-red-a10)"
  },
  "colors.red.a11": {
    "value": "var(--iap-colors-red-a11)",
    "variable": "var(--iap-colors-red-a11)"
  },
  "colors.red.a12": {
    "value": "var(--iap-colors-red-a12)",
    "variable": "var(--iap-colors-red-a12)"
  },
  "colors.red.default": {
    "value": "var(--iap-colors-red-default)",
    "variable": "var(--iap-colors-red-default)"
  },
  "colors.red.emphasized": {
    "value": "var(--iap-colors-red-emphasized)",
    "variable": "var(--iap-colors-red-emphasized)"
  },
  "colors.red.fg": {
    "value": "var(--iap-colors-red-fg)",
    "variable": "var(--iap-colors-red-fg)"
  },
  "colors.red.text": {
    "value": "var(--iap-colors-red-text)",
    "variable": "var(--iap-colors-red-text)"
  },
  "colors.sand.1": {
    "value": "var(--iap-colors-sand-1)",
    "variable": "var(--iap-colors-sand-1)"
  },
  "colors.sand.2": {
    "value": "var(--iap-colors-sand-2)",
    "variable": "var(--iap-colors-sand-2)"
  },
  "colors.sand.3": {
    "value": "var(--iap-colors-sand-3)",
    "variable": "var(--iap-colors-sand-3)"
  },
  "colors.sand.4": {
    "value": "var(--iap-colors-sand-4)",
    "variable": "var(--iap-colors-sand-4)"
  },
  "colors.sand.5": {
    "value": "var(--iap-colors-sand-5)",
    "variable": "var(--iap-colors-sand-5)"
  },
  "colors.sand.6": {
    "value": "var(--iap-colors-sand-6)",
    "variable": "var(--iap-colors-sand-6)"
  },
  "colors.sand.7": {
    "value": "var(--iap-colors-sand-7)",
    "variable": "var(--iap-colors-sand-7)"
  },
  "colors.sand.8": {
    "value": "var(--iap-colors-sand-8)",
    "variable": "var(--iap-colors-sand-8)"
  },
  "colors.sand.9": {
    "value": "var(--iap-colors-sand-9)",
    "variable": "var(--iap-colors-sand-9)"
  },
  "colors.sand.10": {
    "value": "var(--iap-colors-sand-10)",
    "variable": "var(--iap-colors-sand-10)"
  },
  "colors.sand.11": {
    "value": "var(--iap-colors-sand-11)",
    "variable": "var(--iap-colors-sand-11)"
  },
  "colors.sand.12": {
    "value": "var(--iap-colors-sand-12)",
    "variable": "var(--iap-colors-sand-12)"
  },
  "colors.sand.a1": {
    "value": "var(--iap-colors-sand-a1)",
    "variable": "var(--iap-colors-sand-a1)"
  },
  "colors.sand.a2": {
    "value": "var(--iap-colors-sand-a2)",
    "variable": "var(--iap-colors-sand-a2)"
  },
  "colors.sand.a3": {
    "value": "var(--iap-colors-sand-a3)",
    "variable": "var(--iap-colors-sand-a3)"
  },
  "colors.sand.a4": {
    "value": "var(--iap-colors-sand-a4)",
    "variable": "var(--iap-colors-sand-a4)"
  },
  "colors.sand.a5": {
    "value": "var(--iap-colors-sand-a5)",
    "variable": "var(--iap-colors-sand-a5)"
  },
  "colors.sand.a6": {
    "value": "var(--iap-colors-sand-a6)",
    "variable": "var(--iap-colors-sand-a6)"
  },
  "colors.sand.a7": {
    "value": "var(--iap-colors-sand-a7)",
    "variable": "var(--iap-colors-sand-a7)"
  },
  "colors.sand.a8": {
    "value": "var(--iap-colors-sand-a8)",
    "variable": "var(--iap-colors-sand-a8)"
  },
  "colors.sand.a9": {
    "value": "var(--iap-colors-sand-a9)",
    "variable": "var(--iap-colors-sand-a9)"
  },
  "colors.sand.a10": {
    "value": "var(--iap-colors-sand-a10)",
    "variable": "var(--iap-colors-sand-a10)"
  },
  "colors.sand.a11": {
    "value": "var(--iap-colors-sand-a11)",
    "variable": "var(--iap-colors-sand-a11)"
  },
  "colors.sand.a12": {
    "value": "var(--iap-colors-sand-a12)",
    "variable": "var(--iap-colors-sand-a12)"
  },
  "colors.sand.default": {
    "value": "var(--iap-colors-sand-default)",
    "variable": "var(--iap-colors-sand-default)"
  },
  "colors.sand.emphasized": {
    "value": "var(--iap-colors-sand-emphasized)",
    "variable": "var(--iap-colors-sand-emphasized)"
  },
  "colors.sand.fg": {
    "value": "var(--iap-colors-sand-fg)",
    "variable": "var(--iap-colors-sand-fg)"
  },
  "colors.sand.text": {
    "value": "var(--iap-colors-sand-text)",
    "variable": "var(--iap-colors-sand-text)"
  },
  "colors.slate.1": {
    "value": "var(--iap-colors-slate-1)",
    "variable": "var(--iap-colors-slate-1)"
  },
  "colors.slate.2": {
    "value": "var(--iap-colors-slate-2)",
    "variable": "var(--iap-colors-slate-2)"
  },
  "colors.slate.3": {
    "value": "var(--iap-colors-slate-3)",
    "variable": "var(--iap-colors-slate-3)"
  },
  "colors.slate.4": {
    "value": "var(--iap-colors-slate-4)",
    "variable": "var(--iap-colors-slate-4)"
  },
  "colors.slate.5": {
    "value": "var(--iap-colors-slate-5)",
    "variable": "var(--iap-colors-slate-5)"
  },
  "colors.slate.6": {
    "value": "var(--iap-colors-slate-6)",
    "variable": "var(--iap-colors-slate-6)"
  },
  "colors.slate.7": {
    "value": "var(--iap-colors-slate-7)",
    "variable": "var(--iap-colors-slate-7)"
  },
  "colors.slate.8": {
    "value": "var(--iap-colors-slate-8)",
    "variable": "var(--iap-colors-slate-8)"
  },
  "colors.slate.9": {
    "value": "var(--iap-colors-slate-9)",
    "variable": "var(--iap-colors-slate-9)"
  },
  "colors.slate.10": {
    "value": "var(--iap-colors-slate-10)",
    "variable": "var(--iap-colors-slate-10)"
  },
  "colors.slate.11": {
    "value": "var(--iap-colors-slate-11)",
    "variable": "var(--iap-colors-slate-11)"
  },
  "colors.slate.12": {
    "value": "var(--iap-colors-slate-12)",
    "variable": "var(--iap-colors-slate-12)"
  },
  "colors.slate.a1": {
    "value": "var(--iap-colors-slate-a1)",
    "variable": "var(--iap-colors-slate-a1)"
  },
  "colors.slate.a2": {
    "value": "var(--iap-colors-slate-a2)",
    "variable": "var(--iap-colors-slate-a2)"
  },
  "colors.slate.a3": {
    "value": "var(--iap-colors-slate-a3)",
    "variable": "var(--iap-colors-slate-a3)"
  },
  "colors.slate.a4": {
    "value": "var(--iap-colors-slate-a4)",
    "variable": "var(--iap-colors-slate-a4)"
  },
  "colors.slate.a5": {
    "value": "var(--iap-colors-slate-a5)",
    "variable": "var(--iap-colors-slate-a5)"
  },
  "colors.slate.a6": {
    "value": "var(--iap-colors-slate-a6)",
    "variable": "var(--iap-colors-slate-a6)"
  },
  "colors.slate.a7": {
    "value": "var(--iap-colors-slate-a7)",
    "variable": "var(--iap-colors-slate-a7)"
  },
  "colors.slate.a8": {
    "value": "var(--iap-colors-slate-a8)",
    "variable": "var(--iap-colors-slate-a8)"
  },
  "colors.slate.a9": {
    "value": "var(--iap-colors-slate-a9)",
    "variable": "var(--iap-colors-slate-a9)"
  },
  "colors.slate.a10": {
    "value": "var(--iap-colors-slate-a10)",
    "variable": "var(--iap-colors-slate-a10)"
  },
  "colors.slate.a11": {
    "value": "var(--iap-colors-slate-a11)",
    "variable": "var(--iap-colors-slate-a11)"
  },
  "colors.slate.a12": {
    "value": "var(--iap-colors-slate-a12)",
    "variable": "var(--iap-colors-slate-a12)"
  },
  "colors.slate.default": {
    "value": "var(--iap-colors-slate-default)",
    "variable": "var(--iap-colors-slate-default)"
  },
  "colors.slate.emphasized": {
    "value": "var(--iap-colors-slate-emphasized)",
    "variable": "var(--iap-colors-slate-emphasized)"
  },
  "colors.slate.fg": {
    "value": "var(--iap-colors-slate-fg)",
    "variable": "var(--iap-colors-slate-fg)"
  },
  "colors.slate.text": {
    "value": "var(--iap-colors-slate-text)",
    "variable": "var(--iap-colors-slate-text)"
  },
  "colors.tomato.1": {
    "value": "var(--iap-colors-tomato-1)",
    "variable": "var(--iap-colors-tomato-1)"
  },
  "colors.tomato.2": {
    "value": "var(--iap-colors-tomato-2)",
    "variable": "var(--iap-colors-tomato-2)"
  },
  "colors.tomato.3": {
    "value": "var(--iap-colors-tomato-3)",
    "variable": "var(--iap-colors-tomato-3)"
  },
  "colors.tomato.4": {
    "value": "var(--iap-colors-tomato-4)",
    "variable": "var(--iap-colors-tomato-4)"
  },
  "colors.tomato.5": {
    "value": "var(--iap-colors-tomato-5)",
    "variable": "var(--iap-colors-tomato-5)"
  },
  "colors.tomato.6": {
    "value": "var(--iap-colors-tomato-6)",
    "variable": "var(--iap-colors-tomato-6)"
  },
  "colors.tomato.7": {
    "value": "var(--iap-colors-tomato-7)",
    "variable": "var(--iap-colors-tomato-7)"
  },
  "colors.tomato.8": {
    "value": "var(--iap-colors-tomato-8)",
    "variable": "var(--iap-colors-tomato-8)"
  },
  "colors.tomato.9": {
    "value": "var(--iap-colors-tomato-9)",
    "variable": "var(--iap-colors-tomato-9)"
  },
  "colors.tomato.10": {
    "value": "var(--iap-colors-tomato-10)",
    "variable": "var(--iap-colors-tomato-10)"
  },
  "colors.tomato.11": {
    "value": "var(--iap-colors-tomato-11)",
    "variable": "var(--iap-colors-tomato-11)"
  },
  "colors.tomato.12": {
    "value": "var(--iap-colors-tomato-12)",
    "variable": "var(--iap-colors-tomato-12)"
  },
  "colors.tomato.a1": {
    "value": "var(--iap-colors-tomato-a1)",
    "variable": "var(--iap-colors-tomato-a1)"
  },
  "colors.tomato.a2": {
    "value": "var(--iap-colors-tomato-a2)",
    "variable": "var(--iap-colors-tomato-a2)"
  },
  "colors.tomato.a3": {
    "value": "var(--iap-colors-tomato-a3)",
    "variable": "var(--iap-colors-tomato-a3)"
  },
  "colors.tomato.a4": {
    "value": "var(--iap-colors-tomato-a4)",
    "variable": "var(--iap-colors-tomato-a4)"
  },
  "colors.tomato.a5": {
    "value": "var(--iap-colors-tomato-a5)",
    "variable": "var(--iap-colors-tomato-a5)"
  },
  "colors.tomato.a6": {
    "value": "var(--iap-colors-tomato-a6)",
    "variable": "var(--iap-colors-tomato-a6)"
  },
  "colors.tomato.a7": {
    "value": "var(--iap-colors-tomato-a7)",
    "variable": "var(--iap-colors-tomato-a7)"
  },
  "colors.tomato.a8": {
    "value": "var(--iap-colors-tomato-a8)",
    "variable": "var(--iap-colors-tomato-a8)"
  },
  "colors.tomato.a9": {
    "value": "var(--iap-colors-tomato-a9)",
    "variable": "var(--iap-colors-tomato-a9)"
  },
  "colors.tomato.a10": {
    "value": "var(--iap-colors-tomato-a10)",
    "variable": "var(--iap-colors-tomato-a10)"
  },
  "colors.tomato.a11": {
    "value": "var(--iap-colors-tomato-a11)",
    "variable": "var(--iap-colors-tomato-a11)"
  },
  "colors.tomato.a12": {
    "value": "var(--iap-colors-tomato-a12)",
    "variable": "var(--iap-colors-tomato-a12)"
  },
  "colors.tomato.default": {
    "value": "var(--iap-colors-tomato-default)",
    "variable": "var(--iap-colors-tomato-default)"
  },
  "colors.tomato.emphasized": {
    "value": "var(--iap-colors-tomato-emphasized)",
    "variable": "var(--iap-colors-tomato-emphasized)"
  },
  "colors.tomato.fg": {
    "value": "var(--iap-colors-tomato-fg)",
    "variable": "var(--iap-colors-tomato-fg)"
  },
  "colors.tomato.text": {
    "value": "var(--iap-colors-tomato-text)",
    "variable": "var(--iap-colors-tomato-text)"
  },
  "colors.yellow.1": {
    "value": "var(--iap-colors-yellow-1)",
    "variable": "var(--iap-colors-yellow-1)"
  },
  "colors.yellow.2": {
    "value": "var(--iap-colors-yellow-2)",
    "variable": "var(--iap-colors-yellow-2)"
  },
  "colors.yellow.3": {
    "value": "var(--iap-colors-yellow-3)",
    "variable": "var(--iap-colors-yellow-3)"
  },
  "colors.yellow.4": {
    "value": "var(--iap-colors-yellow-4)",
    "variable": "var(--iap-colors-yellow-4)"
  },
  "colors.yellow.5": {
    "value": "var(--iap-colors-yellow-5)",
    "variable": "var(--iap-colors-yellow-5)"
  },
  "colors.yellow.6": {
    "value": "var(--iap-colors-yellow-6)",
    "variable": "var(--iap-colors-yellow-6)"
  },
  "colors.yellow.7": {
    "value": "var(--iap-colors-yellow-7)",
    "variable": "var(--iap-colors-yellow-7)"
  },
  "colors.yellow.8": {
    "value": "var(--iap-colors-yellow-8)",
    "variable": "var(--iap-colors-yellow-8)"
  },
  "colors.yellow.9": {
    "value": "var(--iap-colors-yellow-9)",
    "variable": "var(--iap-colors-yellow-9)"
  },
  "colors.yellow.10": {
    "value": "var(--iap-colors-yellow-10)",
    "variable": "var(--iap-colors-yellow-10)"
  },
  "colors.yellow.11": {
    "value": "var(--iap-colors-yellow-11)",
    "variable": "var(--iap-colors-yellow-11)"
  },
  "colors.yellow.12": {
    "value": "var(--iap-colors-yellow-12)",
    "variable": "var(--iap-colors-yellow-12)"
  },
  "colors.yellow.a1": {
    "value": "var(--iap-colors-yellow-a1)",
    "variable": "var(--iap-colors-yellow-a1)"
  },
  "colors.yellow.a2": {
    "value": "var(--iap-colors-yellow-a2)",
    "variable": "var(--iap-colors-yellow-a2)"
  },
  "colors.yellow.a3": {
    "value": "var(--iap-colors-yellow-a3)",
    "variable": "var(--iap-colors-yellow-a3)"
  },
  "colors.yellow.a4": {
    "value": "var(--iap-colors-yellow-a4)",
    "variable": "var(--iap-colors-yellow-a4)"
  },
  "colors.yellow.a5": {
    "value": "var(--iap-colors-yellow-a5)",
    "variable": "var(--iap-colors-yellow-a5)"
  },
  "colors.yellow.a6": {
    "value": "var(--iap-colors-yellow-a6)",
    "variable": "var(--iap-colors-yellow-a6)"
  },
  "colors.yellow.a7": {
    "value": "var(--iap-colors-yellow-a7)",
    "variable": "var(--iap-colors-yellow-a7)"
  },
  "colors.yellow.a8": {
    "value": "var(--iap-colors-yellow-a8)",
    "variable": "var(--iap-colors-yellow-a8)"
  },
  "colors.yellow.a9": {
    "value": "var(--iap-colors-yellow-a9)",
    "variable": "var(--iap-colors-yellow-a9)"
  },
  "colors.yellow.a10": {
    "value": "var(--iap-colors-yellow-a10)",
    "variable": "var(--iap-colors-yellow-a10)"
  },
  "colors.yellow.a11": {
    "value": "var(--iap-colors-yellow-a11)",
    "variable": "var(--iap-colors-yellow-a11)"
  },
  "colors.yellow.a12": {
    "value": "var(--iap-colors-yellow-a12)",
    "variable": "var(--iap-colors-yellow-a12)"
  },
  "colors.yellow.default": {
    "value": "var(--iap-colors-yellow-default)",
    "variable": "var(--iap-colors-yellow-default)"
  },
  "colors.yellow.emphasized": {
    "value": "var(--iap-colors-yellow-emphasized)",
    "variable": "var(--iap-colors-yellow-emphasized)"
  },
  "colors.yellow.fg": {
    "value": "var(--iap-colors-yellow-fg)",
    "variable": "var(--iap-colors-yellow-fg)"
  },
  "colors.yellow.text": {
    "value": "var(--iap-colors-yellow-text)",
    "variable": "var(--iap-colors-yellow-text)"
  },
  "colors.gray.1": {
    "value": "var(--iap-colors-gray-1)",
    "variable": "var(--iap-colors-gray-1)"
  },
  "colors.gray.2": {
    "value": "var(--iap-colors-gray-2)",
    "variable": "var(--iap-colors-gray-2)"
  },
  "colors.gray.3": {
    "value": "var(--iap-colors-gray-3)",
    "variable": "var(--iap-colors-gray-3)"
  },
  "colors.gray.4": {
    "value": "var(--iap-colors-gray-4)",
    "variable": "var(--iap-colors-gray-4)"
  },
  "colors.gray.5": {
    "value": "var(--iap-colors-gray-5)",
    "variable": "var(--iap-colors-gray-5)"
  },
  "colors.gray.6": {
    "value": "var(--iap-colors-gray-6)",
    "variable": "var(--iap-colors-gray-6)"
  },
  "colors.gray.7": {
    "value": "var(--iap-colors-gray-7)",
    "variable": "var(--iap-colors-gray-7)"
  },
  "colors.gray.8": {
    "value": "var(--iap-colors-gray-8)",
    "variable": "var(--iap-colors-gray-8)"
  },
  "colors.gray.9": {
    "value": "var(--iap-colors-gray-9)",
    "variable": "var(--iap-colors-gray-9)"
  },
  "colors.gray.10": {
    "value": "var(--iap-colors-gray-10)",
    "variable": "var(--iap-colors-gray-10)"
  },
  "colors.gray.11": {
    "value": "var(--iap-colors-gray-11)",
    "variable": "var(--iap-colors-gray-11)"
  },
  "colors.gray.12": {
    "value": "var(--iap-colors-gray-12)",
    "variable": "var(--iap-colors-gray-12)"
  },
  "colors.gray.a1": {
    "value": "var(--iap-colors-gray-a1)",
    "variable": "var(--iap-colors-gray-a1)"
  },
  "colors.gray.a2": {
    "value": "var(--iap-colors-gray-a2)",
    "variable": "var(--iap-colors-gray-a2)"
  },
  "colors.gray.a3": {
    "value": "var(--iap-colors-gray-a3)",
    "variable": "var(--iap-colors-gray-a3)"
  },
  "colors.gray.a4": {
    "value": "var(--iap-colors-gray-a4)",
    "variable": "var(--iap-colors-gray-a4)"
  },
  "colors.gray.a5": {
    "value": "var(--iap-colors-gray-a5)",
    "variable": "var(--iap-colors-gray-a5)"
  },
  "colors.gray.a6": {
    "value": "var(--iap-colors-gray-a6)",
    "variable": "var(--iap-colors-gray-a6)"
  },
  "colors.gray.a7": {
    "value": "var(--iap-colors-gray-a7)",
    "variable": "var(--iap-colors-gray-a7)"
  },
  "colors.gray.a8": {
    "value": "var(--iap-colors-gray-a8)",
    "variable": "var(--iap-colors-gray-a8)"
  },
  "colors.gray.a9": {
    "value": "var(--iap-colors-gray-a9)",
    "variable": "var(--iap-colors-gray-a9)"
  },
  "colors.gray.a10": {
    "value": "var(--iap-colors-gray-a10)",
    "variable": "var(--iap-colors-gray-a10)"
  },
  "colors.gray.a11": {
    "value": "var(--iap-colors-gray-a11)",
    "variable": "var(--iap-colors-gray-a11)"
  },
  "colors.gray.a12": {
    "value": "var(--iap-colors-gray-a12)",
    "variable": "var(--iap-colors-gray-a12)"
  },
  "colors.gray.default": {
    "value": "var(--iap-colors-gray-default)",
    "variable": "var(--iap-colors-gray-default)"
  },
  "colors.gray.emphasized": {
    "value": "var(--iap-colors-gray-emphasized)",
    "variable": "var(--iap-colors-gray-emphasized)"
  },
  "colors.gray.fg": {
    "value": "var(--iap-colors-gray-fg)",
    "variable": "var(--iap-colors-gray-fg)"
  },
  "colors.gray.text": {
    "value": "var(--iap-colors-gray-text)",
    "variable": "var(--iap-colors-gray-text)"
  },
  "colors.accent.1": {
    "value": "var(--iap-colors-accent-1)",
    "variable": "var(--iap-colors-accent-1)"
  },
  "colors.accent.2": {
    "value": "var(--iap-colors-accent-2)",
    "variable": "var(--iap-colors-accent-2)"
  },
  "colors.accent.3": {
    "value": "var(--iap-colors-accent-3)",
    "variable": "var(--iap-colors-accent-3)"
  },
  "colors.accent.4": {
    "value": "var(--iap-colors-accent-4)",
    "variable": "var(--iap-colors-accent-4)"
  },
  "colors.accent.5": {
    "value": "var(--iap-colors-accent-5)",
    "variable": "var(--iap-colors-accent-5)"
  },
  "colors.accent.6": {
    "value": "var(--iap-colors-accent-6)",
    "variable": "var(--iap-colors-accent-6)"
  },
  "colors.accent.7": {
    "value": "var(--iap-colors-accent-7)",
    "variable": "var(--iap-colors-accent-7)"
  },
  "colors.accent.8": {
    "value": "var(--iap-colors-accent-8)",
    "variable": "var(--iap-colors-accent-8)"
  },
  "colors.accent.9": {
    "value": "var(--iap-colors-accent-9)",
    "variable": "var(--iap-colors-accent-9)"
  },
  "colors.accent.10": {
    "value": "var(--iap-colors-accent-10)",
    "variable": "var(--iap-colors-accent-10)"
  },
  "colors.accent.11": {
    "value": "var(--iap-colors-accent-11)",
    "variable": "var(--iap-colors-accent-11)"
  },
  "colors.accent.12": {
    "value": "var(--iap-colors-accent-12)",
    "variable": "var(--iap-colors-accent-12)"
  },
  "colors.accent.a1": {
    "value": "var(--iap-colors-accent-a1)",
    "variable": "var(--iap-colors-accent-a1)"
  },
  "colors.accent.a2": {
    "value": "var(--iap-colors-accent-a2)",
    "variable": "var(--iap-colors-accent-a2)"
  },
  "colors.accent.a3": {
    "value": "var(--iap-colors-accent-a3)",
    "variable": "var(--iap-colors-accent-a3)"
  },
  "colors.accent.a4": {
    "value": "var(--iap-colors-accent-a4)",
    "variable": "var(--iap-colors-accent-a4)"
  },
  "colors.accent.a5": {
    "value": "var(--iap-colors-accent-a5)",
    "variable": "var(--iap-colors-accent-a5)"
  },
  "colors.accent.a6": {
    "value": "var(--iap-colors-accent-a6)",
    "variable": "var(--iap-colors-accent-a6)"
  },
  "colors.accent.a7": {
    "value": "var(--iap-colors-accent-a7)",
    "variable": "var(--iap-colors-accent-a7)"
  },
  "colors.accent.a8": {
    "value": "var(--iap-colors-accent-a8)",
    "variable": "var(--iap-colors-accent-a8)"
  },
  "colors.accent.a9": {
    "value": "var(--iap-colors-accent-a9)",
    "variable": "var(--iap-colors-accent-a9)"
  },
  "colors.accent.a10": {
    "value": "var(--iap-colors-accent-a10)",
    "variable": "var(--iap-colors-accent-a10)"
  },
  "colors.accent.a11": {
    "value": "var(--iap-colors-accent-a11)",
    "variable": "var(--iap-colors-accent-a11)"
  },
  "colors.accent.a12": {
    "value": "var(--iap-colors-accent-a12)",
    "variable": "var(--iap-colors-accent-a12)"
  },
  "colors.accent.default": {
    "value": "var(--iap-colors-accent-default)",
    "variable": "var(--iap-colors-accent-default)"
  },
  "colors.accent.emphasized": {
    "value": "var(--iap-colors-accent-emphasized)",
    "variable": "var(--iap-colors-accent-emphasized)"
  },
  "colors.accent.fg": {
    "value": "var(--iap-colors-accent-fg)",
    "variable": "var(--iap-colors-accent-fg)"
  },
  "colors.accent.text": {
    "value": "var(--iap-colors-accent-text)",
    "variable": "var(--iap-colors-accent-text)"
  },
  "colors.bg.canvas": {
    "value": "var(--iap-colors-bg-canvas)",
    "variable": "var(--iap-colors-bg-canvas)"
  },
  "colors.bg.default": {
    "value": "var(--iap-colors-bg-default)",
    "variable": "var(--iap-colors-bg-default)"
  },
  "colors.bg.subtle": {
    "value": "var(--iap-colors-bg-subtle)",
    "variable": "var(--iap-colors-bg-subtle)"
  },
  "colors.bg.muted": {
    "value": "var(--iap-colors-bg-muted)",
    "variable": "var(--iap-colors-bg-muted)"
  },
  "colors.bg.emphasized": {
    "value": "var(--iap-colors-bg-emphasized)",
    "variable": "var(--iap-colors-bg-emphasized)"
  },
  "colors.bg.disabled": {
    "value": "var(--iap-colors-bg-disabled)",
    "variable": "var(--iap-colors-bg-disabled)"
  },
  "colors.fg.default": {
    "value": "var(--iap-colors-fg-default)",
    "variable": "var(--iap-colors-fg-default)"
  },
  "colors.fg.muted": {
    "value": "var(--iap-colors-fg-muted)",
    "variable": "var(--iap-colors-fg-muted)"
  },
  "colors.fg.subtle": {
    "value": "var(--iap-colors-fg-subtle)",
    "variable": "var(--iap-colors-fg-subtle)"
  },
  "colors.fg.disabled": {
    "value": "var(--iap-colors-fg-disabled)",
    "variable": "var(--iap-colors-fg-disabled)"
  },
  "colors.fg.error": {
    "value": "var(--iap-colors-fg-error)",
    "variable": "var(--iap-colors-fg-error)"
  },
  "colors.border.default": {
    "value": "var(--iap-colors-border-default)",
    "variable": "var(--iap-colors-border-default)"
  },
  "colors.border.muted": {
    "value": "var(--iap-colors-border-muted)",
    "variable": "var(--iap-colors-border-muted)"
  },
  "colors.border.subtle": {
    "value": "var(--iap-colors-border-subtle)",
    "variable": "var(--iap-colors-border-subtle)"
  },
  "colors.border.disabled": {
    "value": "var(--iap-colors-border-disabled)",
    "variable": "var(--iap-colors-border-disabled)"
  },
  "colors.border.outline": {
    "value": "var(--iap-colors-border-outline)",
    "variable": "var(--iap-colors-border-outline)"
  },
  "colors.border.error": {
    "value": "var(--iap-colors-border-error)",
    "variable": "var(--iap-colors-border-error)"
  },
  "shadows.xs": {
    "value": "var(--iap-shadows-xs)",
    "variable": "var(--iap-shadows-xs)"
  },
  "shadows.sm": {
    "value": "var(--iap-shadows-sm)",
    "variable": "var(--iap-shadows-sm)"
  },
  "shadows.md": {
    "value": "var(--iap-shadows-md)",
    "variable": "var(--iap-shadows-md)"
  },
  "shadows.lg": {
    "value": "var(--iap-shadows-lg)",
    "variable": "var(--iap-shadows-lg)"
  },
  "shadows.xl": {
    "value": "var(--iap-shadows-xl)",
    "variable": "var(--iap-shadows-xl)"
  },
  "shadows.2xl": {
    "value": "var(--iap-shadows-2xl)",
    "variable": "var(--iap-shadows-2xl)"
  },
  "colors.colorPalette": {
    "value": "var(--iap-colors-color-palette)",
    "variable": "var(--iap-colors-color-palette)"
  },
  "colors.colorPalette.a1": {
    "value": "var(--iap-colors-color-palette-a1)",
    "variable": "var(--iap-colors-color-palette-a1)"
  },
  "colors.colorPalette.a2": {
    "value": "var(--iap-colors-color-palette-a2)",
    "variable": "var(--iap-colors-color-palette-a2)"
  },
  "colors.colorPalette.a3": {
    "value": "var(--iap-colors-color-palette-a3)",
    "variable": "var(--iap-colors-color-palette-a3)"
  },
  "colors.colorPalette.a4": {
    "value": "var(--iap-colors-color-palette-a4)",
    "variable": "var(--iap-colors-color-palette-a4)"
  },
  "colors.colorPalette.a5": {
    "value": "var(--iap-colors-color-palette-a5)",
    "variable": "var(--iap-colors-color-palette-a5)"
  },
  "colors.colorPalette.a6": {
    "value": "var(--iap-colors-color-palette-a6)",
    "variable": "var(--iap-colors-color-palette-a6)"
  },
  "colors.colorPalette.a7": {
    "value": "var(--iap-colors-color-palette-a7)",
    "variable": "var(--iap-colors-color-palette-a7)"
  },
  "colors.colorPalette.a8": {
    "value": "var(--iap-colors-color-palette-a8)",
    "variable": "var(--iap-colors-color-palette-a8)"
  },
  "colors.colorPalette.a9": {
    "value": "var(--iap-colors-color-palette-a9)",
    "variable": "var(--iap-colors-color-palette-a9)"
  },
  "colors.colorPalette.a10": {
    "value": "var(--iap-colors-color-palette-a10)",
    "variable": "var(--iap-colors-color-palette-a10)"
  },
  "colors.colorPalette.a11": {
    "value": "var(--iap-colors-color-palette-a11)",
    "variable": "var(--iap-colors-color-palette-a11)"
  },
  "colors.colorPalette.a12": {
    "value": "var(--iap-colors-color-palette-a12)",
    "variable": "var(--iap-colors-color-palette-a12)"
  },
  "colors.colorPalette.light.1": {
    "value": "var(--iap-colors-color-palette-light-1)",
    "variable": "var(--iap-colors-color-palette-light-1)"
  },
  "colors.colorPalette.1": {
    "value": "var(--iap-colors-color-palette-1)",
    "variable": "var(--iap-colors-color-palette-1)"
  },
  "colors.colorPalette.light.2": {
    "value": "var(--iap-colors-color-palette-light-2)",
    "variable": "var(--iap-colors-color-palette-light-2)"
  },
  "colors.colorPalette.2": {
    "value": "var(--iap-colors-color-palette-2)",
    "variable": "var(--iap-colors-color-palette-2)"
  },
  "colors.colorPalette.light.3": {
    "value": "var(--iap-colors-color-palette-light-3)",
    "variable": "var(--iap-colors-color-palette-light-3)"
  },
  "colors.colorPalette.3": {
    "value": "var(--iap-colors-color-palette-3)",
    "variable": "var(--iap-colors-color-palette-3)"
  },
  "colors.colorPalette.light.4": {
    "value": "var(--iap-colors-color-palette-light-4)",
    "variable": "var(--iap-colors-color-palette-light-4)"
  },
  "colors.colorPalette.4": {
    "value": "var(--iap-colors-color-palette-4)",
    "variable": "var(--iap-colors-color-palette-4)"
  },
  "colors.colorPalette.light.5": {
    "value": "var(--iap-colors-color-palette-light-5)",
    "variable": "var(--iap-colors-color-palette-light-5)"
  },
  "colors.colorPalette.5": {
    "value": "var(--iap-colors-color-palette-5)",
    "variable": "var(--iap-colors-color-palette-5)"
  },
  "colors.colorPalette.light.6": {
    "value": "var(--iap-colors-color-palette-light-6)",
    "variable": "var(--iap-colors-color-palette-light-6)"
  },
  "colors.colorPalette.6": {
    "value": "var(--iap-colors-color-palette-6)",
    "variable": "var(--iap-colors-color-palette-6)"
  },
  "colors.colorPalette.light.7": {
    "value": "var(--iap-colors-color-palette-light-7)",
    "variable": "var(--iap-colors-color-palette-light-7)"
  },
  "colors.colorPalette.7": {
    "value": "var(--iap-colors-color-palette-7)",
    "variable": "var(--iap-colors-color-palette-7)"
  },
  "colors.colorPalette.light.8": {
    "value": "var(--iap-colors-color-palette-light-8)",
    "variable": "var(--iap-colors-color-palette-light-8)"
  },
  "colors.colorPalette.8": {
    "value": "var(--iap-colors-color-palette-8)",
    "variable": "var(--iap-colors-color-palette-8)"
  },
  "colors.colorPalette.light.9": {
    "value": "var(--iap-colors-color-palette-light-9)",
    "variable": "var(--iap-colors-color-palette-light-9)"
  },
  "colors.colorPalette.9": {
    "value": "var(--iap-colors-color-palette-9)",
    "variable": "var(--iap-colors-color-palette-9)"
  },
  "colors.colorPalette.light.10": {
    "value": "var(--iap-colors-color-palette-light-10)",
    "variable": "var(--iap-colors-color-palette-light-10)"
  },
  "colors.colorPalette.10": {
    "value": "var(--iap-colors-color-palette-10)",
    "variable": "var(--iap-colors-color-palette-10)"
  },
  "colors.colorPalette.light.11": {
    "value": "var(--iap-colors-color-palette-light-11)",
    "variable": "var(--iap-colors-color-palette-light-11)"
  },
  "colors.colorPalette.11": {
    "value": "var(--iap-colors-color-palette-11)",
    "variable": "var(--iap-colors-color-palette-11)"
  },
  "colors.colorPalette.light.12": {
    "value": "var(--iap-colors-color-palette-light-12)",
    "variable": "var(--iap-colors-color-palette-light-12)"
  },
  "colors.colorPalette.12": {
    "value": "var(--iap-colors-color-palette-12)",
    "variable": "var(--iap-colors-color-palette-12)"
  },
  "colors.colorPalette.light.a1": {
    "value": "var(--iap-colors-color-palette-light-a1)",
    "variable": "var(--iap-colors-color-palette-light-a1)"
  },
  "colors.colorPalette.light.a2": {
    "value": "var(--iap-colors-color-palette-light-a2)",
    "variable": "var(--iap-colors-color-palette-light-a2)"
  },
  "colors.colorPalette.light.a3": {
    "value": "var(--iap-colors-color-palette-light-a3)",
    "variable": "var(--iap-colors-color-palette-light-a3)"
  },
  "colors.colorPalette.light.a4": {
    "value": "var(--iap-colors-color-palette-light-a4)",
    "variable": "var(--iap-colors-color-palette-light-a4)"
  },
  "colors.colorPalette.light.a5": {
    "value": "var(--iap-colors-color-palette-light-a5)",
    "variable": "var(--iap-colors-color-palette-light-a5)"
  },
  "colors.colorPalette.light.a6": {
    "value": "var(--iap-colors-color-palette-light-a6)",
    "variable": "var(--iap-colors-color-palette-light-a6)"
  },
  "colors.colorPalette.light.a7": {
    "value": "var(--iap-colors-color-palette-light-a7)",
    "variable": "var(--iap-colors-color-palette-light-a7)"
  },
  "colors.colorPalette.light.a8": {
    "value": "var(--iap-colors-color-palette-light-a8)",
    "variable": "var(--iap-colors-color-palette-light-a8)"
  },
  "colors.colorPalette.light.a9": {
    "value": "var(--iap-colors-color-palette-light-a9)",
    "variable": "var(--iap-colors-color-palette-light-a9)"
  },
  "colors.colorPalette.light.a10": {
    "value": "var(--iap-colors-color-palette-light-a10)",
    "variable": "var(--iap-colors-color-palette-light-a10)"
  },
  "colors.colorPalette.light.a11": {
    "value": "var(--iap-colors-color-palette-light-a11)",
    "variable": "var(--iap-colors-color-palette-light-a11)"
  },
  "colors.colorPalette.light.a12": {
    "value": "var(--iap-colors-color-palette-light-a12)",
    "variable": "var(--iap-colors-color-palette-light-a12)"
  },
  "colors.colorPalette.dark.1": {
    "value": "var(--iap-colors-color-palette-dark-1)",
    "variable": "var(--iap-colors-color-palette-dark-1)"
  },
  "colors.colorPalette.dark.2": {
    "value": "var(--iap-colors-color-palette-dark-2)",
    "variable": "var(--iap-colors-color-palette-dark-2)"
  },
  "colors.colorPalette.dark.3": {
    "value": "var(--iap-colors-color-palette-dark-3)",
    "variable": "var(--iap-colors-color-palette-dark-3)"
  },
  "colors.colorPalette.dark.4": {
    "value": "var(--iap-colors-color-palette-dark-4)",
    "variable": "var(--iap-colors-color-palette-dark-4)"
  },
  "colors.colorPalette.dark.5": {
    "value": "var(--iap-colors-color-palette-dark-5)",
    "variable": "var(--iap-colors-color-palette-dark-5)"
  },
  "colors.colorPalette.dark.6": {
    "value": "var(--iap-colors-color-palette-dark-6)",
    "variable": "var(--iap-colors-color-palette-dark-6)"
  },
  "colors.colorPalette.dark.7": {
    "value": "var(--iap-colors-color-palette-dark-7)",
    "variable": "var(--iap-colors-color-palette-dark-7)"
  },
  "colors.colorPalette.dark.8": {
    "value": "var(--iap-colors-color-palette-dark-8)",
    "variable": "var(--iap-colors-color-palette-dark-8)"
  },
  "colors.colorPalette.dark.9": {
    "value": "var(--iap-colors-color-palette-dark-9)",
    "variable": "var(--iap-colors-color-palette-dark-9)"
  },
  "colors.colorPalette.dark.10": {
    "value": "var(--iap-colors-color-palette-dark-10)",
    "variable": "var(--iap-colors-color-palette-dark-10)"
  },
  "colors.colorPalette.dark.11": {
    "value": "var(--iap-colors-color-palette-dark-11)",
    "variable": "var(--iap-colors-color-palette-dark-11)"
  },
  "colors.colorPalette.dark.12": {
    "value": "var(--iap-colors-color-palette-dark-12)",
    "variable": "var(--iap-colors-color-palette-dark-12)"
  },
  "colors.colorPalette.dark.a1": {
    "value": "var(--iap-colors-color-palette-dark-a1)",
    "variable": "var(--iap-colors-color-palette-dark-a1)"
  },
  "colors.colorPalette.dark.a2": {
    "value": "var(--iap-colors-color-palette-dark-a2)",
    "variable": "var(--iap-colors-color-palette-dark-a2)"
  },
  "colors.colorPalette.dark.a3": {
    "value": "var(--iap-colors-color-palette-dark-a3)",
    "variable": "var(--iap-colors-color-palette-dark-a3)"
  },
  "colors.colorPalette.dark.a4": {
    "value": "var(--iap-colors-color-palette-dark-a4)",
    "variable": "var(--iap-colors-color-palette-dark-a4)"
  },
  "colors.colorPalette.dark.a5": {
    "value": "var(--iap-colors-color-palette-dark-a5)",
    "variable": "var(--iap-colors-color-palette-dark-a5)"
  },
  "colors.colorPalette.dark.a6": {
    "value": "var(--iap-colors-color-palette-dark-a6)",
    "variable": "var(--iap-colors-color-palette-dark-a6)"
  },
  "colors.colorPalette.dark.a7": {
    "value": "var(--iap-colors-color-palette-dark-a7)",
    "variable": "var(--iap-colors-color-palette-dark-a7)"
  },
  "colors.colorPalette.dark.a8": {
    "value": "var(--iap-colors-color-palette-dark-a8)",
    "variable": "var(--iap-colors-color-palette-dark-a8)"
  },
  "colors.colorPalette.dark.a9": {
    "value": "var(--iap-colors-color-palette-dark-a9)",
    "variable": "var(--iap-colors-color-palette-dark-a9)"
  },
  "colors.colorPalette.dark.a10": {
    "value": "var(--iap-colors-color-palette-dark-a10)",
    "variable": "var(--iap-colors-color-palette-dark-a10)"
  },
  "colors.colorPalette.dark.a11": {
    "value": "var(--iap-colors-color-palette-dark-a11)",
    "variable": "var(--iap-colors-color-palette-dark-a11)"
  },
  "colors.colorPalette.dark.a12": {
    "value": "var(--iap-colors-color-palette-dark-a12)",
    "variable": "var(--iap-colors-color-palette-dark-a12)"
  },
  "colors.colorPalette.default": {
    "value": "var(--iap-colors-color-palette-default)",
    "variable": "var(--iap-colors-color-palette-default)"
  },
  "colors.colorPalette.hover": {
    "value": "var(--iap-colors-color-palette-hover)",
    "variable": "var(--iap-colors-color-palette-hover)"
  },
  "colors.colorPalette.emphasized": {
    "value": "var(--iap-colors-color-palette-emphasized)",
    "variable": "var(--iap-colors-color-palette-emphasized)"
  },
  "colors.colorPalette.fg": {
    "value": "var(--iap-colors-color-palette-fg)",
    "variable": "var(--iap-colors-color-palette-fg)"
  },
  "colors.colorPalette.text": {
    "value": "var(--iap-colors-color-palette-text)",
    "variable": "var(--iap-colors-color-palette-text)"
  },
  "colors.colorPalette.canvas": {
    "value": "var(--iap-colors-color-palette-canvas)",
    "variable": "var(--iap-colors-color-palette-canvas)"
  },
  "colors.colorPalette.subtle": {
    "value": "var(--iap-colors-color-palette-subtle)",
    "variable": "var(--iap-colors-color-palette-subtle)"
  },
  "colors.colorPalette.muted": {
    "value": "var(--iap-colors-color-palette-muted)",
    "variable": "var(--iap-colors-color-palette-muted)"
  },
  "colors.colorPalette.disabled": {
    "value": "var(--iap-colors-color-palette-disabled)",
    "variable": "var(--iap-colors-color-palette-disabled)"
  },
  "colors.colorPalette.error": {
    "value": "var(--iap-colors-color-palette-error)",
    "variable": "var(--iap-colors-color-palette-error)"
  },
  "colors.colorPalette.outline": {
    "value": "var(--iap-colors-color-palette-outline)",
    "variable": "var(--iap-colors-color-palette-outline)"
  }
};

function token(path, fallback) {
  return tokens[path]?.value || fallback
}

function tokenVar(path, fallback) {
  return tokens[path]?.variable || fallback
}

token.var = tokenVar;

export { token };
