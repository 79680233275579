/**
 * Disable default react error overlay in development mode
 */

import { Helmet } from 'react-helmet-async'

import {
  enableCustomErrorBoundaryInDevelopmentMode,
  isProduction,
} from 'components/ErrorBoundaries/const'

const disableReactErrorOverlay =
  enableCustomErrorBoundaryInDevelopmentMode && !isProduction
const noOverlayWorkaroundScript = {
  __html: `
  window.addEventListener('error', event => {
    event.stopImmediatePropagation()
  })

  window.addEventListener('unhandledrejection', event => {
    event.stopImmediatePropagation()
  })
`,
}

export function DisableReactErrorOverlay() {
  if (!disableReactErrorOverlay) {
    return null
  }
  return (
    <Helmet>
      <script dangerouslySetInnerHTML={noOverlayWorkaroundScript} />
    </Helmet>
  )
}
