import ExampleCodeBlock, {
  CodeParamType,
} from 'components/Docs/ExampleCodeBlock'

import { httpCodeExamples } from '../http-code-examples'

const js = `await integrationApp
  .connection('{INTEGRATION_KEY}')
  .action('{ACTION_KEY}')
  .create()
`

const rest = httpCodeExamples(
  '/connections/{INTEGRATION_KEY}/actions/{ACTION_KEY}',
  {
    method: 'PUT',
  },
)

export function CreateActionInstanceCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js, ...rest }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
        ACTION_KEY: CodeParamType.ActionKey,
      }}
      run={async ({ integrationApp, parameters }) => {
        return await integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .action(parameters.ACTION_KEY)
          .create()
      }}
    />
  )
}
