"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { forwardRef, useRef } from 'react';
import { clsx } from 'clsx';
import { getRefElement } from '../../../helpers/getRefElement.mjs';
import classes from './OptionSelector.module.css.mjs';
import { FieldWrapper } from '../../ComboBoxElements/FieldWrapper.mjs';
import '../../ComboBoxElements/TipTapEditor/Editor.mjs';
import '../../ComboBoxElements/TipTapEditor/EditorStandAlone.mjs';
import { DropdownCategoryType } from '../types.mjs';
import '@integration-app/sdk';
import 'fuse';
import '../Dropdown/categoryContentMapping.mjs';
import { isClickOnEditorComponent } from '../../ComboBoxElements/TipTapEditor/helpers/clicks.mjs';
import { ActionBar } from '../../DataBuilderUI/index.mjs';
import { useComboBox } from '../context/combobox-context.mjs';
import { useComboBoxDropdownContext } from '../context/combobox-dropdown-context.mjs';
import { Dropdown } from '../Dropdown/Dropdown.mjs';
import '../Value/Formula.mjs';
import '../Value/Schema.mjs';
import { ComboBoxValue } from '../Value/ComboBoxValue.mjs';
import { isDefinedValue } from '../Value/isDefinedValue.mjs';

function OptionSelector({
  placeholder,
  className,
  onChange,
  disabled,
  required,
  valueComponent
}) {
  const valueRef = useRef(null);
  const fieldValueRef = useRef(null);
  const {
    value,
    refsToIgnoreClick,
    // Dropdown related
    isDropdownOpen,
    openDropdown,
    closeDropdown,
    topDropdownPanelComponent
  } = useComboBox();
  const { activeCategory } = useComboBoxDropdownContext();
  function handleOpenDropdown() {
    if (disabled) return;
    openDropdown();
  }
  const refs = [valueRef, fieldValueRef, ...refsToIgnoreClick ?? []];
  const handleClick = function handleClick2(e) {
    const isClickInsideAnyOfElements = refs.map(getRefElement).some((el) => el.contains(e?.target));
    if (isClickInsideAnyOfElements && !isClickOnEditorComponent(e?.target, fieldValueRef?.current)) {
      handleOpenDropdown();
      e?.stopPropagation();
    }
  };
  function handleOptionSelect(option) {
    const keepDropdownOpen = activeCategory && [DropdownCategoryType.SELECT_FROM_UDM_DYNAMICALLY].includes(
      activeCategory
    );
    onChange(option);
    if (!keepDropdownOpen) {
      closeDropdown();
    }
  }
  const handleOnDelete = () => {
    onChange(void 0);
    closeDropdown();
  };
  const requiredAndEmptyField = required && isDefinedValue(value);
  return /* @__PURE__ */ jsx(
    FieldWrapper,
    {
      role: "combobox",
      ref: fieldValueRef,
      "aria-expanded": isDropdownOpen,
      onClick: handleClick,
      disabled,
      requiredAndEmptyField,
      children: /* @__PURE__ */ jsx(
        Dropdown,
        {
          topDropdownPanelComponent,
          autoFocusOnSearch: true,
          onOptionSelect: handleOptionSelect,
          children: /* @__PURE__ */ jsx(
            Value,
            {
              ref: valueRef,
              valueComponent,
              placeholder,
              disabled,
              className,
              onChange,
              onDelete: handleOnDelete,
              isEdited: isDropdownOpen
            }
          )
        }
      )
    }
  );
}
const Value = forwardRef(
  ({
    className,
    valueComponent,
    placeholder,
    onClick,
    onChange,
    onDelete,
    disabled,
    isEdited
  }, ref) => {
    const { value } = useComboBox();
    return /* @__PURE__ */ jsxs("span", { className: classes.valueWrapper, ref, children: [
      !!valueComponent ? /* @__PURE__ */ jsx(Fragment, { children: valueComponent }) : /* @__PURE__ */ jsx(
        ComboBoxValue,
        {
          className,
          placeholder,
          onClick,
          onChange,
          disabled,
          isEdited
        }
      ),
      isDefinedValue(value) && onDelete && /* @__PURE__ */ jsx(
        ActionBar,
        {
          className: clsx(
            classes.actionBar,
            isEdited && classes.actionBar__visible
          ),
          children: /* @__PURE__ */ jsx(ActionBar.DeleteButton, { onClick: onDelete })
        }
      )
    ] });
  }
);

export { OptionSelector };
