"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsx, jsxs } from 'react/jsx-runtime';
import { useId, useRef } from 'react';
import { DropdownUI } from '../../DropdownUI/index.mjs';
import { DROPDOWN_NAVIGATOR_ID } from '../../DropdownUI/constants.mjs';
import { useOptionListKeyNavDispatch } from '../../../helpers/hooks/keyboard-events.mjs';
import { useWindowEvent } from '../../../helpers/hooks/useWindowEvent.mjs';
import { Root, Anchor, Content as Content$1, Title } from '../../../ui-kit/styled/popover.mjs';
import { useComboBox } from '../context/combobox-context.mjs';
import '../context/combobox-dropdown-context.mjs';
import '../context/combobox-dropdown-search.mjs';
import { useDropdownStackContext } from '../context/combobox-dropdown-stack.mjs';
import '@integration-app/sdk';
import { DropdownPositioner } from './DropdownPositioner.mjs';
import { DropdownPortal } from '../DropdownPortal/index.mjs';

const GUTTER = 10;
const POSITIONING = {
  placement: "bottom-start",
  gutter: GUTTER,
  slide: true,
  fitViewport: true
};
function DropdownPopoverProvider({
  children
}) {
  const { isDropdownOpen, closeDropdown } = useComboBox();
  return /* @__PURE__ */ jsx(
    Root,
    {
      open: isDropdownOpen,
      onEscapeKeyDown: closeDropdown,
      closeOnInteractOutside: false,
      unmountOnExit: true,
      lazyMount: true,
      portalled: true,
      autoFocus: false,
      positioning: POSITIONING,
      children
    }
  );
}
function DropdownPopoverContent({
  children
}) {
  return /* @__PURE__ */ jsx(DropdownPortal, { children: /* @__PURE__ */ jsx(DropdownPositioner, { children: /* @__PURE__ */ jsx(Content, { children }) }) });
}
function DropdownAnchor({ children }) {
  return /* @__PURE__ */ jsx(Anchor, { display: "inline", children });
}
function Content({ children }) {
  const id = useId();
  const ref = useRef(null);
  const { scopedComboboxId, closeDropdown } = useComboBox();
  useOptionListKeyNavDispatch(ref.current, scopedComboboxId);
  const { isClickInsideRefsAndChildren } = useDropdownStackContext(
    ref
  );
  useClick((event) => {
    if (!isClickInsideRefsAndChildren(event)) {
      closeDropdown();
    }
  });
  return /* @__PURE__ */ jsx(
    Content$1,
    {
      ref,
      "data-navigation-emitter-id": DROPDOWN_NAVIGATOR_ID,
      asChild: true,
      "aria-labelledby": id,
      children: /* @__PURE__ */ jsx(DropdownUI, { children: /* @__PURE__ */ jsxs(DropdownUI.Content, { children: [
        /* @__PURE__ */ jsx(Title, { srOnly: true, id, children: "Options menu" }),
        children
      ] }) })
    }
  );
}
function useClick(cb) {
  useWindowEvent("mouseup", cb);
  useWindowEvent("touchend", cb);
}

export { DropdownAnchor, DropdownPopoverContent, DropdownPopoverProvider };
