import {
  createContext,
  useContext,
  ElementType,
  type PropsWithoutRef,
} from 'react'

import { cx } from 'styled-system/css'
import {
  SlotRecipeRuntimeFn,
  SlotRecipeVariantRecord,
  RecipeSelection,
} from 'styled-system/types'

export const createSlotRecipeContext = <
  S extends string,
  T extends SlotRecipeVariantRecord<S>,
>(
  recipe: SlotRecipeRuntimeFn<S, T>,
) => {
  const StyleContext = createContext<{
    slotStyles: Partial<Record<S, string>>
    forwardedProps: Record<string, unknown>
  }>({
    slotStyles: {},
    forwardedProps: {},
  })

  function use() {
    const context = useContext(StyleContext)
    if (!context) {
      throw new Error(
        'useSlotRecipeContext must be used within a SlotRecipeProvider',
      )
    }
    return context
  }

  const withProvider = <P extends { className?: string | undefined }>(
    Component: ElementType,
    slot: S | S[],
    options: {
      props?: Partial<PropsWithoutRef<P>>
      forwardVariantsToSlots?: Array<
        keyof RecipeSelection<T> | keyof PropsWithoutRef<P>
      >
    } = {},
  ) => {
    const StyledProvider = (props: PropsWithoutRef<P> & RecipeSelection<T>) => {
      const [variantProps, restProps] = recipe.splitVariantProps(props)
      const slotStyles = recipe(variantProps)

      const forwardedProps = (options?.forwardVariantsToSlots ?? []).reduce(
        (acc, key) => {
          if (key in props) {
            acc[key as string] = props[key]
          }
          return acc
        },
        {} as Record<string, unknown>,
      )

      const contextValue = {
        slotStyles,
        forwardedProps,
      }

      // `splitVariantProps` split components `props` into two parts,
      // one is `variantProps` which is `RecipeSelection<T>` type,
      // the other is `restProps` which is `P` type.
      // We need to asset `restProps` to `P` type because `Component` is `ComponentType<P>`.
      const assertedRestProps = restProps as unknown as PropsWithoutRef<P>

      return (
        <StyleContext.Provider value={contextValue}>
          <Component
            {...options.props}
            {...assertedRestProps}
            className={cx(slotStyles[slot], assertedRestProps?.className)}
          />
        </StyleContext.Provider>
      )
    }

    // @ts-expect-error
    StyledProvider.displayName = Component.displayName || Component.name

    return StyledProvider
  }

  const withContext = <P extends { className?: string | undefined }>(
    Component: ElementType,
    slot: S | S[],
    componentProps: Partial<P> = {},
  ) => {
    const StyledSlotComponent = (props: PropsWithoutRef<P>) => {
      const { forwardedProps, slotStyles } = use()

      const styledComponentProps = {
        ...props,
        ...componentProps,
        ...forwardedProps,
      }

      return (
        <Component
          {...styledComponentProps}
          className={cx(slotStyles[slot], props?.className)}
        />
      )
    }

    // @ts-expect-error
    StyledSlotComponent.displayName = Component.displayName || Component.name

    return StyledSlotComponent
  }

  return {
    withProvider,
    withContext,
    use,
  }
}
