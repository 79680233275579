import { IntegrationAppClient } from '@integration-app/sdk'

import { ENV_CONFIG } from 'config/env'

import ExampleCodeBlock, { CodeParamType } from '../../Docs/ExampleCodeBlock'

const js = `
import { IntegrationAppClient } from '@integration-app/sdk'

const integrationApp = new IntegrationAppClient({
  token: '{TOKEN}',
})

const self = await integrationApp.self.get()
`

export function GetSelfViaTokenCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js }}
      parameters={{
        TOKEN: CodeParamType.String,
      }}
      run={async ({ parameters }) => {
        const client = new IntegrationAppClient({
          token: parameters.TOKEN,
          apiUri: ENV_CONFIG.ENGINE_API_URI,
        })

        const self = await client.self.get()

        return { self }
      }}
    />
  )
}
