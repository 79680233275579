"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { BackButton } from './BackButton.mjs';
import { Content } from './Content.mjs';
import { ErrorHeading } from './ErrorHeading.mjs';
import { InfoHeading } from './InfoHeading.mjs';
import { Item } from './Item.mjs';
import { ItemIcon } from './ItemIcon.mjs';
import { ItemNestingIndicator } from './ItemNestingIndicator.mjs';
import { ItemTitle } from './ItemTitle.mjs';
import { List } from './List.mjs';
import { ListHeader } from './ListHeader.mjs';
import { LoadingItem } from './LoadingItem.mjs';
import { NoOptionsHeading } from './NoOptionsHeading.mjs';
import { Panel } from './Panel.mjs';
import { PopoverContainer } from './PopoverContainer.mjs';
import { Search } from './Search.mjs';

const DropdownUI = Object.assign(PopoverContainer, {
  Content,
  Search,
  Panel,
  List,
  Item,
  ListHeader,
  BackButton,
  NoOptionsHeading,
  ErrorHeading,
  InfoHeading,
  LoadingItem,
  ItemIcon,
  ItemTitle,
  ItemNestingIndicator
});

export { DropdownUI };
