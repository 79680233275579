"use strict";
"use client";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { Menu } from '@ark-ui/react';
import '../../styled-system/helpers.mjs';
import '../../styled-system/css/conditions.mjs';
import '../../styled-system/css/css.mjs';
import { menu } from '../../styled-system/recipes/menu.mjs';
import { createStyleContext } from './utils/create-style-context.mjs';
export { MenuContext as Context } from '@ark-ui/react/menu';

const { withRootProvider, withContext } = createStyleContext(menu);
withRootProvider(Menu.RootProvider);
const Root = withRootProvider(
  Menu.Root
);
withContext(Menu.Arrow, "arrow");
withContext(Menu.ArrowTip, "arrowTip");
withContext(Menu.CheckboxItem, "item");
const Content = withContext(Menu.Content, "content");
withContext(Menu.ContextTrigger, "contextTrigger");
withContext(Menu.Indicator, "indicator");
const ItemGroupLabel = withContext(Menu.ItemGroupLabel, "itemGroupLabel");
const ItemGroup = withContext(Menu.ItemGroup, "itemGroup");
withContext(Menu.ItemIndicator, "itemIndicator");
const Item = withContext(Menu.Item, "item");
withContext(Menu.ItemText, "itemText");
const Positioner = withContext(Menu.Positioner, "positioner");
withContext(Menu.RadioItemGroup, "itemGroup");
withContext(Menu.RadioItem, "item");
withContext(Menu.Separator, "separator");
withContext(Menu.TriggerItem, "triggerItem");
const Trigger = withContext(Menu.Trigger, "trigger");

export { Content, Item, ItemGroup, ItemGroupLabel, Positioner, Root, Trigger };
