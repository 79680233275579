/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { memo, splitProps } from '../helpers.mjs';
import { mergeRecipes, createRecipe } from './create-recipe.mjs';

const spinnerFn = /* @__PURE__ */ createRecipe('spinner', {
  "size": "md"
}, []);

const spinnerVariantMap = {
  "size": [
    "xs",
    "sm",
    "md",
    "lg",
    "xl"
  ]
};

const spinnerVariantKeys = Object.keys(spinnerVariantMap);

const spinner = /* @__PURE__ */ Object.assign(memo(spinnerFn.recipeFn), {
  __recipe__: true,
  __name__: 'spinner',
  __getCompoundVariantCss__: spinnerFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: spinnerVariantKeys,
  variantMap: spinnerVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, spinnerVariantKeys)
  },
  getVariantProps: spinnerFn.getVariantProps,
});

export { spinner };
