import * as Sentry from '@sentry/react'

import { ENV_CONFIG } from 'config/env'

import { enableCustomErrorBoundary, errorLevel } from './const'

/**
 * In development mode, React will rethrow errors caught within an error boundary.
 * This will result in errors being reported twice to Sentry with the above setup,
 * but this won’t occur in your production build.
 *
 * https://docs.sentry.io/platforms/javascript/guides/react/features/error-boundary/#:~:text=in%20development%20mode%2C%20react%20will%20rethrow%20errors%20caught%20within%20an%20error%20boundary.
 */
export function onErrorHandler(error: Error) {
  if (enableCustomErrorBoundary && ENV_CONFIG.ENABLE_TRACKING) {
    Sentry.captureException(error, {
      level: errorLevel,
    })
  }
}
