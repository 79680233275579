"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import useSWR from 'swr';
import { useIntegrationApp } from '../contexts/integration-app-context.mjs';

function useExportFieldsAsCode(elementKey, id, put) {
  const client = useIntegrationApp();
  const { data: code, mutate: mutateCode } = useSWR(
    id ? `/${elementKey}/${id}/export` : void 0,
    (url) => client.get(url)
  );
  async function onCodeChange(newCode) {
    await Promise.all([
      mutateCode(newCode, { revalidate: false }),
      put({ ...newCode })
    ]);
  }
  return { code, onCodeChange, mutateCode };
}

export { useExportFieldsAsCode };
