import { createContext, PropsWithChildren, useContext } from 'react'
import { useFlow, useExportFieldsAsCode } from '@integration-app/react'
import { Flow, IntegrationAppError } from '@integration-app/sdk'

// TODO: PL-7140 replace with generic loading or allow custom
import { EmptyPageLoader } from 'components/Loader'

const FlowContext = createContext<{
  flow: Flow
  parent: Flow | undefined

  onChange(data: Partial<Flow>): Promise<any>

  code: any
  onCodeChange(newCode: any): Promise<void>

  refresh(): Promise<Flow>
  apply(integrationKeys: string[]): Promise<Flow[]>
  archive(): Promise<void>
  reset(): Promise<void>

  putNode(key: string, data: Record<string, any>): Promise<void>
  patchNode(key: string, data: Record<string, any>): Promise<void>
  patchNodeConfig(key: string, data: Record<string, any>): Promise<void>

  isSaving: boolean
  error: null | IntegrationAppError
}>({} as any)

export function FlowContextProvider({
  flowId,
  children,
}: PropsWithChildren<{ flowId: string | undefined }>) {
  const {
    flow,
    put,
    refresh,
    archive,
    reset,
    apply,

    saving: isSaving,
    error,
  } = useFlow(flowId)

  const flowNodes = flow?.nodes ?? {}

  const parentId = flow?.universalFlowId ?? undefined

  const { flow: parent, refresh: refreshParent } = useFlow(parentId)

  const { code, onCodeChange, mutateCode } = useExportFieldsAsCode(
    'flows',
    flowId,
    put,
  )

  async function onChange(data: any) {
    await put({
      ...flow,
      ...(data ?? {}),
    })
    await mutateCode()
    void refreshParent()
  }

  async function patchNode(key: string, data?: Record<string, any>) {
    return onChange({
      nodes: {
        ...flowNodes,
        [key]: {
          ...(flowNodes[key] ?? {}),
          ...data,
        },
      },
    })
  }

  async function putNode(key: string, data?: Record<string, any>) {
    return onChange({
      nodes: {
        ...flowNodes,
        [key]: data,
      },
    })
  }

  async function patchNodeConfig(key: string, data?: Record<string, any>) {
    const node = flowNodes[key]

    if (!node) {
      return
    }

    return patchNode(key, {
      config: {
        ...(node.config ?? {}),
        ...(data ?? {}),
      },
    })
  }

  async function onReset() {
    await reset()
    void refreshParent()
  }

  async function onArchive() {
    await archive()
    void refreshParent()
  }

  if (!flowId || !flow) {
    return <EmptyPageLoader />
  }

  return (
    <FlowContext.Provider
      value={{
        flow,
        parent,

        onChange,

        code,
        onCodeChange,

        putNode,
        patchNode,
        patchNodeConfig,

        refresh,
        archive: onArchive,
        reset: onReset,
        apply,

        error,
        isSaving,
      }}
    >
      {flow && children}
    </FlowContext.Provider>
  )
}

export function useFlowContext() {
  return useContext(FlowContext)
}
