"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { useElements } from '../useElements.mjs';

function useDataLinkTableInstances(query) {
  const { ...rest } = useElements(
    "data-link-table-instances",
    query
  );
  return {
    dataLinkTableInstances: rest.items,
    ...rest
  };
}

export { useDataLinkTableInstances };
