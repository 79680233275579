import { capitalize } from '@integration-app/ui/helpers/capitalize'

import { MENU_TITLE_OVERRIDE } from 'routes/Docs/helpers/constants'
import {
  ArticleListMapType,
  DocArticleType,
  DocMenuLinkType,
  DocMenuSectionType,
  DocMenuType,
  TOCLink,
} from 'routes/Docs/types'

type ContextTypeFn = (key: string) => {
  TITLE: string
  DESCRIPTION: string
  IS_BETA?: boolean
  default: React.ComponentType
  toc?: TOCLink[]
}
type ContextType = ContextTypeFn & {
  keys: () => string[]
}

export function getArticleList(context: ContextType): ArticleListMapType {
  const listMap = getArticleListMap(context)

  const listMapValues = listMap.values()
  for (const article of listMapValues) {
    // set parent and child relations
    if (article.parentRawPath) {
      setArticleParentChildRelationInListMap(article, listMap)
    }
  }

  for (const article of listMapValues) {
    if (article.parentPath) {
      sortArticleChildren(article)
    }
  }

  return listMap
}

function getArticleListMap(context: ContextType): ArticleListMapType {
  return context.keys().reduce((map: ArticleListMapType, key) => {
    map.set(getCleanPath(key), getDocArticleFromContext(context, key))
    return map
  }, new Map())
}

// Filter paths if any segment starts with underscore
function filterPathWithUnderscore(path: string) {
  return !path.split('/').find((x) => x.match(/^(\d+-)?_/))
}

function getDocArticleFromContext(
  context: ContextType,
  key: string,
): DocArticleType {
  const {
    TITLE,
    DESCRIPTION,
    IS_BETA = false,
    default: Component,
    toc,
  } = context(key)
  const path = getCleanPath(key)
  const rawPath = getRawPath(key)

  return {
    title: TITLE || getTitleFromPath(path),
    description: DESCRIPTION,
    isBeta: IS_BETA,
    toc,
    path,
    rawPath,
    parentPath: getParentPath(path),
    parentRawPath: getParentPath(rawPath),
    Component,
  }
}

function createNodeInListMapByRawPath(
  list: ArticleListMapType,
  rawPath: string,
): DocArticleType {
  const path = getCleanPath(rawPath)
  const parentPath = getParentPath(path)
  const parentRawPath = getParentPath(rawPath)

  const article: DocArticleType = {
    title: getTitleFromPath(path),
    path,
    rawPath,
    parentPath,
    parentRawPath,
  }

  list.set(path, article)

  // set parent-child relation if parent of created node exists
  if (parentRawPath) setArticleParentChildRelationInListMap(article, list)

  return getNodeFromListMap(list, path)
}

function setArticleParentChildRelationInListMap(
  article: DocArticleType,
  listMap: ArticleListMapType,
) {
  const childNode = getNodeFromListMap(listMap, article.path)

  // get parent node or create it if it doesn't exist
  const parentNode =
    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    getNodeFromListMap(listMap, article.parentPath) ||
    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    createNodeInListMapByRawPath(listMap, article.parentRawPath)

  const parentNodeChildren = parentNode?.children ?? []
  /* `contains` method is not exist in `parentNodeChildren` ¯\_(ツ)_/¯ */
  if (parentNodeChildren.indexOf(childNode) === -1) {
    parentNode.children = [...parentNodeChildren, childNode]
  }
  childNode.parent = parentNode
}

function getNodeFromListMap(
  list: ArticleListMapType,
  path: string,
): DocArticleType {
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2322): Type 'DocArticleType | undefined' is not assignabl... Remove this comment to see the full error message
  return list.get(path)
}

export function getNodeParents(article: DocArticleType): DocArticleType[] {
  const parents = []
  let articleParent = article?.parent

  while (articleParent) {
    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2345): Argument of type 'DocArticleType' is not assignabl... Remove this comment to see the full error message
    parents.push(articleParent)
    articleParent = articleParent?.parent
  }

  return parents
}

export function createDocMenuBasedOnArticleListMap(
  listMap: ArticleListMapType,
) {
  return getRootArticles(listMap)
    .filter((i) => !i.rawPath.startsWith('_'))
    .map(transformDocArticleToDocMenu)
}

// get path without heading numbers
function getCleanPath(path: string) {
  return removeUnderscores(removePrefixNumberFromPath(getRawPath(path)))
}

function removeUnderscores(path: string) {
  return path
    .split('/')
    .map((segment) => segment.replace(/^_/g, ''))
    .join('/')
}

// get path without leading dot, index file and extension
// ready to compare with other paths
export function getRawPath(path: string) {
  return path
    .replace('./', '')
    .replace('.mdx', '')
    .replace('.tsx', '')
    .replace(/^index$/, '')
    .replace(/\/index$/, '')
}

function getParentPath(path: string) {
  return path.split('/').slice(0, -1).join('/') || undefined
}

function getTitleFromPath(path: string) {
  return getMenuSpecificTitle(path) || capitalize(path.split('/').pop() || '')
}

function getMenuSpecificTitle(path: string) {
  return MENU_TITLE_OVERRIDE[path]
}

export function removePrefixNumberFromPath(path: string) {
  return path
    .split('/')
    .map((segment) => segment.replace(/^[0-9]+-/, ''))
    .join('/')
}

function sortArticleChildren(article: DocArticleType): void {
  if (article.children) {
    article.children = sortArrayOfArticles(article.children)
  }
}

function sortArrayOfArticles(array: DocArticleType[]) {
  return array.sort((a, b) => compareArticlesByPath(a.rawPath, b.rawPath))
}

export function compareArticlesByPath(a1: string, a2: string) {
  return a1 > a2 ? 1 : a1 < a2 ? -1 : 0
}

function getRootArticles(listMap: ArticleListMapType): DocArticleType[] {
  const result = []
  const listMapValues = listMap.values()
  for (const article of listMapValues) {
    // set parent and child relations
    if (article.parentRawPath === undefined) {
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2345): Argument of type 'DocArticleType' is not assignabl... Remove this comment to see the full error message
      result.push(article)
    }
  }

  return sortArrayOfArticles(result).filter((article) => {
    return removePrefixNumberFromPath(article.rawPath)
  })
}

function transformDocArticleToDocMenu(article: DocArticleType): DocMenuType {
  // if no component found it is a section
  const title = article.title
  const isBeta = article.isBeta
  const links = article.children
    ?.filter((art) => filterPathWithUnderscore(art.rawPath))
    .map(transformDocArticleToDocMenu)

  if (!article.Component) {
    return {
      title,
      links,
      isSection: true,
    } as DocMenuSectionType
  }

  return {
    title: article.title,
    path: article.path,
    links: links,
    isBeta,
  } as DocMenuLinkType
}
