import { ReactElement } from 'react'
import { NavLink } from 'react-router-dom'

import { getDocRelativeLink } from 'components/Docs/DocLinksGrid/helpers'
import { getArticle } from 'routes/Docs/helpers/articleList'
import { sva, cx } from 'styled-system/css'
import { Icon } from 'ui-kit/icon'
import { Link, LinkProps } from 'ui-kit/link'

import { DocTagBadge } from './DocTag'

const linkRecipe = sva({
  className: 'docLink',
  slots: ['root', 'icon'],
  base: {
    root: {
      colorPalette: 'primary',
      display: 'inline-flex',
      alignItems: 'baseline',
      lineHeight: 'none',
      fontWeight: 'inherit',
      fontSize: 'inherit',
      gap: 0.5,
      _hover: {
        textDecoration: 'underline',
      },
    },
    icon: {
      alignSelf: 'center',
    },
  },
})

const linkRecipeClassnames = linkRecipe()

export default function DocLink({
  path,
  showTag,
  leftIcon,
  rightIcon,
  children,
  className,
  ...props
}: LinkProps & {
  path: string
  showTag?: boolean
  leftIcon?: ReactElement
  rightIcon?: ReactElement
}) {
  const to = getDocRelativeLink(path)

  return (
    <Link
      asChild
      className={cx(linkRecipeClassnames.root, className)}
      {...props}
    >
      <NavLink to={to}>
        {!!leftIcon && (
          <Icon size={'sm'} className={linkRecipeClassnames.icon}>
            {leftIcon}
          </Icon>
        )}
        {children || getArticle(path)?.title}
        {!!rightIcon && (
          <Icon size={'sm'} className={linkRecipeClassnames.icon}>
            {rightIcon}
          </Icon>
        )}
        {showTag && <DocTagBadge />}
      </NavLink>
    </Link>
  )
}
