import deepmerge from 'deepmerge'

import { cva, RecipeVariantProps, cx, css } from 'styled-system/css'
import { Square, SquareProps, splitCssProps } from 'styled-system/jsx'
import { Text } from 'ui-kit/text'

const statusDotRecipe = cva({
  base: {
    borderRadius: 'full',
    display: 'inline-block',
    backgroundColor: 'colorPalette.10',
    color: 'colorPalette.default',
    colorPalette: 'neutral',
  },
  variants: {
    status: {
      success: {
        colorPalette: 'success',
      },
      warning: {
        colorPalette: 'warning',
      },
      error: {
        colorPalette: 'error',
      },
      info: {
        colorPalette: 'neutral',
        opacity: 0.3,
      },
    },
    size: {
      sm: {
        width: 2,
        height: 2,
      },
      md: {
        width: 2.5,
        height: 2.5,
      },
      lg: {
        width: 3,
        height: 3,
      },
    },
  },
  defaultVariants: {
    size: 'md',
    status: 'info',
  },
})

export type StatusDotStatusType = 'success' | 'warning' | 'error' | 'info'

export function StatusDot({
  className,
  ...props
}: Omit<SquareProps, 'size'> & RecipeVariantProps<typeof statusDotRecipe>) {
  const [variantProps, restProps] = statusDotRecipe.splitVariantProps(props)
  const [styleProps, otherProps] = splitCssProps(restProps)

  return (
    <Square
      className={cx(
        css(deepmerge(statusDotRecipe.raw(variantProps), styleProps)),
        className,
      )}
      {...otherProps}
    >
      <Text as={'span'} srOnly>
        Status: {getStatusDotLabel(variantProps.status)}
      </Text>
    </Square>
  )
}

function getStatusDotLabel(status?: StatusDotStatusType) {
  switch (status) {
    case 'success':
      return 'Success'
    case 'warning':
      return 'Warning'
    case 'error':
      return 'Error'
    case 'info':
    default:
      return 'Info'
  }
}
