/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { forwardRef, createElement } from 'react';
import { splitProps } from '../helpers.mjs';
import { getCenterStyle } from '../patterns/center.mjs';
import { styled } from './factory.mjs';

const Center = /* @__PURE__ */ forwardRef(function Center(props, ref) {
  const [patternProps, restProps] = splitProps(props, ["inline"]);

const styleProps = getCenterStyle(patternProps);
const mergedProps = { ref, ...styleProps, ...restProps };

return createElement(styled.div, mergedProps)
  });

export { Center };
