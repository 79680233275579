import { ReactNode } from 'react'

import { Tooltip } from 'components/Tooltip'
import { Icon } from 'ui-kit/icon'
import {
  IconButtonProps as UiKitIconButtonProps,
  IconButton as UiKitIconButton,
} from 'ui-kit/icon-button'

export type IconButtonProps = UiKitIconButtonProps & {
  tooltip: ReactNode
}

// Simple implementation of `IconButton`
// that forces to use tooltip and provides ARIA compatibility
export function IconButton({
  children,
  tooltip,
  size,
  ...props
}: IconButtonProps) {
  return (
    <Tooltip tooltip={tooltip}>
      <UiKitIconButton {...props} size={size}>
        <Icon size={size}>{children}</Icon>
      </UiKitIconButton>
    </Tooltip>
  )
}
