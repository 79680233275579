import { isValidElement, ReactNode } from 'react'
import { Portal } from '@ark-ui/react'

import { Tooltip as UiKitTooltip } from 'ui-kit/tooltip'

export type TooltipProps = UiKitTooltip.RootProps & {
  tooltip: ReactNode
  usePortal?: boolean
}

function Tooltip({ children, tooltip, usePortal, ...props }: TooltipProps) {
  const asChild = asChildSupported(children)

  return (
    <UiKitTooltip.Root openDelay={500} {...props}>
      <UiKitTooltip.Trigger asChild={asChild}>{children}</UiKitTooltip.Trigger>
      <Portal disabled={!usePortal}>
        <UiKitTooltip.Positioner>
          <UiKitTooltip.Arrow>
            <UiKitTooltip.ArrowTip />
          </UiKitTooltip.Arrow>
          <UiKitTooltip.Content>{tooltip}</UiKitTooltip.Content>
        </UiKitTooltip.Positioner>
      </Portal>
    </UiKitTooltip.Root>
  )
}
export { Tooltip }

function asChildSupported(children: ReactNode) {
  if (!children) return false

  if (!isValidElement(children)) return false

  const isDomElement = typeof children.type === 'string'

  const isForwardRef =
    children.type?.['$$typeof'] === Symbol.for('react.forward_ref')
  return isDomElement || isForwardRef
}
