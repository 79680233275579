/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import styleInject from 'style-inject';

var css_248z = ".OptionSelector-module_valueWrapper__bSY1e {\n  position: relative;\n  display: inline-flex;\n  max-width: 100%;\n  --tw-text-opacity: 1;\n  color: rgb(0 0 0 / var(--tw-text-opacity));\n  padding-right: 0.375rem;\n  width: -moz-fit-content;\n  width: fit-content;\n  transition:\n    width 0.5s,\n    height 0.5s\n}\n\n.OptionSelector-module_valueWrapper__bSY1e[role='combobox'] span:empty {\n  --tw-bg-opacity: 1;\n  background-color: rgb(170 170 170 / var(--tw-bg-opacity));\n  min-width: 7rem\n}\n\n.OptionSelector-module_actionBar__YRz10:not(.OptionSelector-module_actionBar__visible__3kDge) {\n  display: none\n}\n\n.OptionSelector-module_actionBar__visible__3kDge {\n  position: absolute;\n  left: 100%;\n  top: 0px;\n  bottom: 0px;\n  margin-right: 0px;\n  width: 0px;\n  z-index: 1\n}\n";
var classes = {"valueWrapper":"OptionSelector-module_valueWrapper__bSY1e","actionBar":"OptionSelector-module_actionBar__YRz10","actionBar__visible":"OptionSelector-module_actionBar__visible__3kDge"};
styleInject(css_248z);

export { classes as default };
