import ExampleCodeBlock from 'components/Docs/ExampleCodeBlock'

import { useWorkspace } from '../../Workspaces/workspace-context'

export function VueBasicSetupCodeExample() {
  const { testCustomerAccessToken } = useWorkspace()

  const js = `<script setup lang="ts">
import { IntegrationAppClient } from "@integration-app/sdk";

const token = await tokenResp.json();

const integrationApp = new IntegrationAppClient({
  "${testCustomerAccessToken}",
});

</script>

<template>
  <div v-if="!token">Loading...</div>
  <div v-else>
    <button class="btn btn-sm btn-outline m-2" v-on:click="integrationApp.open">
      Configure Integrations
    </button>
  </div>
</template>
`

  return <ExampleCodeBlock customCodes={{ js }} />
}
