import { FlowInstance } from '@integration-app/sdk'
import { useDebounceValue } from 'usehooks-ts'

import { SelectItemType, ComboboxProps, Combobox } from 'components/Select'

import { OptionItem } from './components'
import useIntegrationElement from '../hooks/useIntegrationElement'

export function FlowInstanceComboboxSelect({
  value,
  onChange,
  name,
  ...props
}: Omit<ComboboxProps, 'items' | 'value' | 'onChange'> & {
  value?: string
  onChange: (value?: string) => void
  name?: string
}) {
  const [debouncedSearchValue, setDebouncedSearchValue] = useDebounceValue(
    '',
    150,
  )

  const { items } = useIntegrationElement<FlowInstance>({
    route: 'flow-instances',
  })

  const options = items?.map((flowInstance) => ({
    value: flowInstance.id,
    label: flowInstance.name,
  }))

  const filteredOptions = options?.filter((o) => {
    return [o.label, o.value]
      .map((x) => x.toLowerCase())
      .some((x) => x.includes(debouncedSearchValue.toLowerCase()))
  })
  const selectedOption = options?.find((o) => o.value === value)

  function handleChange(item: SelectItemType) {
    onChange(item?.value)
  }

  return (
    <Combobox
      items={filteredOptions}
      value={selectedOption}
      onValueChange={handleChange}
      placeholder={'Select a flow instance'}
      onSearchInputChange={setDebouncedSearchValue}
      TriggerTextComponent={(props) => (
        <OptionItem asTrigger name={name} {...props} />
      )}
      ItemTextComponent={(props) => <OptionItem {...props} />}
      {...props}
    />
  )
}
