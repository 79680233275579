import { useState } from 'react'
import { ComboBox, ComboBoxOption } from '@integration-app/react'

import ExampleUIBlock from 'components/Docs/ExampleUIBlock'

const jsx = `import { ComboBox, ComboBoxOption } from "@integration-app/react";

const staticOptions: ComboBoxOption[] = [
  {
    label: 'Option 1',
    value: 'option-1',
  },
  {
    label: 'Option 2',
    value: 'option-2',
  },
]

export function ComboboxExample() {
  const [value, setValue] = useState<unknown>(null)

  return (
    <ComboBox
      value={value}
      onChange={setValue}
      placeholder='Type here...'
      options={staticOptions}
    />
  )
}
`

const staticOptions: ComboBoxOption[] = [
  {
    label: 'Option 1',
    value: 'option-1',
  },
  {
    label: 'Option 2',
    value: 'option-2',
  },
]

function Component() {
  const [value, setValue] = useState<unknown>(null)

  return (
    <ComboBox
      value={value}
      onChange={setValue}
      placeholder='Type here...'
      options={staticOptions}
    />
  )
}

export function ComboboxCodeExample() {
  return <ExampleUIBlock customCodes={{ jsx }} Component={Component} />
}
