"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import usePromiseImport from 'react-use-promise';

const defaultExported = usePromiseImport?.default;
const usePromise = defaultExported ?? usePromiseImport;

export { usePromise as default };
