"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { usePopoverContext } from '@ark-ui/react/popover';
import { useFloating, autoUpdate, offset, autoPlacement, size } from '@floating-ui/react';
import 'react';
import '../../../styled-system/css/css.mjs';
import '../../../styled-system/helpers.mjs';
import '../../../styled-system/jsx/is-valid-prop.mjs';
import { Box } from '../../../styled-system/jsx/box.mjs';
import '../../../styled-system/patterns/visually-hidden.mjs';

const GUTTER = 10;
const VIEWPORT_PADDING = 8;
const DropdownPositioner = ({
  children
}) => {
  const context = usePopoverContext();
  const anchorPops = context.getAnchorProps();
  const { refs, floatingStyles } = useFloating({
    whileElementsMounted: autoUpdate,
    elements: {
      reference: anchorPops.id ? document.getElementById(anchorPops.id) : void 0
    },
    middleware: [
      offset({ mainAxis: GUTTER }),
      autoPlacement({
        allowedPlacements: ["bottom-start", "top-start"],
        crossAxis: true,
        boundary: "clippingAncestors",
        padding: GUTTER / 2
      }),
      size({
        padding: VIEWPORT_PADDING,
        apply({ elements, availableHeight, availableWidth }) {
          const floating = elements.floating;
          availableWidth = Math.floor(availableWidth);
          availableHeight = Math.floor(availableHeight);
          floating.style.setProperty("--available-width", `${availableWidth}px`);
          floating.style.setProperty(
            "--available-height",
            `${availableHeight}px`
          );
        }
      })
    ]
  });
  return /* @__PURE__ */ jsx(
    Box,
    {
      ref: refs.setFloating,
      style: floatingStyles,
      zIndex: "popover",
      isolation: "isolate",
      children
    }
  );
};

export { DropdownPositioner };
