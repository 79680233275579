"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useState, useRef, useEffect } from 'react';
import { getReferenceCollectionPointerForSchema, getMissingRequiredFields, extractIntegrationAppErrorData } from '@integration-app/sdk';
import deepEqual from 'fast-deep-equal';
import { v4 } from 'uuid';
import { DropdownUI } from '../../../../DropdownUI/index.mjs';
import { SvgIconType } from '../../../../SvgIcon/svg-icon-type.mjs';
import { useIntegrationAppClient } from '../../../../../contexts/integration-app-client-context.mjs';
import { useIntegrationAppConnection } from '../../../../../contexts/integration-app-connection-context.mjs';
import { useIntegrationAppIntegration } from '../../../../../contexts/integration-app-integration-context.mjs';
import { useKeyboardEventSubscribe, EVENT_TYPE } from '../../../../../helpers/hooks/keyboard-events.mjs';
import { useDeepEffect } from '../../../../../helpers/hooks/useDeepEffect.mjs';
import { useDataCollectionSpec } from '../../../../../hooks/data-collections/useDataCollectionSpec.mjs';
import '../../../../../styled-system/css/css.mjs';
import '../../../../../styled-system/helpers.mjs';
import { sva } from '../../../../../styled-system/css/sva.mjs';
import '../../../../../styled-system/jsx/is-valid-prop.mjs';
import { Flex } from '../../../../../styled-system/jsx/flex.mjs';
import '../../../../../styled-system/patterns/visually-hidden.mjs';
import { Root, Icon, Title } from '../../../../../ui-kit/styled/alert.mjs';
import { ParametersPanel } from './ParametersPanel.mjs';
import { SvgIcon } from '../../../../SvgIcon/index.mjs';
import { useComboBox } from '../../../context/combobox-context.mjs';
import { useComboBoxDropdownContext } from '../../../context/combobox-dropdown-context.mjs';
import { useComboBoxDropdownSearchContext } from '../../../context/combobox-dropdown-search.mjs';
import { filterOptionsByLabel } from '../../../options-factories/utils.mjs';
import { useNavigationContext } from '../../NavigationContext.mjs';
import DropdownOption from '../../Option.mjs';

const lookupOptionsRecipe = sva({
  className: "lookup-config-options",
  slots: [
    "container",
    "required-message-container",
    "required-message-title",
    "additional-message-container",
    "additional-message-title"
  ],
  base: {
    container: {
      flexDir: "column",
      py: "1.5",
      maxW: "md"
    },
    "required-message-container": {
      h: "fit-content",
      w: "fit-content",
      rounded: "lg",
      px: "3",
      py: "1.5",
      alignItems: "center",
      gap: "2",
      bg: "gray.2",
      mx: "1.5"
    },
    "required-message-title": {
      textStyle: "sm",
      color: "slate.11",
      fontWeight: "medium",
      padding: "0"
    },
    "additional-message-container": {
      h: "fit-content",
      w: "fit-content",
      rounded: "lg",
      px: "3",
      py: "1.5",
      alignItems: "center",
      gap: "2",
      bg: "yellow.2",
      borderColor: "yellow.8",
      mx: "1.5",
      mt: "1.5",
      "& svg": {
        color: "yellow.11"
      }
    },
    "additional-message-title": {
      textStyle: "sm",
      color: "yellow.11",
      fontWeight: "medium",
      padding: "0"
    }
  }
});
function LookupOptionsCategoryContent({
  onOptionSelect
}) {
  const [options, setOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(true);
  const [additionalMessage, setAdditionalMessage] = useState("");
  const [error, setError] = useState();
  const { searchValue } = useComboBoxDropdownSearchContext();
  const [parametersValue, setParametersValue] = useState({});
  const { schema } = useComboBox();
  const pointer = getReferenceCollectionPointerForSchema(schema);
  const { client } = useIntegrationAppClient();
  const { connectionId } = useIntegrationAppConnection();
  const { integrationId } = useIntegrationAppIntegration();
  const currentLoadOptionsId = useRef("");
  const styles = lookupOptionsRecipe();
  const referenceCollectionSpec = useDataCollectionSpec({
    key: pointer?.key,
    integrationId
  });
  useEffect(() => {
    if (pointer?.parameters) {
      setParametersValue(pointer.parameters);
    }
  }, [JSON.stringify(pointer)]);
  useEffect(() => {
    if (schema && connectionId) {
      void loadOptions();
    }
  }, [
    connectionId,
    JSON.stringify(schema),
    JSON.stringify(parametersValue),
    JSON.stringify(referenceCollectionSpec)
  ]);
  if (!schema) return null;
  const hasParameters = Object.keys(referenceCollectionSpec?.parametersSchema?.properties ?? {}).length > 0;
  const hasMissingParameters = getMissingRequiredFields(
    referenceCollectionSpec?.parametersSchema,
    parametersValue
  )?.length > 0;
  async function loadOptions() {
    const loadOptionsId = v4();
    currentLoadOptionsId.current = loadOptionsId;
    setLoadingOptions(true);
    setError(void 0);
    setAdditionalMessage("");
    if (hasMissingParameters) return;
    try {
      if (!connectionId) {
        throw new Error("No connection provided. Cannot load options.");
      }
      if (!referenceCollectionSpec || !pointer) {
        setOptions([]);
        return;
      }
      const connectionAccessor = client.connection(connectionId);
      let response;
      const collectionAccessor = await connectionAccessor.dataCollection(
        pointer?.key,
        parametersValue
      );
      if (referenceCollectionSpec?.list) {
        response = await collectionAccessor.list();
      } else {
        throw new Error(
          `Collection ${referenceCollectionSpec.key} does not support listing options.`
        );
      }
      if (currentLoadOptionsId.current === loadOptionsId) {
        const options2 = response.records.map((record) => {
          return {
            label: record.name ?? record.id,
            value: record.id
          };
        });
        if (response.cursor) {
          setAdditionalMessage(
            `Only the first ${options2.length} options are loaded. If your option is not in the list, please type its ID manually.`
          );
        }
        setOptions(options2);
      }
    } catch (e) {
      if (currentLoadOptionsId.current === loadOptionsId) {
        setError(extractIntegrationAppErrorData(e));
      }
    } finally {
      if (currentLoadOptionsId.current === loadOptionsId) {
        setLoadingOptions(false);
      }
    }
  }
  const filteredOptions = filterOptionsByLabel(options, searchValue);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    hasParameters && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx("hr", {}),
      /* @__PURE__ */ jsx(
        ParametersPanel,
        {
          onChange: (value) => setParametersValue(value),
          schema: referenceCollectionSpec?.parametersSchema,
          value: parametersValue
        }
      ),
      /* @__PURE__ */ jsx("hr", {})
    ] }),
    /* @__PURE__ */ jsxs(Flex, { className: styles.container, children: [
      /* @__PURE__ */ jsx(DropdownUI.ListHeader, { children: "Available options" }),
      !hasMissingParameters ? loadingOptions ? /* @__PURE__ */ jsxs(DropdownUI.List, { children: [
        /* @__PURE__ */ jsx(DropdownUI.LoadingItem, {}),
        /* @__PURE__ */ jsx(DropdownUI.LoadingItem, {}),
        /* @__PURE__ */ jsx(DropdownUI.LoadingItem, {})
      ] }) : error ? /* @__PURE__ */ jsx(
        DropdownUI.ErrorHeading,
        {
          text: error?.message || "Something went wrong"
        }
      ) : /* @__PURE__ */ jsxs(DropdownUI.List, { children: [
        /* @__PURE__ */ jsx(
          LookupOptionsList,
          {
            options: filteredOptions,
            onOptionSelect: (value) => onOptionSelect(value?.value)
          }
        ),
        additionalMessage && /* @__PURE__ */ jsx(AdditionalMessageAlert, { text: additionalMessage })
      ] }) : /* @__PURE__ */ jsx(OptionsParametersRequiredAlert, {})
    ] })
  ] });
}
function LookupOptionsList({
  options,
  onOptionSelect
}) {
  const { isOptionSelected, drillUp } = useComboBoxDropdownContext();
  const { scopedComboboxId } = useComboBox();
  const {
    currentItem,
    moveToPreviousItem,
    moveToNextItem,
    onOptionsListChange
  } = useNavigationContext();
  useKeyboardEventSubscribe({
    scopedComboboxId,
    event: EVENT_TYPE.OPTION_DOWN,
    callback: () => {
      moveToPreviousItem(options, currentItem);
    }
  });
  useKeyboardEventSubscribe({
    scopedComboboxId,
    event: EVENT_TYPE.OPTION_UP,
    callback: () => {
      moveToNextItem(options, currentItem);
    }
  });
  useKeyboardEventSubscribe({
    scopedComboboxId,
    event: EVENT_TYPE.OPTION_LEFT,
    callback: () => {
      drillUp();
    }
  });
  useKeyboardEventSubscribe({
    scopedComboboxId,
    event: EVENT_TYPE.OPTION_RIGHT,
    callback: handleKeyEnter
  });
  useKeyboardEventSubscribe({
    scopedComboboxId,
    event: EVENT_TYPE.OPTION_SELECT,
    callback: handleKeyEnter
  });
  useDeepEffect(() => {
    onOptionsListChange(options);
  }, [options]);
  function handleKeyEnter() {
    if (currentItem === "drill-up") {
      drillUp();
      return;
    }
    if (currentItem) {
      handleOptionSelect(currentItem);
    }
  }
  function handleOptionSelect(option) {
    onOptionSelect(option);
  }
  return /* @__PURE__ */ jsx(DropdownUI.List, { children: /* @__PURE__ */ jsx(
    DropdownListOptions,
    {
      options,
      isOptionSelected,
      handleOptionSelect
    }
  ) });
}
function DropdownListOptions({
  options,
  isOptionSelected,
  handleOptionSelect
}) {
  const { currentItem } = useNavigationContext();
  if (!options) {
    return null;
  }
  if (options.length === 0) {
    return /* @__PURE__ */ jsx(DropdownUI.NoOptionsHeading, { text: "No results found" });
  }
  return /* @__PURE__ */ jsx(Fragment, { children: options.map((option, idx) => /* @__PURE__ */ jsx(
    DropdownOption,
    {
      option,
      selected: isOptionSelected(option),
      current: deepEqual(option, currentItem),
      onSelect: () => handleOptionSelect(option)
    },
    idx
  )) });
}
function OptionsParametersRequiredAlert() {
  const styles = lookupOptionsRecipe();
  return /* @__PURE__ */ jsxs(Root, { className: styles["required-message-container"], children: [
    /* @__PURE__ */ jsx(Icon, { asChild: true, children: /* @__PURE__ */ jsx(SvgIcon, { type: SvgIconType.InfoCircle }) }),
    /* @__PURE__ */ jsx(Title, { className: styles["required-message-title"], children: "Please select parameters above to display lists" })
  ] });
}
function AdditionalMessageAlert({ text }) {
  const styles = lookupOptionsRecipe();
  return /* @__PURE__ */ jsxs(Root, { className: styles["additional-message-container"], children: [
    /* @__PURE__ */ jsx(Icon, { asChild: true, children: /* @__PURE__ */ jsx(SvgIcon, { type: SvgIconType.InfoCircle }) }),
    /* @__PURE__ */ jsx(Title, { className: styles["additional-message-title"], children: text })
  ] });
}

export { AdditionalMessageAlert, LookupOptionsCategoryContent, OptionsParametersRequiredAlert };
