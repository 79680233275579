/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { getPatternStyles, patternFns } from '../helpers.mjs';
import '../css/css.mjs';

const circleConfig = {
transform(props) {
  const { size, ...rest } = props;
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "0 0 auto",
    width: size,
    height: size,
    borderRadius: "9999px",
    ...rest
  };
}};

const getCircleStyle = (styles = {}) => {
  const _styles = getPatternStyles(circleConfig, styles);
  return circleConfig.transform(_styles, patternFns)
};

export { getCircleStyle };
