"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import React, { createContext, useId, useContext, useRef } from 'react';
import { Portal } from '@ark-ui/react';

const BoundaryContext = createContext(null);
const BOUNDARY_DATA_KEY = "data-dropdown-portal-boundary";
function DropdownPortalBoundary({
  children,
  id
}) {
  const generatedId = useId();
  return /* @__PURE__ */ jsx(BoundaryContext.Provider, { value: id || generatedId, children: React.isValidElement(children) ? React.cloneElement(children, {
    [BOUNDARY_DATA_KEY]: id || generatedId
  }) : children });
}
function DropdownPortal({ children }) {
  const boundaryId = useContext(BoundaryContext);
  const containerRef = useRef(null);
  const container = boundaryId ? document.querySelector(
    `[${BOUNDARY_DATA_KEY}="${boundaryId}"]`
  ) : null;
  containerRef.current = container;
  return /* @__PURE__ */ jsx(Portal, { container: containerRef, children });
}

export { DropdownPortal, DropdownPortalBoundary };
