"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { clsx } from 'clsx';
import { dropdownRecipe } from '../../recipes/dropdown.mjs';
import '../../styled-system/css/css.mjs';
import '../../styled-system/helpers.mjs';
import '../../styled-system/jsx/is-valid-prop.mjs';
import { Box } from '../../styled-system/jsx/box.mjs';
import '../../styled-system/patterns/visually-hidden.mjs';

const PopoverContainer = forwardRef(({ children, className, ...props }, ref) => {
  const styles = dropdownRecipe({});
  return /* @__PURE__ */ jsx(
    Box,
    {
      ref,
      className: clsx(styles["popover-container"], className),
      ...props,
      children
    }
  );
});

export { PopoverContainer };
