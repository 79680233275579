"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsx, jsxs } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { schemaWithTitle } from '@integration-app/sdk';
import { SvgIconType } from '../../SvgIcon/svg-icon-type.mjs';
import { Formula, Variable, VariableSvgIcon } from '../../Formula/index.mjs';
import useDataBuilderField from '../field-context.mjs';
import { DrilldownBlockContentWrapper } from '../../DataBuilderUI/index.mjs';
import { makeSubField } from '../data-field.mjs';
import DataBuilderFormField from '../Field.mjs';
import { DataBuilderFieldControlPanel } from '../Nested/FieldControlPanel/index.mjs';

const FirstNotEmptyFormula = forwardRef(
  (props, ref) => {
    return /* @__PURE__ */ jsx(Formula, { ref, onClick: props.onClick, children: /* @__PURE__ */ jsxs(Variable, { children: [
      /* @__PURE__ */ jsx(VariableSvgIcon, { type: SvgIconType.Function }),
      " First not empty"
    ] }) });
  }
);
FirstNotEmptyFormula.displayName = "FirstNotEmptyFormula";
function FirstNotEmptyDropdown({
  onChange,
  formula
}) {
  const { field } = useDataBuilderField();
  const renderedField = {
    ...field,
    value: {
      $firstNotEmpty: formula.args
    }
  };
  return /* @__PURE__ */ jsx(FirstNotEmptyNested, { field: renderedField, onChange });
}
function FirstNotEmptyNested({ field, onChange }) {
  const values = field.value?.$firstNotEmpty ?? [];
  function handleChangeValue(index, value) {
    const newValues = [...values];
    newValues[index] = value;
    onChange?.({ $firstNotEmpty: newValues });
  }
  function handleDeleteValue(index) {
    const newValues = [...values];
    newValues.splice(index, 1);
    onChange?.({ $firstNotEmpty: newValues });
  }
  function handleAddValue() {
    const newValues = [...values];
    newValues.push(null);
    onChange?.({ $firstNotEmpty: newValues });
  }
  return /* @__PURE__ */ jsxs(DrilldownBlockContentWrapper, { children: [
    values.map((value, index) => {
      const subField = makeSubField(field, {
        /* FIXME: strictNullCheck temporary fix */
        // @ts-expect-error TS(2345): Argument of type 'DataSchema | undefined' is not a... Remove this comment to see the full error message
        schema: schemaWithTitle(field.schema, void 0),
        valueLocator: `${field.valueLocator}.$firstNotEmpty.${index}`,
        value,
        isDeletable: true
      });
      return /* @__PURE__ */ jsx(
        DataBuilderFormField,
        {
          field: subField,
          onDelete: () => handleDeleteValue(index),
          onChange: (value2) => handleChangeValue(index, value2)
        },
        index
      );
    }),
    /* @__PURE__ */ jsx(DataBuilderFieldControlPanel, { field, onAdd: handleAddValue })
  ] });
}

export { FirstNotEmptyDropdown, FirstNotEmptyFormula as default };
