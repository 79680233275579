import { SvgIconType } from '@integration-app/react'
import {
  WorkspaceElementState,
  WorkspaceElementType,
} from '@integration-app/sdk'

import {
  CUSTOMERS_ROUTE,
  APPS_ROUTE,
  FLOWS_ROUTE,
  ACTIONS_ROUTE,
  DATA_SOURCE_INSTANCES_ROUTE,
  FLOW_INSTANCES_ROUTE,
  ACTION_INSTANCES_ROUTE,
  FIELD_MAPPING_INSTANCES_ROUTE,
  DATA_SOURCES_ROUTE,
  CONNECTIONS_ROUTE,
  FIELD_MAPPINGS_ROUTE,
} from 'routes/Workspaces/Workspace/routes-constants'

export const WORKSPACE_ELEMENT_TYPE_TO_ICON_TYPE: Partial<
  Record<WorkspaceElementType, SvgIconType>
> = {
  [WorkspaceElementType.Integration]: SvgIconType.AppEntity,
  [WorkspaceElementType.Connection]: SvgIconType.Link,
  [WorkspaceElementType.Customer]: SvgIconType.UserEntity,
  [WorkspaceElementType.Flow]: SvgIconType.FlowEntity,
  [WorkspaceElementType.FlowInstance]: SvgIconType.FlowEntity,
  [WorkspaceElementType.Action]: SvgIconType.ActionEntity,
  [WorkspaceElementType.ActionInstance]: SvgIconType.ActionEntity,
  [WorkspaceElementType.DataSource]: SvgIconType.DataSourceEntity,
  [WorkspaceElementType.DataSourceInstance]: SvgIconType.DataSourceEntity,
  [WorkspaceElementType.FieldMapping]: SvgIconType.FieldMappingEntity,
  [WorkspaceElementType.FieldMappingInstance]: SvgIconType.FieldMappingEntity,
}

export const WORKSPACE_ELEMENTS_TYPE_TO_ROUTE: Partial<
  Record<WorkspaceElementType, string>
> = {
  [WorkspaceElementType.Integration]: APPS_ROUTE,
  [WorkspaceElementType.Connection]: CONNECTIONS_ROUTE,
  [WorkspaceElementType.Customer]: CUSTOMERS_ROUTE,
  [WorkspaceElementType.Flow]: FLOWS_ROUTE,
  [WorkspaceElementType.FlowInstance]: FLOW_INSTANCES_ROUTE,
  [WorkspaceElementType.Action]: ACTIONS_ROUTE,
  [WorkspaceElementType.ActionInstance]: ACTION_INSTANCES_ROUTE,
  [WorkspaceElementType.DataSource]: DATA_SOURCES_ROUTE,
  [WorkspaceElementType.DataSourceInstance]: DATA_SOURCE_INSTANCES_ROUTE,
  [WorkspaceElementType.FieldMapping]: FIELD_MAPPINGS_ROUTE,
  [WorkspaceElementType.FieldMappingInstance]: FIELD_MAPPING_INSTANCES_ROUTE,
}

export const WORKSPACE_ELEMENT_STATE_TO_COLOR_PALETTE = (
  state: WorkspaceElementState,
) => {
  switch (state) {
    case WorkspaceElementState.READY:
      return 'success'
    case WorkspaceElementState.SETTING_UP:
      return 'warning'
    case WorkspaceElementState.SETUP_FAILED:
      return 'error'
    case WorkspaceElementState.CONFIGURATION_ERROR:
      return 'error'
    default:
      return 'info'
  }
}
