"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import { UNIFIED_DATA_MODELS } from '@integration-app/sdk';
import { ComboBox } from '../../ComboBox/ComboBox.mjs';
import { Row, Cell } from '../../DataBuilderUI/index.mjs';

function UdmSelect({ value, onChange }) {
  const options = [];
  for (const [key, udm] of Object.entries(UNIFIED_DATA_MODELS)) {
    options.push({
      label: udm.pluralName,
      value: key
    });
  }
  return /* @__PURE__ */ jsxs(Row, { children: [
    /* @__PURE__ */ jsx(Cell.Name, { children: "Data Model" }),
    /* @__PURE__ */ jsx(Cell.Combobox, { children: /* @__PURE__ */ jsx(
      ComboBox,
      {
        placeholder: "Select Universal Data Model",
        value,
        options,
        onChange
      }
    ) })
  ] });
}

export { UdmSelect };
