"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { clsx } from 'clsx';
import { SvgIconType } from '../../../SvgIcon/svg-icon-type.mjs';
import classes from './FieldListWrapper.module.css.mjs';
import { SvgIcon } from '../../../SvgIcon/index.mjs';

function FieldListWrapper({
  children,
  onClick,
  fieldsToShow,
  collapsed
}) {
  const buttonTitle = collapsed ? `Show ${fieldsToShow} more fields` : "Collapse fields";
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    children,
    /* @__PURE__ */ jsx(
      "div",
      {
        className: clsx(
          classes.container,
          collapsed && classes.container__collapsed
        ),
        onClick,
        children: /* @__PURE__ */ jsxs("button", { className: classes.button, children: [
          buttonTitle,
          /* @__PURE__ */ jsx(
            SvgIcon,
            {
              type: collapsed ? SvgIconType.ChevronDown : SvgIconType.ChevronUp
            }
          )
        ] })
      }
    )
  ] });
}

export { FieldListWrapper };
