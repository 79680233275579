"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { DropdownUI } from '../../DropdownUI/index.mjs';
import { useNavigationContext } from './NavigationContext.mjs';
import { useComboBoxDropdownContext } from '../context/combobox-dropdown-context.mjs';

const DrillUpButton = forwardRef(({ onClick, ...props }, ref) => {
  const { drillUp, drillDownStackTop } = useComboBoxDropdownContext();
  const { currentItem } = useNavigationContext();
  if (!drillDownStackTop) {
    return null;
  }
  return /* @__PURE__ */ jsx(
    DropdownUI.BackButton,
    {
      ref,
      onClick: (e) => {
        drillUp();
        onClick?.(e);
      },
      "data-current": currentItem === "drill-up" ? true : void 0,
      ...props,
      children: drillDownStackTop.label
    }
  );
});

export { DrillUpButton };
