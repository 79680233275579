"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { useState } from 'react';
import { clsx } from 'clsx';
import { toHeaderCase } from 'js-convert-case';
import { makeDataField } from '../../../../DataBuilder/index.mjs';
import { DataBuilderForm } from '../../../../DataBuilder/Form.mjs';
import { SvgIcon } from '../../../../SvgIcon/index.mjs';
import { SvgIconType } from '../../../../SvgIcon/svg-icon-type.mjs';
import '../../../../../styled-system/css/css.mjs';
import '../../../../../styled-system/helpers.mjs';
import { sva } from '../../../../../styled-system/css/sva.mjs';
import '../../../../../styled-system/jsx/is-valid-prop.mjs';
import { Flex } from '../../../../../styled-system/jsx/flex.mjs';
import '../../../../../styled-system/patterns/visually-hidden.mjs';
import { Button } from '../../../../../ui-kit/button.mjs';
import { Icon } from '../../../../../ui-kit/styled/icon.mjs';
import { PanelHeader, NoParameters, ParametersList, Parameter, ParameterText, PanelHeaderButton } from './UiComponents.mjs';

const parametersConfigRecipe = sva({
  className: "lookup-parameters-config",
  slots: ["container", "save-button"],
  base: {
    container: {
      flexDir: "column",
      p: "1.5",
      pb: "2.5",
      gap: "2"
    },
    "save-button": {
      w: "fit-content",
      h: "fit-content",
      rounded: "xl",
      color: "slate.11",
      px: "2",
      py: "1"
    }
  }
});
function ParametersPanel({
  onChange,
  schema,
  value,
  className
}) {
  const parametersProvided = isParametersProvided(value);
  const [isEditing, setIsEditing] = useState(!parametersProvided);
  const { container } = parametersConfigRecipe();
  function isParametersProvided(object) {
    return !!Object.keys(object).length;
  }
  function toggleIsEditing() {
    setIsEditing(!isEditing);
  }
  function handleOnClose() {
    setIsEditing(false);
  }
  return /* @__PURE__ */ jsxs(Flex, { className: clsx(container, className), children: [
    /* @__PURE__ */ jsx(
      PanelHeader,
      {
        title: "Parameters",
        actions: /* @__PURE__ */ jsx(
          EditParametersButton,
          {
            onClick: toggleIsEditing,
            isEditing
          }
        )
      }
    ),
    isEditing ? /* @__PURE__ */ jsx(
      ParametersPanelForm,
      {
        onChange,
        onClose: handleOnClose,
        schema,
        value
      }
    ) : /* @__PURE__ */ jsx(ParametersPanelView, { schema, value })
  ] });
}
function ParametersPanelForm({
  onClose,
  onChange,
  schema,
  value
}) {
  const styles = parametersConfigRecipe();
  const field = makeDataField({
    schema,
    value
  });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(DataBuilderForm, { field, onChange }),
    /* @__PURE__ */ jsx(
      Button,
      {
        onClick: onClose,
        variant: "outline",
        colorPalette: "gray",
        size: "sm",
        className: styles["save-button"],
        children: "Save and Close"
      }
    )
  ] });
}
function ParametersPanelView({
  schema,
  value
}) {
  const parameters = Object.entries(schema?.properties ?? {}).map(
    ([key, field]) => {
      return {
        title: field?.title || toHeaderCase(key),
        value: value?.[key]
      };
    }
  );
  if (!parameters.length) {
    return /* @__PURE__ */ jsx(NoParameters, {});
  }
  return /* @__PURE__ */ jsx(ParametersList, { children: parameters.map(({ title, value: value2 }, i) => /* @__PURE__ */ jsx(Parameter, { children: /* @__PURE__ */ jsx(ParameterText, { title, value: value2 }) }, i)) });
}
function EditParametersButton({
  onClick,
  isEditing
}) {
  const label = isEditing ? "Cancel" : "Edit";
  const icon = isEditing ? SvgIconType.Close : SvgIconType.Edit;
  return /* @__PURE__ */ jsxs(PanelHeaderButton, { onClick, children: [
    label,
    /* @__PURE__ */ jsx(Icon, { children: /* @__PURE__ */ jsx(SvgIcon, { type: icon }) })
  ] });
}

export { ParametersPanel };
