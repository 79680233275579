"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { useElements } from '../useElements.mjs';

function useScenarios(query) {
  const { ...rest } = useElements("scenarios", query);
  return {
    scenarios: rest.items,
    ...rest
  };
}

export { useScenarios };
