import { sva } from 'styled-system/css'
import { Flex } from 'styled-system/jsx'

const recipe = sva({
  className: 'button',
  slots: ['container', 'title', 'icon-left', 'icon-right'],
  base: {
    container: {
      display: 'flex',
      alignItems: 'center',
      gap: '2',
      px: '4',
      py: '2',
      rounded: 'md',
      textStyle: 'md',
      color: 'white',
      bg: 'gray.9',
      _hover: {
        bg: 'gray.10',
      },
    },
    'icon-left': {
      h: '4',
      w: '4',
      bg: 'red.5',
      rounded: 'full',
    },
    'icon-right': {
      h: '4',
      w: '4',
      bg: 'blue.5',
      rounded: 'full',
    },
  },
})

export const ExampleButton = () => {
  const styles = recipe()

  return (
    <Flex
      p='4'
      borderWidth='2'
      borderStyle='dashed'
      borderColor='red.5'
      rounded='md'
      mb='2'
    >
      <button className={styles.container}>
        <div className={styles['icon-left']} />
        <span className={styles.title}>Button</span>
        <div className={styles['icon-right']} />
      </button>
    </Flex>
  )
}
