import {
  Component,
  ComponentType,
  ErrorInfo,
  PropsWithChildren,
  PropsWithRef,
  useState,
} from 'react'

import {
  ErrorBoundaryProps,
  ErrorBoundaryState,
} from 'components/ErrorBoundaries/types'

const changedArray = (a: Array<unknown> = [], b: Array<unknown> = []) =>
  a.length !== b.length || a.some((item, index) => !Object.is(item, b[index]))

const initialState: ErrorBoundaryState = {
  error: null,
  amountSameErrorAsPrevious: 0,
}

class ErrorBoundary extends Component<
  PropsWithRef<PropsWithChildren<ErrorBoundaryProps>>,
  ErrorBoundaryState
> {
  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState((state) => ({
      ...state,
      amountSameErrorAsPrevious:
        state.error === error ? state.amountSameErrorAsPrevious + 1 : 0,
    }))
    this.props.onError?.(
      error,
      info as {
        componentStack: string
      },
    )
  }

  state = initialState
  resetErrorBoundary = (...args: Array<unknown>) => {
    this.props.onReset?.(...args)
    this.reset()
  }

  reset() {
    this.setState(initialState)
  }

  componentDidUpdate(
    prevProps: ErrorBoundaryProps,
    prevState: ErrorBoundaryState,
  ) {
    const { error } = this.state
    const { resetKeys } = this.props

    // There's an edge case where if the thing that triggered the error
    // happens to *also* be in the resetKeys array, we'd end up resetting
    // the error boundary immediately. This would likely trigger a second
    // error to be thrown.
    // So we make sure that we don't check the resetKeys on the first call
    // of cDU after the error is set

    if (
      error !== null &&
      prevState.error !== null &&
      changedArray(prevProps.resetKeys, resetKeys)
    ) {
      this.props.onResetKeysChange?.(prevProps.resetKeys, resetKeys)
      this.reset()
    }
  }

  render() {
    const { error } = this.state

    const { strategy, FallbackComponent } = this.props

    if (error !== null) {
      if (!FallbackComponent) {
        throw new Error(
          'react-error-boundary requires either a fallback, fallbackRender, or FallbackComponent prop',
        )
      }

      const props = {
        error,
        resetErrorBoundary: this.resetErrorBoundary,
      }

      // Stop rendering the component if the error is in infinite loop
      const shouldRenderComponent = this.state.amountSameErrorAsPrevious < 2
      if (!shouldRenderComponent) return <FallbackComponent {...props} />

      return (
        <>
          {strategy === 'showErrorBelow' && this.props.children}
          <FallbackComponent {...props} />
          {strategy === 'showErrorAbove' && this.props.children}
        </>
      )
    }

    return this.props.children
  }
}

function withErrorBoundary<P>(
  Component: ComponentType<P>,
  errorBoundaryProps: ErrorBoundaryProps,
): ComponentType<P> {
  const Wrapped: ComponentType<P> = (props) => {
    return (
      <ErrorBoundary {...errorBoundaryProps}>
        {/* @ts-expect-error TS(2322): Type P is not assignable to type IntrinsicAttributes & P. */}
        <Component {...props} />
      </ErrorBoundary>
    )
  }

  // Format for display in DevTools
  const name = Component.displayName || Component.name || 'Unknown'
  Wrapped.displayName = `withErrorBoundary(${name})`

  return Wrapped
}

function useErrorHandler(givenError?: unknown): (error: unknown) => void {
  const [error, setError] = useState<unknown>(null)
  if (givenError != null) throw givenError
  if (error != null) throw error
  return setError
}

export { ErrorBoundary, useErrorHandler, withErrorBoundary }

/*
eslint
  @typescript-eslint/no-throw-literal: "off",
  @typescript-eslint/prefer-nullish-coalescing: "off"
*/
