"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsx, jsxs } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { Portal } from '@ark-ui/react';
import { Item, Root, Trigger, Positioner, Content } from '../../ui-kit/styled/menu.mjs';

const SimpleMenu = ({
  triggerNode,
  children,
  placement = "bottom-start",
  offset = [0, 4],
  lazyMount = true,
  unmountOnExit = true,
  portalled = false
}) => {
  const [crossAxis, mainAxis] = offset;
  return /* @__PURE__ */ jsxs(
    Root,
    {
      lazyMount,
      unmountOnExit,
      positioning: {
        placement,
        offset: {
          crossAxis,
          mainAxis
        }
      },
      children: [
        /* @__PURE__ */ jsx(Trigger, { asChild: true, children: triggerNode }),
        /* @__PURE__ */ jsx(Portal, { disabled: !portalled, children: /* @__PURE__ */ jsx(Positioner, { zIndex: "1", children: /* @__PURE__ */ jsx(
          Content,
          {
            bg: "neutral.2",
            borderWidth: "thin",
            borderColor: "neutral.5",
            p: "0",
            rounded: "sm",
            minW: "28",
            w: "auto",
            shadow: "none",
            gap: "0",
            children
          }
        ) }) })
      ]
    }
  );
};
SimpleMenu.Item = forwardRef(({ value, children, ...rest }, ref) => {
  return /* @__PURE__ */ jsx(
    Item,
    {
      ref,
      value,
      m: "0",
      borderTopWidth: "thin",
      borderColor: "neutral.6",
      px: "3",
      py: "2",
      fontWeight: "normal",
      _first: {
        borderTopWidth: "0"
      },
      ...rest,
      children
    }
  );
});

export { SimpleMenu };
