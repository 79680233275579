import { createContext, ReactNode, useContext } from 'react'

import { useWorkspace } from 'components/Workspaces/workspace-context'
import useLocalStorage from 'hooks/useLocalStorage'

const DocsContext = createContext<{
  parameters: Record<string, any>
  patchParameters: (partialParameters: Record<string, any>) => void
  isStaticParameter: (name: string) => boolean
  isVariableParameter: (name: string) => boolean
}>({} as any)

export default function useDocs() {
  return useContext(DocsContext)
}

export const DocsContextProvider = ({
  children,
  parameters: staticParameters = {},
}: {
  children: ReactNode
  parameters?: any
}) => {
  const { workspace, testCustomerAccessToken } = useWorkspace()

  const [variableParameters, setVariableParameters] = useLocalStorage(
    `${workspace?.id}-docs-variable-parameters`,
    {},
  )

  // Remove undefineds from static parameters
  Object.keys(staticParameters ?? {}).forEach(
    (key) =>
      staticParameters[key] === undefined && delete staticParameters[key],
  )

  const parameters = {
    ...(variableParameters ?? {}),
    ...(staticParameters ?? {}),
    CUSTOMER_TOKEN: testCustomerAccessToken,
  }

  function patchParameters(partialParameters) {
    setVariableParameters({
      ...variableParameters,
      ...partialParameters,
    })
  }

  return (
    <DocsContext.Provider
      value={{
        parameters,
        patchParameters,
        isStaticParameter: (name) => staticParameters?.[name] !== undefined,
        isVariableParameter: (name) => staticParameters?.[name] === undefined,
      }}
    >
      {children}
    </DocsContext.Provider>
  )
}
