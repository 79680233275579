import { enableCustomErrorBoundary } from 'components/ErrorBoundaries/const'
import ErrorToast from 'components/ErrorBoundaries/elements/ErrorToast'
import { onErrorHandler } from 'components/ErrorBoundaries/onErrorHandler'

import { ErrorBoundary } from './index'

export default function AppErrorBoundary({ children }) {
  if (!enableCustomErrorBoundary) return children
  return (
    <ErrorBoundary
      FallbackComponent={AppErrorFallback}
      onError={onErrorHandler}
      strategy={'showErrorAbove'}
    >
      {children}
    </ErrorBoundary>
  )
}

function AppErrorFallback({ error }) {
  const title = error?.title
  const link = error?.link
  const message = typeof error === 'string' ? error : error.message
  return (
    <ErrorToast>
      {title && <ErrorToast.Header>{title}</ErrorToast.Header>}
      <ErrorToast.Content>
        <p>{message}</p>
        {link && (
          <p>
            <a href={link}>Link to docs</a>
          </p>
        )}
      </ErrorToast.Content>
    </ErrorToast>
  )
}
