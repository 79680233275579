// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '18ef270894a06e0bbff1aa6ae3f21dc0';
const css =`._backdrop_15tot_1{align-items:flex-start;bottom:0;display:flex;left:0;position:absolute;right:0;top:0;z-index:500}._backdrop-overlay_15tot_1{--tw-bg-opacity:0.8;background-color:rgb(0 0 0/var(--tw-bg-opacity));padding:1.75rem}._backdrop-blur_15tot_1{--tw-backdrop-blur:blur(4px);-webkit-backdrop-filter:var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);backdrop-filter:var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)}._backdrop__clickable_15tot_1{cursor:pointer}`;
const styles = {
    get ['backdrop']() { injectStyles(key, css, options);  return '_backdrop_15tot_1'; },
    get ['backdrop-overlay']() { injectStyles(key, css, options);  return '_backdrop-overlay_15tot_1'; },
    get ['backdropOverlay']() { injectStyles(key, css, options);  return '_backdrop-overlay_15tot_1'; },
    get ['backdrop-blur']() { injectStyles(key, css, options);  return '_backdrop-blur_15tot_1'; },
    get ['backdropBlur']() { injectStyles(key, css, options);  return '_backdrop-blur_15tot_1'; },
    get ['backdrop__clickable']() { injectStyles(key, css, options);  return '_backdrop__clickable_15tot_1'; },
    get ['backdropClickable']() { injectStyles(key, css, options);  return '_backdrop__clickable_15tot_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
