import { useState, ReactNode, useEffect } from 'react'
import { ErrorData } from '@integration-app/sdk'
import { createMachine } from '@zag-js/core'
import { OpenChangeDetails } from '@zag-js/dialog'

import { Popup } from 'components/Popup'
import { Popup as UiKitPopup } from 'ui-kit/popup'

import { ErrorBadge, ErrorDocsLink, ErrorJSON } from './components'
import { ErrorItem } from './ErrorItem'

type MachineContext = {
  error?: ErrorData | null
  popupProps?: UiKitPopup.RootProps | null
}
type MachineState = {
  value: 'closed' | 'open'
}

const errorPopupMachine = createMachine<MachineContext, MachineState>(
  {
    // initial state
    id: 'errorPopup',
    initial: 'closed',
    context: {
      error: null,
      popupProps: null,
    },
    states: {
      closed: {
        on: {
          OPEN: {
            target: 'open',
            actions: ['setError'],
          },
        },
      },
      open: {
        on: {
          CLOSE: {
            target: 'closed',
            actions: ['clearError'],
          },
        },
      },
    },
  },
  {
    actions: {
      setError(context, event: any) {
        context.error = event?.error ?? null
        context.popupProps = event?.popupProps ?? null
      },
      clearError(context) {
        context.error = null
        context.popupProps = null
      },
    },
  },
)
errorPopupMachine.start()

function showErrorInPopup(
  error: ErrorData | null,
  popupProps?: UiKitPopup.RootProps,
) {
  if (!!error) {
    errorPopupMachine.send({ type: 'OPEN', error, popupProps })
  } else {
    errorPopupMachine.send({ type: 'CLOSE' })
  }
}

const ErrorPopupProvider = ({ children }: { children: ReactNode }) => {
  const [error, setError] = useState<ErrorData | null>(null)
  const [popupProps, setPopupProps] =
    useState<Partial<UiKitPopup.RootProps> | null>(null)

  useEffect(() => {
    errorPopupMachine.subscribe((state) => {
      setPopupProps(state.context.popupProps ?? null)
      setError(state.context.error ?? null)
    })
  })

  function handleOnOpenChange(details: OpenChangeDetails) {
    popupProps?.onOpenChange?.(details)
    showErrorInPopup(null)
  }

  return (
    <>
      {children}
      <ErrorPopup
        error={error}
        open={!!error}
        {...(popupProps ?? {})}
        onOpenChange={handleOnOpenChange}
      />
    </>
  )
}

const useErrorPopupOpenStatus = (): boolean => {
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false)

  useEffect(() => {
    errorPopupMachine.subscribe((state) => {
      setIsErrorPopupOpen(!!state.context.error)
    })
  })

  return isErrorPopupOpen
}

function ErrorPopup({
  error,
  ...props
}: { error?: ErrorData | null } & UiKitPopup.RootProps) {
  if (!error) return null

  return (
    <Popup.Root {...props}>
      <Popup.Window usePortal zIndex={'popover'} width={'3xl'}>
        <Popup.Header>
          <ErrorItem.Header>
            <ErrorBadge error={error} />
            <ErrorItem.Title>{error.message}</ErrorItem.Title>
          </ErrorItem.Header>
        </Popup.Header>
        <Popup.Content>
          <ErrorItem.Root>
            <ErrorJSON error={error} />

            <ErrorDocsLink error={error} />
          </ErrorItem.Root>
        </Popup.Content>
      </Popup.Window>
    </Popup.Root>
  )
}

export { ErrorPopupProvider, useErrorPopupOpenStatus, showErrorInPopup }
